<template>
  <v-menu
    class="dropdown rounded-xl"
    :class="colorClass"
    nudge-left="0"
    nudge-bottom="24"
  >
    <template v-slot:activator="{ on }">
      <div
        class="dropdown__activator text-body-3"
        v-on="on"
        @click="isOpen = !isOpen"
      >
        <span :class="colorClass">{{ title }}</span>
        <fa-icon
          :class="colorClass"
          :icon="isOpen ? 'chevron-up' : 'chevron-down'"
          width="12"
          height="12"
        />
      </div>
    </template>
    <div class="d-flex flex-column white pa-2">
      <nuxt-link
        v-for="item in items"
        :key="item.id"
        :to="item.slug"
        class="dropdown__item d-block black--text text-decoration-none pa-1"
      >
        {{ item.title }}
      </nuxt-link>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: 'BlogDropdown',
  props: {
    colorClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  border-radius: 6px;
  z-index: 999;
  &__activator {
    display: inline-grid;
    justify-content: start;
    align-items: center;
    grid-template-columns: repeat(2, max-content);
    gap: 0.5rem;
  }
  &__item {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: $cubic-bezier;
    &:hover {
      background-color: var(--v-light-grey-base);
    }
  }
}
</style>
