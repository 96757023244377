<template>
  <section v-if="showComponent" class="media" :data-type="linkType">
    <div class="media__cards">
      <template>
        <CardLink
          v-if="websiteUrl"
          class="card-link--custom"
          :external-link="websiteUrl"
          data-website
        >
          <fa-icon icon="share" class="media__icon primary--text mr-1" />
          <span class="text-body-1">{{ $t('global.visitMyWebsite') }}</span>
        </CardLink>
        <CardLink
          v-for="link in formattedLinks"
          :key="link.id"
          class="card-link--custom"
          :external-link="link.linkUrl"
          :data-icon="link.icon"
        >
          <v-img
            v-if="link.photoUrl"
            :src="link.photoUrl"
            class="media__icon"
            width="24"
            height="24"
            alt=""
          />
          <fa-icon
            v-else
            :icon="link.icon"
            class="media__icon mr-1"
            :style="link.iconColor"
          />
          <span class="text-body-1">{{ link.description }}</span>
        </CardLink>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import CardLink from '~/components/atoms/CardLink.vue';
import { CUSTOM_LINK_TYPE, PROFILE_TYPE } from '~/constants';
import { createExternalLink, getIconName, getStorageUrl } from '~/helpers';

export default {
  name: 'CustomLinks',
  components: {
    CardLink,
  },
  props: {
    company: [Boolean],
    showHighlited: [Boolean],
  },
  computed: {
    ...mapGetters('profile', [
      'highlighted',
      'currentProfileOrganization',
      'customLinks',
      'profile',
    ]),
    ...mapGetters('organization', [
      'organizationCustomLinks',
      'organizationHighlighted',
      'organizationProfile',
    ]),
    showComponent() {
      if (this.formattedLinks.length || this?.websiteUrl) return true;
      if (this.company) return !!this.organizationHighlighted.length;
      return !!this.highlighted.length;
    },
    websiteUrl() {
      if (this.company && this.enabledLinks?.['websiteUrl']) {
        return this.organizationProfile?.websiteUrl;
      }
      return '';
    },
    enabledLinks() {
      return this.currentProfileOrganization?.organization?.settings
        ?.companyLinks;
    },
    formattedLinks() {
      let links = [];
      if (this.company) {
        links = this.organizationCustomLinks.filter((link) => {
          return (
            this.enabledLinks?.[link.id] &&
            link?.groupType !== CUSTOM_LINK_TYPE.TILE
          );
        });
      } else {
        links = this.customLinks;
      }
      return links
        .map((link) => {
          return {
            id: link.id,
            icon: getIconName(link.iconName),
            iconColor: {
              color: link.iconColor,
            },
            linkUrl: link.link,
            photoUrl: link?.photoName ? getStorageUrl(link.photoName) : null,
            description: link.description,
            order: link.order,
          };
        })
        .sort((first, second) => {
          return first.order - second.order;
        });
    },
    linkType() {
      if (this.company) return 'company-link';
      if (this.profile?.profileType === PROFILE_TYPE.COMPANY)
        return 'company-custom-link';
      return null;
    },
  },
  methods: {
    goToWebsite() {
      window.open(createExternalLink(this.websiteUrl), '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.media {
  &__cards {
    display: grid;
    gap: 0.75rem;
  }
  &__btn {
    border-radius: 12px;
    &:hover {
      background-color: var(--v-primary-hover-base) !important;
    }
    &:active {
      background-color: var(--v-primary-active-base) !important;
    }
  }
  &__icon {
    border-radius: 50%;
  }
}
</style>
