<template>
  <div data-product="child" />
</template>

<script>
import profileMixin from '~/mixins/profileMixin';

export default {
  name: 'ClubMemberProfile',
  mixins: [profileMixin],
  layout: 'product',
};
</script>
