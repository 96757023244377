<template>
  <div class="layout">
    <Header />
    <LazyHydrate when-visible> <BusinessCard /></LazyHydrate>
    <LazyHydrate when-visible> <Advantages /></LazyHydrate>
    <LazyHydrate when-visible> <HowItWorks /></LazyHydrate>
    <LazyHydrate when-visible> <Pricing /></LazyHydrate>
    <LazyHydrate when-visible> <Shop /></LazyHydrate>
    <LazyHydrate when-visible> <YourData /></LazyHydrate>
    <LazyHydrate when-visible> <GoForIt /></LazyHydrate>
    <LazyHydrate when-visible> <Partners /></LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

import Header from '~/components/Header';

export default {
  name: 'HomePage',
  components: {
    LazyHydrate,
    Header,
    YourData: () => import('~/components/YourData'),
    Partners: () => import('~/components/Partners'),
    GoForIt: () => import('~/components/GoForIt'),
    HowItWorks: () => import('~/components/HowItWorks'),
    Advantages: () => import('~/components/Advantages'),
    Pricing: () => import('~/components/Pricing'),
    BusinessCard: () => import('~/components/BusinessCard'),
    Shop: () => import('~/components/Shop'),
  },
  layout: 'landing-page',
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  gap: clamp(4rem, 8vw, 5rem);
}
</style>
