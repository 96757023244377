<template>
  <div class="tutorials">
    <div class="tutorials__container container">
      <div v-for="tutorial in tutorials" :key="tutorial.id">
        <h2 class="tutorials__title text-h1">
          {{ tutorial.category }}
        </h2>
        <div class="tutorials__videos">
          <div v-for="video in tutorial.videos" :key="video.id">
            <VideoThumbnail :video="video" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tutorials from '~/mixins/tutorials';
export default {
  name: 'Tutorials',
  components: {
    VideoThumbnail: () => import('~/components/tutorials/VideoThumbnail.vue'),
  },
  mixins: [tutorials],
};
</script>

<style lang="scss" scoped>
.tutorials {
  &__videos {
    @media #{$md-and-up} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.2rem;
    }
  }
  &__title {
    margin: 2.625rem 0;
    @media #{$md-and-up} {
      margin: 5rem 0;
    }
  }
}
</style>
