<template>
  <v-container class="message d-flex justify-center align-center">
    <div class="message__content d-flex flex-column align-center">
      <h1 class="message__title font-weight-bold light-gray--text">
        {{ $t('global.profileNotFound') }}
      </h1>
      <nuxt-link
        class="message__description font-weight-bold text-gray--text"
        to="/"
      >
        {{ $t('global.returnToHomepage') }}
      </nuxt-link>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Default',
  layout: 'empty',
};
</script>

<style lang="scss" scoped>
.message {
  height: 100%;
  &__title {
    font-size: 1.75rem;
    line-height: 1;
  }
}
</style>
