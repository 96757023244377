import { render, staticRenderFns } from "./GoForIt.vue?vue&type=template&id=e171d7d6&scoped=true&"
import script from "./GoForIt.vue?vue&type=script&lang=js&"
export * from "./GoForIt.vue?vue&type=script&lang=js&"
import style0 from "./GoForIt.vue?vue&type=style&index=0&id=e171d7d6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e171d7d6",
  null
  
)

export default component.exports