<template>
  <section class="team" v-if="profile">
    <CardLink
      v-if="isPersonal && teams.length"
      :internal-link="teamLink"
      class="mt-4"
    >
      <Avatar
        :avatar-image="getStorageUrl(teams[0].photoName)"
        :profile-full-name="teams[0].name"
        :profile-type="PROFILE_TYPE.CLUB"
        class-variant="avatar--navigation align-self-center"
      />
      <p class="mr-1">
        <span class="team__title primary-dark--text mr-1">
          {{ teams[0].name }}
        </span>
      </p>
    </CardLink>
    <CardLink
      v-if="isCompany && numberOfMembers"
      no-icon
      class="primary-dark--text mt-4"
    >
      <v-img
        class="team__image align-self-center"
        width="24"
        height="24"
        :src="photoUrl"
      />
      <p class="team__title font-weight-regular dark-grey--text mr-1">
        {{ numberOfMembers }} members
      </p>
    </CardLink>
    <CardLink v-if="isClub" no-icon class="primary-dark--text mt-4">
      <v-img
        class="team__image align-self-center"
        width="24"
        height="24"
        :src="photoUrl"
      />
      <p class="team__title font-weight-regular primary-dark--text mr-1">
        {{ numberOfMembers }} members
      </p>
    </CardLink>
    <div
      v-if="false"
      class="team__more d-flex justify-end align-center text-gray--text text-right mt-4 ml-auto"
    >
      <span class="mr-1">{{ $t('global.belongsTo') }}</span>
      <v-img
        v-for="(team, index) in teams.slice(1)"
        :key="team.id"
        :src="getStorageUrl(team.photoName)"
        class="team__image align-self-center"
        :class="{ 'team__image--next': index > 0 }"
        width="24"
        height="24"
      />
      <nuxt-link
        to="#teams"
        class="primary--text font-weight-bold text-decoration-none"
      >
        {{ $t('global.otherTeams', { amount: teams.length - 1 }) }}
      </nuxt-link>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Avatar from '~/components/atoms/Avatar';
import CardLink from '~/components/atoms/CardLink.vue';
import { PROFILE_TYPE } from '~/constants';
import { getStorageUrl } from '~/helpers';

export default {
  name: 'Team',
  components: {
    Avatar,
    CardLink,
  },
  data() {
    return {
      PROFILE_TYPE,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'profile',
      'photoUrl',
      'isCompany',
      'isClub',
      'isPersonal',
    ]),
    ...mapGetters('organization', ['numberOfMembers']),
    teams() {
      return this.profile?.teamsOrMembers || [];
    },
    teamLink() {
      return `/profile/${this.teams[0]?.id}`;
    },
  },
  created() {
    const routeId = this.$route.params.id || this.$route.params.organization;
    this.getNumberOfMembers(routeId);
  },
  methods: {
    getStorageUrl,
    ...mapActions('organization', ['getNumberOfMembers']),
  },
};
</script>

<style lang="scss" scoped>
.team {
  &__title {
    line-height: 1.4;
    font-weight: 600;
  }
  &__image {
    border-radius: 50%;
    &--next {
      transform: translateX(-7px);
    }
  }
  &__more {
    font-size: 0.8125rem;
    line-height: 1.2;
    width: max-content;
  }
}
</style>
