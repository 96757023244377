<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    :ripple="false"
    :height="height"
    class="btn text-lp-body-2"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'Button',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    height: {
      type: [Number, String],
      default: 48,
    },
  },
};
</script>
<style lang="scss" scoped>
.v-application .btn {
  box-shadow: none;
  text-transform: inherit;
  border-radius: 6px;
  min-width: max-content !important;
  border: 2px solid transparent;
  transition: $cubic-bezier;
  &::before {
    display: none;
  }
  &:active {
    box-shadow: none;
  }
  ::v-deep {
    .v-btn__content {
      letter-spacing: normal;
    }
  }
  &--primary {
    border-color: var(--v-basic-black-base) !important;
    background-color: var(--v-basic-black-base) !important;
    color: var(--v-white-base) !important;
    &:hover,
    &:active {
      background-color: var(--v-white-base) !important;
      color: var(--v-basic-black-base) !important;
    }
  }
  &--secondary {
    border-color: var(--v-light-grey-base) !important;
    background-color: var(--v-light-grey-base) !important;
    color: var(--v-basic-black-base) !important;
    &:hover,
    &:active {
      border-color: var(--v-basic-black-base) !important;
      background-color: var(--v-basic-black-base) !important;
      color: var(--v-white-base) !important;
    }
  }
  &--light {
    border-color: var(--v-basic-black-base) !important;
    background-color: var(--v-white-base) !important;
    color: var(--v-basic-black-base) !important;
    &:hover,
    &:active {
      background-color: var(--v-basic-black-base) !important;
      color: var(--v-white-base) !important;
    }
  }
  &--lighter {
    border-color: var(--v-dark-grey-base) !important;
    background-color: transparent !important;
    color: var(--v-basic-black-base) !important;
    &:hover,
    &:active {
      background-color: var(--v-dark-grey-base) !important;
      color: var(--v-white-base) !important;
    }
  }
}
</style>
