<template>
  <div class="dropdown">
    <label v-if="label" class="dropdown__label d-block text-h3 mb-2">
      {{ label }}
    </label>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <div
          class="dropdown__activator d-flex align-center justify-space-between light-grey rounded px-4"
          v-on="on"
        >
          <span
            class="body-2"
            :class="
              selectedItemText ? 'basic-black--text' : 'light-black--text'
            "
          >
            {{ selectedItemText || $t('contact.selectItem') }}
          </span>
          <svg-icon
            name="chevron"
            class="basic-black--text"
            width="15"
            height="15"
          />
        </div>
      </template>
      <div class="menu__container white">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="menu__item d-flex align-center basic-black--text body-2 py-2 px-4"
          @click="selectItem(item)"
        >
          <span class="text-truncate">{{ item.text }}</span>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    id: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectedItemText() {
      return this.items?.find((item) => item.id === this.id)?.text;
    },
  },
  methods: {
    selectItem(item) {
      this.$emit('selectedItem', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  &__activator {
    height: 48px;
  }
}
.v-menu__content {
  .menu {
    &__container {
      line-height: 1.17;
      letter-spacing: 0.25px !important;
      border-radius: 8px;
    }
    &__item {
      cursor: pointer;
    }
  }
}
</style>
