<template>
  <div
    v-if="teams && teams.length"
    id="teams"
    class="about__links d-flex flex-column mt-4"
  >
    <p class="about__subtitle text-gray--text">
      {{ $t('global.allTeams') }}
    </p>
    <CardLink
      v-for="team in teams"
      :key="team.id"
      class="team"
      :internal-link="team.link"
    >
      <v-img class="team__image" :src="team.src" width="24" height="24" />
      <p class="team__title mr-1">
        {{ team.title }}
      </p>
    </CardLink>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CardLink from '~/components/atoms/CardLink.vue';
import { getStorageUrl } from '~/helpers';

export default {
  name: 'Organizations',
  components: {
    CardLink,
  },
  computed: {
    ...mapGetters('profile', ['profile']),
    teams() {
      return this.profile?.teamsOrMembers?.map?.((profile) => {
        return {
          id: profile.id,
          src: getStorageUrl(profile?.photoName),
          link: `/profile/${profile.id}`,
          title: profile.name,
          tag: `@${profile.nickName}`,
        };
      });
    },
    displayShowMoreButtons() {
      return this.profile.description && this.profile.description?.length >= 50;
    },
  },
};
</script>

<style lang="scss" scoped>
.team {
  &__image {
    border-radius: 50%;
  }
  &__title {
    line-height: 1.4;
    font-weight: 600;
  }
}
</style>
