<template>
  <fragment>
    <div class="dialog">
      <div
        :class="`dialog__input--${member.membershipStatus.toLowerCase()}`"
        class="dialog__input dialog__input--with-button light rounded-lg px-4 py-2"
      >
        <div>
          <div class="dialog__label font-weight-bold light-gray--text">
            {{ $t('members.membershipStatus') }}
          </div>
          <div class="text-capitalize mt-2">
            {{ member.membershipStatus }}
          </div>
        </div>
      </div>
      <div
        class="dialog__subtitle font-weight-bold text-uppercase text-gray--text"
      >
        {{ $t('members.paymentUpdates') }}
      </div>
      <div v-if="!payments.length && !loading" class="pl-4">
        {{ $t('members.paymentsNotFound') }}
      </div>
      <div v-if="payments.length">
        <div
          v-for="payment in payments"
          :key="payment.id"
          :class="
            payment.paid
              ? 'dialog__payment--active'
              : 'dialog__payment--expired'
          "
          class="dialog__payment d-flex align-center rounded-lg px-5 py-2 mb-2"
        >
          <fa-icon
            class="mr-3"
            :class="
              payment.paid ? 'green-confirmation--text' : 'error-red--text'
            "
            :icon="payment.paid ? 'check' : 'times'"
          />
          <div class="font-weight-bold primary-dark--text">
            {{ paymentPeriod(payment.period_start) }}
          </div>
          <a :href="payment.invoice_pdf" target="_blank" class="ml-auto">
            <fa-icon class="light-gray--text" icon="download" />
          </a>
        </div>
      </div>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
    <div class="dialog__two-columns">
      <Button
        width="100%"
        height="48"
        class="button--rounded button--secondary"
        @click="$emit('closeDrawer')"
      >
        {{ $t('members.close') }}
      </Button>
      <Button
        width="100%"
        height="48"
        class="button--rounded button--primary"
        @click="update(undefined)"
      >
        {{ $t('global.save') }}
      </Button>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import { mapGetters } from 'vuex';

import Button from '~/components/shared/Button';

export default {
  name: 'SubscriptionList',
  components: {
    Button,
    Fragment,
  },
  props: {
    member: [Object],
  },
  computed: {
    ...mapGetters('payments', ['loading', 'payments']),
  },
  methods: {
    update() {
      this.$emit('closeDrawer');
    },
    paymentPeriod(timestamp) {
      return new Date(timestamp * 1000).toLocaleDateString(this.$i18n.locale, {
        year: 'numeric',
        month: 'long',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  display: grid;
  grid-gap: 1.5rem;
  overflow-y: scroll;
  max-height: 70vh;
  &__input {
    height: 66px;
    font-size: 1rem;
    line-height: 1.5;
    &--with-button {
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
    }
    &--active {
      background-color: var(--v-confirmation-bg-base) !important;
      border: 1px solid var(--v-green-confirmation-base) !important;
    }
    &--expired {
      background-color: var(--v-error-red-light-base) !important;
      border: 1px solid var(--v-error-red-base) !important;
    }
    &--invited {
      background-color: var(--v-light-base) !important;
      border: 1px solid var(--v-dark-gray-base) !important;
    }
  }
  &__two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
  }
  &__btn {
    letter-spacing: normal;
  }
  &__label {
    font-size: 0.8125rem;
    line-height: 1.2;
  }
  &__subtitle {
    font-size: 0.8125rem;
    line-height: 1.2;
  }
  &__payment {
    height: 48px;
    font-size: 0.875rem;
    line-height: 1;
    &--active {
      background-color: var(--v-confirmation-bg-base);
      border: 1px solid var(--v-green-confirmation-base);
    }
    &--expired {
      background-color: var(--v-error-red-light-base);
      border: 1px solid var(--v-error-red-base);
      color: var(--v-error-red-base);
    }
  }
}
</style>
