<template>
  <ValidationProvider v-slot="{ validate, errors }" :rules="rules" mode="eager">
    <div
      class="dropdown d-flex align-center light rounded-lg"
      :class="{ 'dropdown--error': errors.length }"
    >
      <v-menu offset-y nudge-left="2" nudge-bottom="-2" @input="validate">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="d-flex align-center justify-space-between flex-grow-1 px-4 py-2"
            v-on="on"
          >
            <div class="text-truncate d-flex flex-column flex-grow-1">
              <label
                class="dropdown__label text-h4 dark-grey--text text-capitalize font-weight-bold"
              >
                {{ label }}
              </label>
              <div
                :class="
                  activeItemText === $t('global.selectItem')
                    ? 'primary-dark--text'
                    : activeColor
                "
              >
                <fa-icon
                  v-if="activeItemIcon"
                  :icon="activeItemIcon"
                  class="justify-end mr-1"
                />
                <span class="text-body-1">{{ activeItemText }}</span>
              </div>
            </div>
            <fa-icon
              v-bind="attrs"
              icon="caret-down"
              class="text-dark--text justify-end ml-2"
            />
          </div>
        </template>
        <div class="menu">
          <div
            v-for="item in otherItemsList"
            :key="item.id"
            class="menu__item primary-dark--text body-2 py-4 px-4"
            @click="chooseItem(item.id)"
          >
            <fa-icon
              v-if="item.icon"
              :icon="item.icon"
              class="dark-grey--text"
            />
            <span class="text-body-1 text-truncate">{{ item.text }}</span>
          </div>
        </div>
      </v-menu>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  mame: 'Dropdown',
  components: {
    ValidationProvider,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '1',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    activeColor: {
      type: String,
      default: 'primary--text',
    },
  },
  data() {
    return {
      activeId: this.id,
    };
  },
  computed: {
    activeItemText() {
      return (
        this.items.find((item) => item.id === this.activeId)?.text ||
        this.$t('global.selectItem')
      );
    },
    activeItemIcon() {
      return this.items.find((item) => item.id === this.activeId)?.icon || '';
    },
    otherItemsList() {
      return this.items.filter((item) => item.id !== this.activeId);
    },
  },
  watch: {
    id(newValue) {
      this.activeId = newValue;
    },
  },
  methods: {
    chooseItem(id) {
      this.activeId = id;
      this.$emit('chooseItem', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  max-width: 100%;
  border: 1px solid var(--v-input-border-gray-base) !important;
  padding: 1px;
  &__label {
    margin-bottom: 0.375rem;
  }
  &--error {
    border-color: var(--v-error-red-base) !important;
    background-color: var(--v-error-red-light-base) !important;
  }
}
.v-menu__content {
  max-width: 342px;
  width: 100%;
  border-radius: 0 !important;
  box-shadow: none;
  border: 1px solid var(--v-light-main-base);
}
.menu {
  line-height: 1.17;
  letter-spacing: 0.25px !important;
  border-radius: 0 !important;
  background-color: var(--v-white-base);
  ::v-deep * {
    cursor: pointer;
  }
  &__item {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 0.75rem;
    border-top: 1px solid var(--v-light-main-base);
    & > svg {
      justify-self: center;
    }
  }
}
</style>
