<template>
  <section id="premium-services" class="premium">
    <div class="container container--internal">
      <div class="premium__content">
        <div class="premium__item" v-for="item in premiumItems" :key="item.id">
          <nuxt-img
            class="premium__item-image mb-5 mb-sm-0"
            format="webp"
            fit="contain"
            :src="item.src"
            :alt="item.alt"
            sizes="xs:70vw lg:300px"
            placeholder
            loading="lazy"
          />
          <div class="premium__item-content">
            <h3 class="text-lp-h3 basic-black--text mb-2 mb-sm-3">
              {{ item.title }}
            </h3>
            <div class="text-lp-body-2 dark-grey--text">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'PremiumServices',
  data() {
    return {
      premiumItems: [
        {
          id: 1,
          src: '/images/business/design-workshop.png',
          alt: '',
          title: this.$t('businessCard.designWorkshops'),
          description: this.$t('businessCard.designWorkshopsInfo'),
        },
        {
          id: 2,
          src: '/images/business/premium-cards.png',
          alt: '',
          title: this.$t('businessCard.premiumCards'),
          description: this.$t('businessCard.premiumCardsInfo'),
        },
        {
          id: 3,
          src: '/images/business/custom-profiles.png',
          alt: '',
          title: this.$t('businessCard.customProfiles'),
          description: this.$t('businessCard.customProfilesInfo'),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.premium {
  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    row-gap: clamp(2.5rem, 6vw, 5rem);
  }
  &__item {
    display: grid;
    grid-template-areas: 'image' 'content';
    grid-template-columns: minmax(0, 1fr);
    position: relative;
    &:nth-child(odd) {
      @media #{$sm-and-up} {
        grid-template-areas: 'image content';
      }
    }
    &:nth-child(even) {
      background-color: var(--v-light-grey-base);
      box-shadow: 0 0 0 100vmax var(--v-light-grey-base);
      clip-path: inset(0 -100vmax);
      padding: 40px 0;
      .premium__item-content {
        @media #{$sm-and-up} {
          padding-right: clamp(2rem, 6vw, 6rem);
        }
      }
      .premium__item-image {
        @media #{$sm-and-up} {
          margin-left: auto;
        }
      }
    }
    &:nth-child(odd) &-content {
      @media #{$sm-and-up} {
        padding-left: clamp(2rem, 6vw, 6rem);
      }
    }
    @media #{$sm-and-up} {
      grid-template-areas: 'content image';
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: center;
      gap: clamp(0rem, 2.5vw, 2rem);
    }
    &-content {
      grid-area: content;
    }
    &-image {
      grid-area: image;
      margin-inline: auto;
      @media #{$sm-and-up} {
        margin-inline: unset;
      }
    }
  }
}
</style>
