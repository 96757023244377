<template>
  <v-expansion-panel @change="switchExpandIcon" class="light-grey">
    <v-expansion-panel-header class="subtitle-1 d-flex align-center pl-4">
      <p class="flex-grow-1">{{ header }}</p>
      <fa-icon :icon="expandIcon" class="expand-icon primary--text" />
    </v-expansion-panel-header>
    <v-expansion-panel-content class="body-2 dark-grey--text">
      <span>{{ contentFirstPart }}</span>
      <a v-if="showLink" :href="link.url" target="_blank">{{ link.text }}</a>
      <Topics v-if="nested" nested />
      <span v-if="contentSecondPart">{{ contentSecondPart }}</span>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'Panel',
  components: {
    Topics: () => import('~/components/faq/Topics'),
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => {},
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandIcon: 'plus',
    };
  },
  computed: {
    showLink() {
      return !!this.link?.text;
    },
    contentFirstPart() {
      return this.content.split('$')?.[0] || '';
    },
    contentSecondPart() {
      return this.content.split('$')?.[1] || '';
    },
  },
  methods: {
    switchExpandIcon() {
      if (this.expandIcon === 'plus') this.expandIcon = 'minus';
      else this.expandIcon = 'plus';
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-icon {
  max-width: 16px;
  max-height: 16px;
}
.v-expansion-panel {
  min-height: unset !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
  &::before,
  &::after {
    display: none;
  }
  &-header {
    min-height: unset !important;
    ::v-deep {
      .v-expansion-panel-header__icon {
        display: none !important;
      }
    }
  }
  &-content {
    white-space: pre-line;
    ::v-deep {
      .v-expansion-panel-content__wrap {
        padding-left: 1rem;
      }
    }
  }
}
</style>
