<template>
  <div v-if="hasMembers" class="dropdown custom-link rounded-lg px-4">
    <div class="dropdown__button d-flex align-center" @click="isOpen = !isOpen">
      <div>
        <v-img
          class="dropdown__image mr-2"
          width="24"
          height="24"
          :src="photoUrl"
        />
      </div>
      <span>{{ `${members.length} ${$t('global.exhibitor')}` }}</span>
      <fa-icon
        :icon="isOpen ? 'chevron-up' : 'chevron-down'"
        class="dropdown__icon primary--text ml-auto"
      />
    </div>
    <div v-if="isOpen">
      <div
        v-for="member in members"
        :key="member.id"
        class="dropdown-item light rounded-lg"
        @click="goToProfile(member)"
      >
        <Avatar
          :avatar-image="getStorageUrl(member.photoName)"
          :profile-full-name="member.name"
          :profile-type="PROFILE_TYPE.PERSONAL"
          class-variant="card__image avatar--member ma-2"
        />
        <div class="my-4">
          <div class="dropdown-item--name font-weight-bold basic-black--text">
            {{ member.name }}
          </div>
          <div
            class="dropdown-item--title font-weight-black text-uppercase dark-grey--text mt-2"
          >
            {{ member.position }}
          </div>
        </div>
        <div class="dropdown-item--icon">
          <fa-icon icon="chevron-right" class="primary--text mr-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Avatar from '~/components/atoms/Avatar.vue';
import { PROFILE_STATUS, PROFILE_TYPE } from '~/constants';
import { getStorageUrl } from '~/helpers';

export default {
  name: 'MembersDropDown',
  components: {
    Avatar,
  },
  data() {
    return {
      isOpen: false,
      PROFILE_TYPE,
    };
  },
  computed: {
    ...mapGetters('profile', ['photoUrl', 'profile']),
    ...mapGetters('organization', ['members']),
    hasMembers() {
      return !!this.members?.length;
    },
  },
  methods: {
    getStorageUrl,
    goToProfile(member) {
      if (member.profileStatus === PROFILE_STATUS.PUBLIC) {
        return this.$router.push(`/Christkindlmarkt.club/${member.nickName}`);
      }
      this.$router.push(`/Christkindlmarkt.club/${member.memberId}`);
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  &__button {
    height: 63px;
  }
  &__image {
    border-radius: 50%;
  }
  &__icon {
    max-height: 12px;
    max-width: 12px;
  }
}
.dropdown-item {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  cursor: pointer;
  margin-bottom: 0.75rem;
  &--icon {
    align-self: center;
    justify-self: center;
  }
  &--name {
    line-height: 1.2;
  }
  &--title {
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0.1rem;
  }
}
</style>
