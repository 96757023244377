<template>
  <footer class="footer py-4">
    <div
      class="footer__container container"
      :class="isOneColumn && 'footer__container--one-column'"
    >
      <slot>
        <Button
          v-if="isOwner"
          height="48"
          class="footer__btn footer__btn--secondary footer__btn--rounded mr-2"
          :class="!(haveContactLinks || showCustomizeBtn) && 'button--expanded'"
          @click="goToDashboard"
        >
          <fa-icon icon="home" class="mr-2" />
          {{ $t('global.home') }}
        </Button>
        <Button
          v-if="showCustomizeBtn"
          height="48"
          class="footer__btn footer__btn--primary footer__btn--rounded mr-2"
          @click="goToProfileEdit"
        >
          <fa-icon icon="user-edit" class="mr-2" />
          {{ $t('global.customize') }}
        </Button>
        <a
          v-if="haveContactLinks && !showCustomizeBtn"
          class="footer__btn footer__btn--rounded d-flex align-center justify-center dark-grey--text font-weight-bold text-decoration-none mr-2"
          :class="
            !!$apolloHelpers.getToken()
              ? 'footer__btn--primary'
              : 'footer__btn--secondary'
          "
          :href="!!mobileLink ? `tel:${mobileLink}` : `mailto:${emailLink}`"
        >
          <fa-icon
            :icon="!!mobileLink ? 'phone-alt' : 'envelope'"
            class="mr-2"
          />
          {{ !!mobileLink ? $t('global.call') : $t('global.email') }}
        </a>
        <Button
          v-if="showContactBtn"
          class="footer__btn footer__btn--primary footer__btn--rounded font-weight-regular primary--text"
          :class="!haveContactLinks && 'button--expanded'"
          height="48"
          width="100%"
          @click="fetchVcfFile"
        >
          <fa-icon icon="plus-circle" class="mr-2" />
          {{ $t('global.addContact') }}
        </Button>
      </slot>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import Button from '~/components/shared/Button.vue';
import { CONTACT_LINK_TYPES, PROFILE_TYPE } from '~/constants';
import goToDashboard from '~/mixins/goToDashboard';

export default {
  name: 'Footer',
  components: {
    Button,
  },
  mixins: [goToDashboard],
  computed: {
    ...mapGetters('profile', [
      'contactLinks',
      'currentOrganizationProfile',
      'getProfileOrganization',
      'isAdmin',
      'isClub',
      'isHubSpot',
      'isProfileFromMyClub',
      'isProfileOwner',
      'isCompanyOwner',
      'profile',
    ]),
    haveContactLinks() {
      return this.contactLinks.filter((link) => {
        return link.contactType !== CONTACT_LINK_TYPES.ADDRESS;
      }).length;
    },
    emailLink() {
      if (this.contactLinks.length) {
        return (
          this.contactLinks.find(
            (link) =>
              link.contactType === CONTACT_LINK_TYPES.EMAIL && !!link.link,
          )?.link ?? ''
        );
      }
      return '';
    },
    mobileLink() {
      return (
        this.contactLinks.find(
          (link) =>
            (link.contactType === CONTACT_LINK_TYPES.MOBILE ||
              link.contactType === CONTACT_LINK_TYPES.PHONE) &&
            !!link.link,
        )?.link ?? ''
      );
    },
    isOneColumn() {
      const isProductProfile =
        this.profile.profileType === PROFILE_TYPE.PRODUCT;
      const canShowContactBtn = this.isOwner || this.showContactBtn;
      const hasContactLinks = this.isOwner || this.haveContactLinks;
      if (isProductProfile) {
        return !(this.isHubSpot || this.isCompanyOwner);
      }
      if (canShowContactBtn) return false;
      return !hasContactLinks;
    },
    showContactBtn() {
      return !this.isOwner;
    },
    showCustomizeBtn() {
      if (this.profile.profileType === PROFILE_TYPE.PERSONAL) {
        return this.isProfileOwner;
      }
      return this.isAdmin;
    },
    isOwner() {
      return this.isProfileOwner || this.isAdmin;
    },
  },
  methods: {
    goToProfileEdit() {
      const id = this.$route.params.id || this.$route.params.organization;
      const organization = this.$route.params.organization;
      if (this.currentOrganizationProfile) {
        window.location.href = `${process.env.baseUrl}/app/${organization}/profile/edit/${id}`;
      } else {
        window.location.href = `${process.env.baseUrl}/app/profile/edit/${id}`;
      }
    },
    fetchVcfFile() {
      if (this.currentOrganizationProfile) {
        window.location.href = `${process.env.REST_URL}/files/VCard/${this.profile.id}/${this.currentOrganizationProfile.id}`;
      } else {
        window.location.href = `${process.env.REST_URL}/files/VCard/${this.profile.id}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  box-shadow: 0 2px 16px 0 #00000014;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--v-white-base);
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    @media #{$under-xs} {
      grid-template-columns: 1fr;
    }
    &--one-column {
      grid-template-columns: 1fr;
    }
  }
  &__btn {
    width: 100%;
    height: 48px;
    min-width: min-content !important;
    text-transform: inherit;
    border-radius: 6px;
    &--primary {
      background-color: var(--v-primary-base) !important;
      color: var(--v-light-base) !important;
      &:hover {
        background-color: var(--v-primary-hover-base) !important;
      }
      &:active {
        background-color: var(--v-primary-active-base) !important;
      }
    }
    &--secondary {
      background-color: var(--v-light-main-base) !important;
      color: var(--v-primary-base) !important;
      &:hover {
        background-color: var(--v-light-hover-base) !important;
      }
      &:active {
        background-color: var(--v-primary-base) !important;
        color: var(--v-white-base) !important;
      }
    }
    &--rounded {
      border-radius: 12px !important;
    }
  }
}
</style>
