<template>
  <section id="partners" class="partners">
    <div class="partners__container container pa-0">
      <h2 class="text-lp-h2 text-center mb-10">{{ $t('global.partners') }}</h2>
      <div class="partners__content">
        <div class="partners__slider d-flex">
          <div class="d-flex ma-0" v-for="index in 2" :key="index">
            <nuxt-img
              v-for="partner in partners"
              :key="partner.id"
              class="partners__img"
              :src="partner.src"
              :alt="partner.alt"
              height="44"
              loading="lazy"
              format="webp"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Partners',
  data() {
    return {
      partners: [
        { id: 1, src: '/partners/moerschen.png', alt: 'Moerschen logo' },
        { id: 2, src: '/partners/payfree.png', alt: 'Payfree logo' },
        { id: 3, src: '/partners/onehive.png', alt: 'OneHive logo' },
        { id: 4, src: '/partners/venturelabs.png', alt: 'Venture Labs logo' },
        { id: 5, src: '/partners/rheinfire.png', alt: 'Rhein fire logo' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.partners {
  padding-bottom: clamp(4rem, 8vw, 5rem);
  &__container {
    position: relative;
  }
  &__content {
    overflow: hidden;
    max-width: 100vw;
    margin: 0 auto;
    &::before,
    &::after {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      content: '';
      position: absolute;
      height: 50px;
      width: 100px;
      z-index: 2;
    }

    &::after {
      right: 0;
      bottom: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      bottom: 0;
    }
  }
  &__slider {
    animation: slidein 50s linear infinite;
  }
  &__img {
    margin-right: clamp(4rem, 6rem, 8rem);
  }
}

@keyframes slidein {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-130%);
  }
}
</style>
