<template>
  <v-app class="app">
    <Navigation />
    <nuxt />
    <Footer />
    <GoToTop />
    <NewCardDialog :redirect-user="true" />
    <CookieControl :locale="$root.$i18n.locale">
      <template v-slot:bar>
        <h3 class="basic-black--text">
          {{ $t('cookieConsent.weUseCookies') }} 🍪
        </h3>
        <p class="dark-grey--text">
          {{ $t('cookieConsent.description') }}
          {{ $t('cookieConsent.legalInformation') }}
          <nuxt-link class="basic-black--text" to="/privacy">
            {{ $t('cookieConsent.privacyPolicy') }}
          </nuxt-link>
          {{ $t('cookieConsent.tolearnMore') }}
        </p>
      </template>
    </CookieControl>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

import Navigation from '~/components/Navigation';
import checkAffiliateLinks from '~/middleware/checkAffiliateLinks';
import tabsBroadcast from '~/mixins/tabsBroadcast';

export default {
  name: 'DefaultLayout',
  components: {
    Navigation,
    GoToTop: () => import('~/components/atoms/GoToTop'),
    NewCardDialog: () => import('~/components/NewCardDialog.vue'),
    Footer: () => import('~/components/Footer'),
  },
  mixins: [tabsBroadcast],
  middleware: [checkAffiliateLinks],
  head() {
    return {
      titleTemplate: '%s',
      title: this.$t('metaTags.metaTitle', this.$i18n.locale),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { title: this.$t('metaTags.metaTitle', this.$i18n.locale) },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'de_DE',
        },
        {
          hid: 'og:locale:alternate',
          property: 'og:locale:alternate',
          content: 'en_US',
        },
        {
          hid: 'og:type',
          name: 'og:type',
          content: 'website',
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('metaTags.metaTitle', this.$i18n.locale),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('metaTags.metaDescription', this.$i18n.locale),
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.$t('metaTags.metaTitle', this.$i18n.locale),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('metaTags.metaTitle', this.$i18n.locale),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('metaTags.metaDescription', this.$i18n.locale),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('metaTags.metaDescription', this.$i18n.locale),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.fetchPosts();
    },
  },
  async mounted() {
    await this.fetchPosts();
  },
  methods: {
    ...mapActions('blog', ['fetchPosts']),
  },
};
</script>
<style lang="scss" scoped>
.app {
  margin-top: 4.875rem;
}
</style>
