<template>
  <nav class="nav light--text">
    <div
      v-if="profile !== null"
      class="nav__container container d-flex align-center justify-space-between"
    >
      <div class="nav__content font-weight-bold d-flex align-center py-3">
        <v-btn
          v-if="canGoBack && !!$apolloHelpers.getToken()"
          icon
          class="d-flex align-center"
          @click="$router.go(-1)"
        >
          <fa-icon class="nav__icon" icon="chevron-left" />
        </v-btn>
        <a :href="logoRedirect" class="mr-2 d-flex">
          <svg-icon name="t2l_rounded" class="logo" width="32" height="32" />
        </a>
        <span class="nav__title text-body-2"> / {{ profile.name }} </span>
      </div>
      <div class="d-flex aling-center">
        <slot>
          <Button
            v-if="showQrDialog"
            class="button--square mr-2"
            ripple
            width="36"
            height="36"
            @click="setQrCodeDialog(true)"
          >
            <fa-icon class="primary--text" icon="qrcode" />
          </Button>
          <Button
            v-else-if="showSaveBtn"
            class="button--square mr-2"
            :class="{ 'primary-active': isSaved }"
            ripple
            width="36"
            height="36"
            @click="handleSaveButton"
          >
            <fa-icon
              :class="isSaved ? 'light--text' : 'primary--text'"
              icon="bookmark"
            />
          </Button>
        </slot>
        <v-menu left nudge-bottom="5" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <Button
              class="nav__button button--primary rounded-lg px-0"
              height="36"
              width="36"
              v-bind="attrs"
              v-on="on"
            >
              <fa-icon icon="share-alt" class="nav__share" />
            </Button>
          </template>
          <div class="d-flex flex-column white">
            <div
              v-for="option in shareOptions"
              :key="option.id"
              class="nav__share--item d-flex align-center dark-grey--text pa-4"
              @click="shareProfile(option.url)"
            >
              <fa-icon class="mr-3" :icon="option.icon" />
              <span>{{ option.label }}</span>
            </div>
          </div>
        </v-menu>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Button from '~/components/shared/Button.vue';
import { PROFILE_TYPE } from '~/constants';
import shareProfileMixin from '~/mixins/shareProfileMixin';
import copyToClipboard from '~/utils/copyToClipboard';

export default {
  name: 'Navigation',
  components: {
    Button,
  },
  mixins: [shareProfileMixin],
  data() {
    return {
      isSaved: false,
      canGoBack: false,
      PROFILE_TYPE,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'currentProfileOrganization',
      'isClub',
      'isCompany',
      'isProfileOwner',
      'photoUrl',
      'profile',
    ]),
    showQrDialog() {
      return this.isProfileOwner && this.currentProfileOrganization?.id;
    },
    showSaveBtn() {
      if (!this.$apolloHelpers.getToken()) return false;
      return this.profile.profileType !== PROFILE_TYPE.CLUB;
    },
    logoRedirect() {
      if (this.$apolloHelpers.getToken()) {
        return '/app/dashboard';
      }
      return '/welcome';
    },
  },
  async mounted() {
    this.canGoBack = window.history.length > 1;
    if (this.$apolloHelpers.getToken() && this.profile?.id) {
      this.isSaved = await this.isProfileSaved(this.profile.id);
    }
  },
  methods: {
    ...mapActions('profile', [
      'savedProfile',
      'disconnectSavedProfile',
      'isProfileSaved',
    ]),
    ...mapMutations('profile', ['setQrCodeDialog']),
    copyToClipboard,
    handleSaveButton() {
      if (this.isSaved) {
        this.disconnectSavedProfile(this.profile?.id);
        this.isSaved = false;
        return;
      }
      this.savedProfile(this.profile?.id);
      this.isSaved = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background: $profile-navigation-gradient;
  @media #{$small} {
    background: var(--v-white-base);
    color: var(--v-primary-dark-base) !important;
  }
  &__container {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    gap: 0.5rem;
    @supports not ((scroll-behavior: smooth) and (gap: 20px)) {
      & > *:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
    @media #{$small} {
      &.container {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
      }
    }
  }
  &__title {
    @media #{$small} {
      color: var(--v-black-base) !important;
    }
  }
  &__icon {
    width: 16px;
    height: 16px;
    color: var(--v-white-base);
    @media #{$small} {
      width: 16px;
      height: 16px;
      color: var(--v-primary-dark-base);
    }
  }
  &__share {
    width: 11px;
    @media #{$small} {
      width: 13px;
    }
    &--item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--v-hover-gray-base);
      }
      &:hover {
        background: var(--v-hover-gray-base);
      }
    }
  }
}
.logo:hover {
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.092));
}
</style>
