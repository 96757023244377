<template>
  <header class="header">
    <div class="header__container container">
      <h1 class="header__title text-h1 mb-2">{{ $t('faq.ourFAQ') }}</h1>
      <p class="header__description body-1 dark-grey--text">
        {{ $t('faq.findFrequentlyAskedQuestions') }}
      </p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
.header {
  margin: 5rem 0;
  &__container {
    max-width: 49.375rem;
    @media #{$md-and-up} {
      padding: 0 !important;
    }
  }
}
</style>
