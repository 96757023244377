<template>
  <section class="terms text-gray--text pr-3">
    <h2 class="terms__title pb-4">
      {{ $t('termsOfUse.title') }}
    </h2>
    <p class="font-weight-bold primary-dark--text pb-3">
      {{ $t('termsOfUse.tableOfContents') }}
    </p>
    <ol>
      <li
        v-for="number in 33"
        :key="number"
        @click="scrollToId(number.toString())"
      >
        {{ $t(`termsOfUse.${number}`) }}
      </li>
    </ol>
    <div v-for="number in 9" :key="number" :id="number">
      <h3 class="terms__subtitle">
        {{ `${number}. ` }}{{ $t(`termsOfUse.${number}`) }}
      </h3>
      <span>{{ $t(`termsOfUse.${number}_info`) }}</span>
    </div>
    <ul>
      <li v-for="number in 24" :key="number">
        {{ $t(`termsOfUse.9_${number}`) }}
      </li>
    </ul>
    <div id="10">
      <h3 class="terms__subtitle">10. {{ $t('termsOfUse.10') }}</h3>
      <span>{{ $t('termsOfUse.10_info') }}</span>
    </div>
    <ul>
      <li v-for="number in 14" :key="number">
        {{ $t(`termsOfUse.10_${number}`) }}
      </li>
    </ul>
    <div v-for="number in 23" :key="number + 10" :id="number + 10">
      <h3 class="terms__subtitle">
        {{ `${number + 10}. ` }}{{ $t(`termsOfUse.${number + 10}`) }}
      </h3>
      <span>{{ $t(`termsOfUse.${number + 10}_info`) }}</span>
    </div>
  </section>
</template>

<script>
import scrollToId from '~/utils/scrollToId';

export default {
  methods: {
    scrollToId,
  },
};
</script>

<style lang="scss" scoped>
.terms {
  font-size: 1.125rem;
  line-height: 1.4;
  overflow-y: scroll;
  max-height: 50vh;
  &__title {
    font-size: 1.25rem;
  }
  &__subtitle {
    font-size: 1.125rem;
    padding: 1.5rem 0 0.5rem;
    & + span {
      white-space: pre-line;
    }
  }
  &__title,
  &__subtitle {
    color: var(--v-primary-dark-base);
  }
  ol li {
    color: var(--v-primary-dark-base);
    padding-bottom: 0.37rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &--legal {
    max-height: unset;
    overflow-y: unset;
    max-width: 790px;
    & .terms__title {
      font-size: 2rem;
    }
  }
}
</style>
