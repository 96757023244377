<template>
  <section
    v-if="filteredContactLinks.length"
    class="contact"
    :data-company="organizationLinks"
  >
    <p class="text-body-1 text-gray--text">
      {{ title }}
    </p>
    <CardLink
      v-for="contactLink in filteredContactLinks"
      :key="contactLink.id"
      class="card-link--contact"
      :data-contact-type="contactLink.contactType"
      :external-link="formattedContactLink(contactLink)"
      :is-private="contactLink.linkStatus === PROFILE_STATUS.NOT_PUBLIC"
    >
      <fa-icon :icon="iconFallback(contactLink)" class="dark-grey--text" />
      <a
        class="text-decoration-none"
        v-show="isLinkTypeContact(contactLink.contactType)"
        :href="formattedContactLink(contactLink)"
      >
        {{ contactLink.link }}
      </a>
      <span v-show="!isLinkTypeContact(contactLink.contactType)">
        {{ contactLink.link }}
      </span>
    </CardLink>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import CardLink from '~/components/atoms/CardLink.vue';
import { CONTACT_LINK_TYPES, PROFILE_STATUS } from '~/constants';

export default {
  name: 'Contact',
  components: {
    CardLink,
  },
  props: {
    organizationLinks: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PROFILE_STATUS,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'profile',
      'isProfileOwner',
      'contactLinks',
      'currentProfileOrganization',
      'privateContactLinks',
    ]),
    ...mapGetters('organization', [
      'organizationContactLinks',
      'organizationPrivateContactLinks',
    ]),
    title() {
      if (this.organizationLinks) {
        return this.$t('profile.companyContactsDetails');
      }
      return this.$t('global.contact');
    },
    isLinkTypeContact() {
      return (contactLinkType) => {
        return contactLinkType !== CONTACT_LINK_TYPES.ADDRESS;
      };
    },
    enabledLinks() {
      return (
        this.currentProfileOrganization?.organization?.settings
          ?.companyContacts ?? {}
      );
    },
    filteredContactLinks() {
      if (!this.organizationLinks) {
        if (this.isProfileOwner) return this.privateContactLinks;
        return this.contactLinks;
      }
      if (this.isProfileOwner) {
        return this.organizationPrivateContactLinks.filter(
          (link) => this.enabledLinks[link.id],
        );
      }
      return this.organizationContactLinks.filter(
        (link) => this.enabledLinks[link.id],
      );
    },
    formattedContactLink() {
      return ({ contactType, link }) => {
        if (
          contactType === CONTACT_LINK_TYPES.MOBILE ||
          contactType === CONTACT_LINK_TYPES.PHONE
        ) {
          return `tel: ${link}`;
        }
        if (contactType === CONTACT_LINK_TYPES.EMAIL) {
          return `mailto: ${link}`;
        }
        if (contactType === CONTACT_LINK_TYPES.ADDRESS) {
          const formattedAddress = link.replace('/([ ])/g', '%20');
          return `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
        }
      };
    },
  },
  methods: {
    iconFallback({ iconName, contactType }) {
      if (!iconName) {
        switch (contactType) {
          case (contactType = CONTACT_LINK_TYPES.EMAIL):
            return 'envelope';
          case (contactType = CONTACT_LINK_TYPES.MOBILE):
            return 'mobile-alt';
          case (contactType = CONTACT_LINK_TYPES.PHONE):
            return 'phone-alt';
          case (contactType = CONTACT_LINK_TYPES.ADDRESS):
            return 'map-marker-alt';
        }
      }
      return iconName;
    },
  },
};
</script>

<style scoped lang="scss">
.contact {
  display: grid;
  gap: 0.75rem;
}
</style>
