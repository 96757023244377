<template>
  <section class="details">
    <div class="details__container container">
      <h2 class="details__title text-h2 basic-black--text">
        {{ $t('contact.moreContactDetails') }}
      </h2>
      <div class="d-flex justify-center light-grey mt-5 py-15 px-5 px-sm-10">
        <div class="details__box d-flex flex-wrap justify-space-between">
          <div class="details__address body-2 mb-5">
            <p class="black--text font-weight-black mb-4">t2l.ink</p>
            <p>{{ $t('global.address') }}</p>
          </div>
          <div class="details__icons dark-grey--text">
            <fa-icon
              v-for="icon in icons"
              :key="icon.url"
              :icon="icon.iconName"
              @click="goToWebsite(icon.url)"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Details',
  data() {
    return {
      icons: [
        {
          iconName: ['fab', 'instagram'],
          url: 'https://www.instagram.com/t2l.ink/',
        },
        {
          iconName: ['fab', 'linkedin'],
          url: 'https://www.linkedin.com/company/tap2link',
        },
        {
          iconName: ['fab', 'facebook-square'],
          url: 'https://www.facebook.com/tap2link/',
        },
      ],
    };
  },
  methods: {
    goToWebsite(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  margin: 5rem 0;
  &__container {
    max-width: 67.125rem;
    @media #{$lg-and-up} {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  &__box {
    width: 100%;
    max-width: 790px;
  }
  &__address {
    width: 228px;
    white-space: pre-line;
  }
  &__icons {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 2.5rem;
    & > svg {
      width: 35px;
      height: 35px;
      cursor: pointer;
    }
  }
}
</style>
