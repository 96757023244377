<template>
  <v-dialog
    v-model="value"
    overlay-color="overlay"
    overlay-opacity="0.75"
    transition="dialog-bottom-transition"
    max-width="640"
    @click:outside="close"
  >
    <div class="dialog">
      <div class="dialog__handler">
        <div class="dialog__handler--rectangle" />
      </div>
      <div class="dialog__wrapper">
        <div
          v-if="title"
          class="dialog__title text-h3 d-flex align-center font-weight-black input-bg--text"
        >
          <slot name="icon">
            <fa-icon v-if="icon && title" :icon="icon" />
          </slot>
          <span class="ml-2">{{ title }}</span>
        </div>
        <slot />
        <slot name="button" />
        <div v-if="$slots.buttons" class="dialog__two-columns">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import preventBgScrolling from '~/utils/preventBgScrolling';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'plus',
    },
    title: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: String,
      default: 'unset',
    },
  },
  emits: ['closeDrawer'],
  watch: {
    value(newVal) {
      if (!process.client) return;
      if (typeof newVal != 'boolean') return;
      this.preventBgScrolling(newVal);
    },
  },
  methods: {
    preventBgScrolling,
    close() {
      this.$emit('closeDrawer');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog__content {
  align-items: flex-end !important;
  @media #{$small} {
    align-items: center !important;
    padding: 0 1rem;
  }
}
::v-deep {
  & .v-dialog {
    margin: 0 !important;
  }
}
.dialog {
  background-color: var(--v-white-base);
  border-radius: 12px;
  padding: 0 1.5rem 1.5rem 1.5rem;
  @media #{$sm-and-up} {
    padding: 1.5rem;
  }
  &__wrapper {
    display: grid;
    gap: 1.5rem;
  }
  &__handler {
    @media #{$sm-and-up} {
      display: none;
    }
    &--rectangle {
      background-color: var(--v-dark-gray-base);
      width: 100px;
      height: 3px;
      border-radius: 2px;
    }
    display: grid;
    place-items: center;
    grid-column: 1 / -1;
    width: 100%;
    height: 40px;
  }
  &__info {
    line-height: 1.4;
  }
  &__two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
    @media #{$under-xs} {
      grid-template-columns: 1fr;
    }
  }
}
</style>
