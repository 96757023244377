<template>
  <div class="request-info">
    <div class="request-info__content">
      <fa-icon
        icon="check-circle"
        class="request-info__icon primary--text mb-6"
      ></fa-icon>
      <div class="request-info__text font-weight-black">
        {{ $t('products.successMessage') }}
      </div>
    </div>
    <div class="d-flex align-center light-main rounded-lg py-2 px-4 mb-6">
      <fa-icon
        :icon="mail ? 'mail-bulk' : 'phone-alt'"
        class="primary--text"
      ></fa-icon>
      <div class="pl-6">
        {{ info }}
      </div>
    </div>
    <Button
      class="button--primary font-weight-bold"
      height="48"
      width="100%"
      @click="$emit('closeDrawer')"
    >
      {{ $t('global.ok') }}
    </Button>
  </div>
</template>

<script>
export default {
  name: 'ContactRequest',
  components: {
    Button: () => import('~/components/shared/Button.vue'),
  },
  props: {
    mail: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    info() {
      if (this.mail) return this.$t('products.mailInfo');
      return this.$t('products.phoneInfo');
    },
  },
};
</script>

<style scoped lang="scss">
.request-info {
  &__content {
    display: grid;
    justify-content: center;
    justify-items: center;
    margin: 5.5rem 0;
    @media #{$under-xs} {
      margin: 3rem 0;
    }
  }
  &__icon {
    font-size: 2.5rem;
  }
  &__text {
    font-size: 1.56rem;
    line-height: 1;
  }
}
</style>
