<template>
  <div class="post" v-if="!isEmpty(post)">
    <img
      :src="post.featuredImage.url"
      :alt="post.featuredImage.alternativeText"
      class="post__wallpaper d-block"
    />
    <div class="post__container">
      <header class="post__header">
        <h1 class="post__title font-weight-black primary-dark--text">
          {{ post.title }}
        </h1>
      </header>
      <main class="post__content">
        <Abstract />
        <Section
          v-for="section in post.sections"
          :key="section.id"
          :section="section"
        />
      </main>
    </div>
    <!-- <RelatedPosts /> -->
  </div>
</template>

<script>
import isEmpty from 'lodash-es/isEmpty';
import { mapActions, mapGetters } from 'vuex';

import Abstract from '~/components/post/Abstract';
// TODO uncomment when more posts provided
// import RelatedPosts from '~/components/post/RelatedPosts';
import Section from '~/components/post/Section';
import silentUrlUpdate from '~/utils/silentUrlUpdate';

export default {
  name: 'Post',
  components: {
    Abstract,
    Section,
    // RelatedPosts,
  },
  middleware: ['blogRedirect'],
  computed: {
    ...mapGetters('blog', ['post', 'localizations']),
  },
  watch: {
    '$i18n.locale': function (newValue) {
      const translatedSlug = this.localizations?.find(
        (localization) => localization.locale === newValue,
      )?.slug;
      if (translatedSlug) {
        silentUrlUpdate(`/blog/${translatedSlug}`);
        this.fetchPostBySlug(translatedSlug);
      } else {
        this.$router.push('/');
      }
    },
  },
  created() {
    this.fetchPostBySlug(this.$route.params.slug);
  },
  methods: {
    isEmpty,
    ...mapActions('blog', ['fetchPostBySlug']),
  },
};
</script>

<style scoped lang="scss">
.post {
  overflow: hidden;
  &__container {
    position: relative;
    max-width: 782px;
    background: var(--v-white-base);
    margin: 0 auto 1.5rem;
    padding: 0 1.3rem;
    @media #{$md-and-up} {
      margin-bottom: 5rem;
      padding: unset;
    }
  }
  &__wallpaper {
    width: 100%;
    max-height: 662px;
    object-fit: cover;
  }
  &__header {
    position: relative;
    margin-bottom: 3.125rem;
    padding: 0.25rem 1.25rem 0;
    @media #{$md-and-up} {
      padding: unset;
      padding-top: 37px;
      margin-bottom: 5rem;
    }
    &::before {
      content: '';
      background-color: var(--v-white-base);
      position: absolute;
      top: -28px;
      left: 50%;
      border-radius: 0.5rem 0.5rem 0 0;
      transform: translateX(-50%);
      width: clamp(100%, 90vw, 891px);
      height: 29px;
      @media #{$md-and-up} {
        top: -67px;
        height: 68px;
      }
    }
  }
  &__title {
    position: relative;
    font-size: 1.625rem;
    line-height: 1.1;
    @media #{$md-and-up} {
      font-size: 3.25rem;
    }
  }
  &__content {
    display: grid;
    row-gap: 2.5rem;
    @media #{$sm-and-up} {
      row-gap: 5rem;
    }
  }
}
</style>
