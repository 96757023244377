<template>
  <section id="pricing" class="pricing container">
    <h2 class="text-lp-h2 basic-black--text text-center mb-7">Pricing</h2>
    <div class="pricing__table">
      <div
        v-for="column in table"
        :key="column.name"
        class="pricing__column rounded-lg px-8 py-10"
      >
        <span class="text-lp-h3">{{ column.name }}</span>
        <v-divider />
        <span class="text-lp-body-2 dark-grey--text">{{ column.info }}</span>
        <v-divider />
        <p class="text-lp-body-2 primary-dark--text">
          <fa-icon class="mr-4" icon="credit-card" />
          {{ column.mainPrice }}
        </p>
        <v-divider />
        <div class="pricing__price-info text-lp-body-2 primary-dark--text">
          <template v-if="column.secondPrice">
            <fa-icon class="mr-4" icon="credit-card" />
            <span>{{ column.secondPrice }}</span>
            <div class="d-flex text-lp-body-3 dark-grey--text mt-7">
              <fa-icon class="green--text mr-2" icon="tag" />
              <span>{{ column.secondPriceInfo }}</span>
            </div>
          </template>
        </div>
        <NuxtLinkButton
          to="/login"
          :class="column.id === 1 ? 'button--lp' : 'button--lp-light'"
        >
          {{ column.button }}
        </NuxtLinkButton>
      </div>
    </div>
  </section>
</template>

<script>
import NuxtLinkButton from '~/components/shared/NuxtLinkButton.vue';
import { PRICING } from '~/constants';

export default {
  name: 'Pricing.vue',
  components: {
    NuxtLinkButton,
  },
  computed: {
    table() {
      return [
        {
          id: 1,
          name: this.$t('pricing.freetrial'),
          info: this.$t('pricing.freetrialInfo'),
          mainPrice: PRICING.TRIAL,
          secondPrice: null,
          button: this.$t('global.getStartedForFree'),
        },
        {
          id: 2,
          name: this.$t('pricing.basic'),
          info: this.$t('pricing.basicInfo'),
          mainPrice: PRICING.BASIC,
          secondPrice: null,
          button: this.$t('global.getStarted'),
        },
        {
          id: 3,
          name: this.$t('pricing.pro'),
          info: this.$t('pricing.proInfo'),
          mainPrice: PRICING.PROFESSIONAL,
          secondPrice: PRICING.ANNUAL_PROFESSIONAL,
          secondPriceInfo: this.$t('pricing.bestValue'),
          button: this.$t('global.getStarted'),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.pricing {
  width: 100%;
  &__table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  &__column {
    height: 100%;
    display: grid;
    grid-template-rows: repeat(6, min-content) 1fr;
    gap: 1.5rem;
    border: 2px solid var(--v-light-grey-base);
    &:first-of-type {
      background: var(--v-light-grey-base);
    }
  }
  &__price-info {
    white-space: pre-line;
  }
}
</style>
