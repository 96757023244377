<template>
  <header class="header">
    <div class="header__container container">
      <h1 class="text-h1 basic-black--text mb-2">
        {{ $t('contact.questionsOrSupport') }}
      </h1>
      <p class="body-1 dark-grey--text">
        {{ $t('contact.ourSupportTeam') }}
      </p>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<style lang="scss" scoped>
.header {
  margin-top: 6.25rem;
  &__container {
    max-width: 49.375rem;
    @media #{$md-and-up} {
      padding: 0 !important;
    }
  }
}
</style>
