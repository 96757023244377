<template>
  <section id="price" class="price">
    <div class="container">
      <h2 class="price__title mb-5">
        <span class="claret--text">{{ $t('rheinfire.fire') }},&nbsp;</span>
        <span class="silver--text">{{ $t('rheinfire.silver') }}&nbsp;</span>
        <span class="black--text">{{ $t('rheinfire.orBlack') }}</span>
      </h2>
      <h3 class="price__subtitle primary-dark--text mb-10">
        {{ $t('rheinfire.whatFanPerkDoYouWant') }}
      </h3>
      <client-only>
        <div v-if="$vuetify.breakpoint.smAndDown">
          <Swiper
            ref="slider"
            class="swiper"
            :options="swiperOptions"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <SwiperSlide v-for="slide in slides" :key="slide.id">
              <Slide :slide="slide" />
            </SwiperSlide>
          </Swiper>
          <v-btn
            v-if="showBackBtn"
            icon
            width="53"
            height="53"
            elevation="4"
            class="swiper__button white"
            @click="previousSlide"
          >
            <fa-icon icon="chevron-left" />
          </v-btn>
          <v-btn
            v-if="showNextBtn"
            icon
            width="53"
            height="53"
            elevation="4"
            class="swiper__button-next swiper__button white"
            @click="nextSlide"
          >
            <fa-icon icon="chevron-right" />
          </v-btn>
        </div>
        <div v-else class="price__content">
          <div class="swiper-slide" v-for="slide in slides" :key="slide.id">
            <Slide :slide="slide" />
          </div>
        </div>
      </client-only>
    </div>
  </section>
</template>

<script>
import 'swiper/swiper.scss';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import Slide from '~/components/rheinfire/price/Slide';
import shopifyButtons from '~/mixins/shopifyButtons';
import shopifyRheinfireButtons from '~/mixins/shopifyRheinfireButtons';

export default {
  name: 'Price',
  components: {
    Swiper,
    SwiperSlide,
    Slide,
  },
  mixins: [shopifyButtons, shopifyRheinfireButtons],
  data() {
    return {
      showNextBtn: true,
      showBackBtn: false,
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1.2,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.slider?.$swiper;
    },
    slides() {
      return [
        {
          id: 'product-component-1658412174828',
          membershipName: 'Fire edition',
          title: this.$t('rheinfire.fireEdition'),
          titleColor: 'claret--text',
          explanation: [this.$t('rheinfire.aPinMadeForYou')],
          image: '/rheinfire-images/Fire-Edition-Card_fallback.png',
          price: '50 €',
          priceDescription: [
            this.$t('rheinfire.limitedMerchandise'),
            this.$t('rheinfire.rightOfPreSale'),
            this.$t('rheinfire.newsletter'),
            this.$t('rheinfire.exclusiveBenefits'),
          ],
          button: this.$t('rheinfire.buttons.chooseFireCard'),
          srcset: [
            '/rheinfire-images/Fire-Edition-Card_w_305.webp 305w',
            '/rheinfire-images/Fire-Edition-Card_w_450.webp 450w',
            '/rheinfire-images/Fire-Edition-Card_w_561.webp 561w',
            '/rheinfire-images/Fire-Edition-Card_w_610.webp 610w',
            '/rheinfire-images/Fire-Edition-Card_w_915.webp 915w',
          ],
        },
        {
          id: 'product-component-1658412202623',
          membershipName: 'Silver edition',
          title: this.$t('rheinfire.silverEdition'),
          titleColor: 'silver--text',
          explanation: [this.$t('rheinfire.silverCardGivesYouDiscount')],
          image: '/rheinfire-images/Silver-Edition-Card_fallback.png',
          price: '250 €',
          priceDescription: [
            this.$t('rheinfire.allFeaturesOfFireEdition'),
            this.$t('rheinfire.merchandiseDiscount', { amount: '5' }),
            this.$t('rheinfire.visitExclusiveFanzone'),
            this.$t('rheinfire.eligibityToParticipate'),
            this.$t('rheinfire.priorityRightOfTicket'),
            this.$t('rheinfire.flagBearer'),
            this.$t('rheinfire.participationinGame'),
            this.$t('rheinfire.reservedParkingSpace'),
          ],
          button: this.$t('rheinfire.buttons.chooseSilverCard'),
          srcset: [
            '/rheinfire-images/Silver-Edition-Card_w_305.webp 305w',
            '/rheinfire-images/Silver-Edition-Card_w_450.webp 450w',
            '/rheinfire-images/Silver-Edition-Card_w_561.webp 561w',
            '/rheinfire-images/Silver-Edition-Card_w_610.webp 610w',
            '/rheinfire-images/Silver-Edition-Card_w_915.webp 915w',
          ],
        },
        {
          id: 'product-component-1658412106497',
          membershipName: 'Black edition',
          title: this.$t('rheinfire.blackEdition'),
          titleColor: 'black--text',
          explanation: [this.$t('rheinfire.yourShoppingTrademark')],
          image: '/rheinfire-images/Black-Edition-Card_fallback.png',
          price: '500 €',
          priceDescription: [
            this.$t('rheinfire.allFeaturesOfSilverEdition'),
            this.$t('rheinfire.exclusiveNameBadge'),
            this.$t('rheinfire.merchandiseDiscount', { amount: '10' }),
            this.$t('rheinfire.autographedMiniHelmet'),
            this.$t('rheinfire.rightToParticipate'),
            this.$t('rheinfire.exclusiveBackstage'),
          ],
          button: this.$t('rheinfire.buttons.chooseBlackCard'),
          srcset: [
            '/rheinfire-images/Black-Edition-Card_w_305.webp 305w',
            '/rheinfire-images/Black-Edition-Card_w_458.webp 458w',
            '/rheinfire-images/Black-Edition-Card_w_550.webp 550w',
            '/rheinfire-images/Black-Edition-Card_w_610.webp 610w',
            '/rheinfire-images/Black-Edition-Card_w_915.webp 915w',
          ],
        },
      ];
    },
  },
  methods: {
    // This method does nothing but is needed to make everything work
    onSwiper() {},
    onSlideChange() {
      this.showNextBtn = !this.swiper.isEnd;
      this.showBackBtn = !this.swiper.isBeginning;
    },
    previousSlide() {
      this.swiper?.slidePrev();
    },
    nextSlide() {
      this.swiper?.slideNext();
      this.showNextBtn = !this.swiper.isEnd;
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  margin: 5rem 0;
  padding-top: 6rem;
  @media #{$lg-and-up} {
    margin: 5rem;
  }
  &__title {
    font-size: 3.5rem;
    line-height: 1;
    @media #{$sm-and-up} {
      font-size: 5rem;
    }
  }
  &__subtitle {
    font-size: 1.5rem;
    @media #{$sm-and-up} {
      font-size: 2rem;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 2.5rem 1.5rem;
    @media #{$sm-and-up} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media #{$lg-and-up} {
      gap: 2.5rem 4.3rem;
    }
  }
}
.swiper-slide {
  height: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: max-content 1fr;
}
.swiper__button {
  position: absolute;
  z-index: 10;
  &-next {
    right: 5%;
  }
}
</style>
