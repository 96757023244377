<template>
  <section class="header">
    <v-container>
      <div class="header__content">
        <h1
          class="header__title cream--text text-uppercase font-weight-black mb-10"
        >
          {{ $t('rheinfire.isFireBurningInYou') }}
        </h1>
        <h3 class="header__subtitle white--text font-weight-black mb-2">
          {{ $t('rheinfire.comeToHeatClub') }}
        </h3>
        <p class="header__paragraph white--text font-weight-light mb-10">
          {{ $t('rheinfire.getBestFanBenefits') }}
        </p>
        <Button
          class="header__button button--cream"
          height="46"
          width="100%"
          outlined
          dark
          color="cream"
          @click="scrollToId('price')"
        >
          <span class="header__text font-weight-medium">
            {{ $t('rheinfire.buttons.fansThisWay') }}
          </span>
        </Button>
      </div>
    </v-container>
  </section>
</template>
<script>
import scrollToId from '~/utils/scrollToId';

export default {
  components: {
    Button: () => import('~/components/shared/Button.vue'),
  },
  methods: {
    scrollToId,
  },
};
</script>
<style lang="scss" scoped>
.header {
  background-image: url('/images/rheinfire_header_fallback.png');
  background-image: url('/images/rheinfire_header_600w.webp');
  background-size: cover;
  background-position: center top;
  padding: 6.5rem 0;
  @media #{$sm-and-up} {
    background-image: url('/images/rheinfire_header_960w.webp');
    padding: 7.3rem 0;
  }
  @media #{$md-and-up} {
    background-image: url('/images/rheinfire_header_1264w.webp');
  }
  &__content {
    @media #{$sm-and-up} {
      max-width: 36.25rem;
    }
  }
  &__title {
    font-size: 3.5rem;
    line-height: 1;
    @media #{$sm-and-up} {
      font-size: 5rem;
    }
  }
  &__subtitle {
    font-size: 1.5rem;
    line-height: 1.1;
    @media #{$sm-and-up} {
      font-size: 2rem;
    }
  }
  &__paragraph {
    font-size: 1.125rem;
    line-height: 1.4;
    @media #{$sm-and-up} {
      font-size: 1.5rem;
    }
  }
  &__button {
    @media #{$sm-and-up} {
      width: 11.7rem !important;
    }
  }
  &__text {
    font-size: 1.125rem;
    line-height: 1.4;
  }
}
</style>
