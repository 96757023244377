<template>
  <div class="app-container d-flex flex-column">
    <RegisterNavigation>
      <nuxt-link
        class="d-flex align-center"
        :to="{ path: '/', query: { ...$route.query } }"
      >
        <svg-icon
          class="primary--text"
          name="tap2link-logo"
          width="54"
          height="25"
        />
      </nuxt-link>
      <div class="d-flex align-center">
        <p class="text-body-2 mr-3">{{ $t('login.haveAccount') }}</p>
        <Button
          height="44"
          class="nav__btn button--rounded button--secondary text-button-2"
          @click="routerPushWithQuery('/login')"
        >
          {{ $t('login.login') }}
        </Button>
      </div>
    </RegisterNavigation>
    <main class="register d-flex flex-column container flex-grow-1">
      <div v-show="custom" class="register__header">
        <div class="d-flex flex-column justify-center">
          <h1 class="text-h1 primary-dark--text">
            {{ $t('login.register') }}
          </h1>
          <p class="text-body-2 dark-grey--text mb-2">
            {{ $t('login.createAccount') }}
          </p>
        </div>
        <v-img
          :src="customLogo"
          class="ml-auto rounded-lg"
          width="68"
          max-height="68"
          :alt="`${customCompanyName}logo`"
        />
      </div>
      <h1 v-show="!custom" class="text-h1 primary-dark--text">
        {{ $t('login.register') }}
      </h1>
      <p v-show="!custom" class="text-body-2 dark-grey--text mb-2">
        {{ $t('login.createAccount') }}
      </p>
      <ValidationObserver
        ref="form"
        v-slot="{ handleSubmit }"
        class="flex-grow-1"
      >
        <v-form
          class="register__form d-flex flex-column"
          @submit.prevent="handleSubmit(handleRegister)"
        >
          <CompactInput
            v-model="name"
            :label="$t('login.name')"
            :placeholder="
              $t('login.insertPlaceholder', { inputLabel: $t('login.name') })
            "
            autocomplete="given-name"
            rules="required|alpha_spaces"
          />
          <CompactInput
            v-model="surname"
            :label="$t('login.surname')"
            :placeholder="
              $t('login.insertPlaceholder', { inputLabel: $t('login.surname') })
            "
            autocomplete="family-name"
            rules="required|alpha_spaces"
          />
          <CompactInput
            v-model="email"
            :label="$t('login.email')"
            :placeholder="
              $t('login.insertPlaceholder', { inputLabel: $t('login.email') })
            "
            autocomplete="email"
            rules="required|email"
          />
          <CompactInput
            v-model="password"
            :label="$t('login.password')"
            :placeholder="
              $t('login.insertPlaceholder', {
                inputLabel: $t('login.password'),
              })
            "
            autocomplete="current-password"
            type="password"
            rules="required|passwordRules"
          />
          <Dropdown
            :id="selectedLanguageId"
            :items="languages"
            :label="$t('login.language')"
            activeColor="primary-dark--text"
            @chooseItem="chooseLanguage"
          />
          <Checkbox
            v-model="privacyNotice"
            rules="required"
            :text="$t('global.iAgreeOnThe')"
          >
            <template #link>
              <span
                class="payment__link text-body-2-link d-inline-block text-decoration-underline primary--text"
                @click.stop.prevent="openDialog(PRIVACY_POLICY)"
              >
                {{ $t('global.privacyPolicy') }}
              </span>
              {{ $t('global.privacyPolicyPartThree') }}
            </template>
          </Checkbox>
          <Checkbox
            v-model="termsOfUse"
            rules="required"
            :text="$t('global.iAgreeOnThe')"
          >
            <template #link>
              <span
                class="payment__link text-body-2 d-inline-block text-decoration-underline primary--text"
                @click.stop.prevent="openDialog(TERMS_OF_USE)"
              >
                {{ $t('global.termsOfUse') }}
              </span>
              {{ $t('global.termsAndConditionsPartThree') }}
            </template>
          </Checkbox>
          <Button
            width="100%"
            height="64"
            color="primary"
            :loading="loading"
            class="register__btn button--rounded button--primary mt-auto"
            type="submit"
          >
            {{ $t('login.register') }}
          </Button>
        </v-form>
      </ValidationObserver>
      <Dialog
        icon="shield-alt"
        :title="dialogTitle"
        :value="showDialog"
        @closeDrawer="showDialog = false"
      >
        <component :is="dialogOption" />
        <v-divider />
        <Button
          width="100%"
          height="48"
          color="light-main"
          class="register__btn button--rounded button--light mt-auto"
          @click="showDialog = false"
        >
          {{ $t('global.ok') }}
        </Button>
      </Dialog>
    </main>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';

import Checkbox from '~/components/form/Checkbox.vue';
import RegisterNavigation from '~/components/shared/AppNavigation.vue';
import Button from '~/components/shared/Button.vue';
import CompactInput from '~/components/shared/Compact.vue';
import Dropdown from '~/components/shared/Dropdown.vue';
import { PRIVACY_POLICY, TERMS_OF_USE } from '~/constants';
import customTheme from '~/mixins/customTheme';
import watchValidation from '~/mixins/watchValidation';

export default {
  name: 'Register',
  components: {
    RegisterNavigation,
    Button,
    CompactInput,
    Dropdown,
    Checkbox,
    ValidationObserver,
    Dialog: () => import('~/components/Dialog.vue'),
    PrivacyPolicy: () => import('~/components/PrivacyPolicy.vue'),
    TermsOfUse: () => import('~/components/TermsOfUse.vue'),
  },
  mixins: [watchValidation, customTheme],
  layout: 'app',
  data() {
    return {
      name: '',
      surname: '',
      email: '',
      password: '',
      selectedLanguageId: '1',
      languages: [
        {
          id: '1',
          code: 'en',
          text: this.$t('global.english'),
        },
        {
          id: '2',
          code: 'de',
          text: this.$t('global.german'),
        },
      ],
      privacyNotice: null,
      termsOfUse: null,
      showDialog: false,
      dialogOption: '',
      PRIVACY_POLICY,
      TERMS_OF_USE,
    };
  },
  computed: {
    ...mapGetters('auth', ['loading']),
    activeLanguage() {
      return this.languages.find((lang) => lang.id === this.selectedLanguageId);
    },
    dialogTitle() {
      return this.dialogOption === PRIVACY_POLICY
        ? this.$t('login.privacyNotice')
        : this.$t('login.termsOfUse');
    },
  },
  mounted() {
    this.selectedLanguageId =
      this.languages.find((lang) => lang.code === this.$i18n.locale).id ?? '1';
  },
  methods: {
    ...mapActions('auth', ['createUser']),
    openDialog(dialogOption) {
      this.dialogOption = dialogOption;
      this.showDialog = true;
      this.privacyNotice = false;
    },
    handleRegister() {
      this.createUser({
        firstName: this.name,
        lastName: this.surname,
        email: this.email,
        password: this.password,
        language: this.activeLanguage.code,
      });
    },
    chooseLanguage(id) {
      this.selectedLanguageId = id;
      this.$i18n.setLocale(this.activeLanguage.code);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  min-height: 100vh;
  padding-bottom: 5rem !important;
}

.register {
  margin-top: 8rem;
  gap: 1rem;
  &__btn {
    &::v-deep {
      .v-btn__content {
        line-height: 1.55;
      }
    }
  }
  &__form {
    height: 100%;
    gap: 1rem;
  }
}
.checkbox__label {
  &--link {
    display: inline-block;
    &:hover {
      color: var(--v-primary-hover-base) !important;
    }
  }
}
</style>
