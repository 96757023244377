<template>
  <section id="go-for-it" class="go">
    <div class="container">
      <div class="go__container container--internal">
        <h2 class="go__title text-lp-h2 basic-black--text mb-6">
          {{ $t('goForIt.justGoForIt') }}
        </h2>
        <p class="go__description text-lp-body-2 dark-grey--text mb-10 mb-sm-0">
          {{ $t('goForIt.stepByStep') }}
        </p>
        <div class="go__buttons">
          <NuxtLinkButton to="/welcome" class="button--lp">
            {{ $t('goForIt.getStarted') }}
          </NuxtLinkButton>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NuxtLinkButton from '~/components/shared/NuxtLinkButton.vue';

export default {
  name: 'GoForIt',
  components: { NuxtLinkButton },
};
</script>

<style scoped lang="scss">
.go {
  padding: clamp(4rem, 8vw, 5rem) 0;
  &__container {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas: 'title' 'description' 'buttons';
    @media #{$sm-and-up} {
      grid-template-columns: minmax(0, 1fr) max-content;
      grid-template-areas: 'title buttons' 'description buttons';
      align-items: center;
      gap: 0 clamp(7rem, 20vw, 11rem);
    }
  }
  &__title {
    grid-area: title;
  }
  &__description {
    grid-area: description;
  }
  &__buttons {
    grid-area: buttons;
  }
}
</style>
