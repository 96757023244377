<template>
  <section class="privacy">
    <div class="privacy__container container px-md-0">
      <h2 class="privacy__title mb-6">
        {{ $t('privacy.privacyPolicyTitle') }}
      </h2>
      <div class="privacy__content text-body-2 text-gray--text">
        <p>{{ $t('privacy.privacyNotice') }}</p>
        <ul>
          <li v-for="number in 2" :key="number">
            <span>{{ $t(`privacy.privacyNotice${number}`) }}</span>
          </li>
        </ul>
        <p>{{ $t('privacy.questionsAndConcerns') }}</p>
        <h2 class="privacy__subtitle">{{ $t('privacy.summary') }}</h2>
        <p>{{ $t('privacy.summaryInfo') }}</p>
        <h2 class="privacy__subtitle">{{ $t('privacy.tableOfContents') }}</h2>
        <ol>
          <li v-for="number in 14" :key="number">
            <span>{{ $t(`privacy.${number}`) }}</span>
          </li>
        </ol>
        <h2 class="privacy__subtitle">1. {{ $t('privacy.1') }}</h2>
        <p>{{ $t('privacy.1_1') }}</p>
        <ul>
          <li v-for="number in 11" :key="number">
            <span>{{ $t(`privacy.1_1_${number}`) }}</span>
          </li>
        </ul>
        <p>{{ $t('privacy.1_2') }}</p>
        <ul>
          <li v-for="number in 4" :key="number">
            <span>{{ $t(`privacy.1_2_${number}`) }}</span>
          </li>
        </ul>
        <p>{{ $t('privacy.1_3') }}</p>
        <ul>
          <li v-for="number in 3" :key="number">
            <span>{{ $t(`privacy.1_3_${number}`) }}</span>
          </li>
        </ul>
        <p>{{ $t('privacy.1_4') }}</p>
        <h2 class="privacy__subtitle">2. {{ $t('privacy.2') }}</h2>
        <p>{{ $t('privacy.2_1') }}</p>
        <ul>
          <li v-for="number in 10" :key="number">
            <span>{{ $t(`privacy.2_1_${number}`) }}</span>
          </li>
        </ul>
        <h2 class="privacy__subtitle">3. {{ $t('privacy.3') }}</h2>
        <p>{{ $t('privacy.3_1') }}</p>
        <p class="primary-dark--text text-decoration-underline">
          {{ $t('privacy.3_2') }}
        </p>
        <p>{{ $t('privacy.3_3') }}</p>
        <ul>
          <li v-for="number in 5" :key="number">
            <span>{{ $t(`privacy.3_3_${number}`) }}</span>
            <template v-if="number === 3">
              <ul>
                <li v-for="number in 3" :key="number">
                  <span>{{ $t(`privacy.3_3_3_${number}`) }}</span>
                </li>
              </ul>
            </template>
          </li>
        </ul>
        <p class="primary-dark--text text-decoration-underline">
          {{ $t('privacy.3_4') }}
        </p>
        <p>{{ $t('privacy.3_5') }}</p>
        <ul>
          <li v-for="number in 11" :key="number">
            <span>{{ $t(`privacy.3_5_${number}`) }}</span>
          </li>
        </ul>
        <h2 class="privacy__subtitle">4. {{ $t('privacy.4') }}</h2>
        <p>{{ $t('privacy.4_1') }}</p>
        <ul>
          <li v-for="number in 5" :key="number">
            <span>{{ $t(`privacy.4_1_${number}`) }}</span>
            <template v-if="number === 2">
              <a
                href="https://policies.google.com/privacy"
                class="privacy__link"
              >
                {{ $t('privacy.link') }}</a
              >
            </template>
          </li>
        </ul>
        <template v-for="number in 5">
          <h2 :key="number" class="privacy__subtitle">
            {{ `${number + 4}. ` }}
            {{ $t(`privacy.${number}`) }}
          </h2>
          <p :key="`${number}_p`">{{ $t(`privacy.${number}_1`) }}</p>
        </template>
        <div>
          <span class="primary-dark--text text-decoration-underline">
            {{ $t('privacy.withdrawingYourContent') }}
          </span>
          <span class="pre-line">{{ $t('privacy.9_2') }}</span>
        </div>
        <div>
          <span class="primary-dark--text text-decoration-underline">
            {{ $t('privacy.optingOut') }}
          </span>
          <span class="pre-line">{{ $t('privacy.9_3') }}</span>
        </div>
        <ul>
          <li>{{ $t('privacy.9_3_1') }}</li>
        </ul>
        <p>{{ $t('privacy.9_4') }}</p>
        <div>
          <span class="primary-dark--text text-decoration-underline">
            {{ $t('privacy.cookies') }}
          </span>
          <span class="pre-line">{{ $t('privacy.9_5') }}</span>
        </div>
        <template v-for="number in 2">
          <h2 :key="`${number}_h2`" class="privacy__subtitle">
            {{ `${number + 9}. ` }}
            {{ $t(`privacy.${number + 9}`) }}
          </h2>
          <p :key="`${number}_paragraph`">
            {{ $t(`privacy.${number + 9}_1`) }}
          </p>
        </template>
        <table>
          <tbody>
            <tr>
              <td>{{ $t('privacy.cartegory') }}</td>
              <td>{{ $t('privacy.examples') }}</td>
              <td>{{ $t('privacy.collected') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.a') }}</td>
              <td>{{ $t('privacy.a_examples') }}</td>
              <td>{{ $t('privacy.yes') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.b') }}</td>
              <td>{{ $t('privacy.b_examples') }}</td>
              <td>{{ $t('privacy.no') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.c') }}</td>
              <td>{{ $t('privacy.c_examples') }}</td>
              <td>{{ $t('privacy.yes') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.d') }}</td>
              <td>{{ $t('privacy.d_examples') }}</td>
              <td>{{ $t('privacy.yes') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.e') }}</td>
              <td>{{ $t('privacy.e_examples') }}</td>
              <td>{{ $t('privacy.no') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.f') }}</td>
              <td>{{ $t('privacy.f_examples') }}</td>
              <td>{{ $t('privacy.no') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.g') }}</td>
              <td>{{ $t('privacy.g_examples') }}</td>
              <td>{{ $t('privacy.no') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.h') }}</td>
              <td>{{ $t('privacy.h_examples') }}</td>
              <td>{{ $t('privacy.yes') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.i') }}</td>
              <td>{{ $t('privacy.i_examples') }}</td>
              <td>{{ $t('privacy.yes') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.j') }}</td>
              <td>{{ $t('privacy.j_examples') }}</td>
              <td>{{ $t('privacy.no') }}</td>
            </tr>
            <tr>
              <td>{{ $t('privacy.k') }}</td>
              <td>{{ $t('privacy.k_examples') }}</td>
              <td>{{ $t('privacy.no') }}</td>
            </tr>
          </tbody>
        </table>
        <p>{{ $t('privacy.11_2') }}</p>
        <ul>
          <li v-for="number in 3" :key="number">
            <span>{{ $t(`privacy.11_2_${number}`) }}</span>
          </li>
        </ul>
        <p>{{ $t('privacy.11_3') }}</p>
        <p class="primary-dark--text text-decoration-underline">
          {{ $t('privacy.requestToDelete') }}
        </p>
        <p>{{ $t('privacy.11_4') }}</p>
        <p class="primary-dark--text text-decoration-underline">
          {{ $t('privacy.requestToKnow') }}
        </p>
        <p>{{ $t('privacy.11_5') }}</p>
        <ul>
          <li v-for="number in 7" :key="number">
            <span>{{ $t(`privacy.11_5_${number}`) }}</span>
          </li>
        </ul>
        <p>{{ $t('privacy.11_6') }}</p>
        <p class="primary-dark--text text-decoration-underline">
          {{ $t('privacy.rightsToNonDiscrimination') }}
        </p>
        <p>{{ $t('privacy.11_7') }}</p>
        <p class="primary-dark--text text-decoration-underline">
          {{ $t('privacy.verificationProcess') }}
        </p>
        <p>{{ $t('privacy.11_8') }}</p>
        <p class="primary-dark--text text-decoration-underline">
          {{ $t('privacy.otherPrivacyRights') }}
        </p>
        <ul>
          <li v-for="number in 4" :key="number">
            <span>{{ $t(`privacy.11_8_${number}`) }}</span>
          </li>
        </ul>
        <p>{{ $t('privacy.11_9') }}</p>
        <template v-for="number in 3">
          <h2 :key="`${number}_h2_2`" class="privacy__subtitle">
            {{ `${number + 11}. ` }}
            {{ $t(`privacy.${number + 11}`) }}
          </h2>
          <p :key="`${number}_paragraph_2`">
            {{ $t(`privacy.${number + 11}_1`) }}
          </p>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

<style lang="scss" scoped>
.privacy {
  overflow-y: scroll;
  max-height: 50vh;
  &__container {
    max-width: 790px;
  }
  &__content {
    display: grid;
    gap: 1.125rem;
    & > p:not(.label) {
      white-space: pre-line;
    }
  }
  &__subtitle,
  &__title {
    color: var(--v-primary-dark-base);
  }
  &__link {
    word-break: break-all;
  }
  &--no-scrollbar {
    overflow-y: unset;
    max-height: unset;
  }
}
p {
  margin-bottom: 0 !important;
}
ol li {
  color: var(--v-primary-dark-base);
}
.link {
  word-break: break-all;
}
.pre-line {
  white-space: pre-line;
}
table {
  border-collapse: collapse;
}
td {
  border: 1px solid var(--v-text-gray-base);
  padding: 0.3rem;
}
</style>
