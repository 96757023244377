<template>
  <main>
    <Header />
    <BoosterClub />
    <Price />
    <Ability />
    <Faq />
    <Hero />
  </main>
</template>
<script>
export default {
  name: 'RheinFireHomepage',
  components: {
    Header: () => import('~/components/rheinfire/Header.vue'),
    BoosterClub: () => import('~/components/rheinfire/BoosterClub.vue'),
    Ability: () => import('~/components/rheinfire/Ability.vue'),
    Hero: () => import('~/components/rheinfire/Hero.vue'),
    Price: () => import('~/components/rheinfire/price/index.vue'),
    Faq: () => import('~/components/rheinfire/Faq.vue'),
  },
  layout: 'rheinfire',
  head() {
    return {
      title: this.$t('rheinfire.metaTitle'),
      meta: [
        {
          title: this.$t('rheinfire.metaTitle'),
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: '/rheinfire-images/preview-image.png',
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('rheinfire.metaTitle'),
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.$t('rheinfire.metaTitle'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('rheinfire.metaTitle'),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('rheinfire.isFireBurningInYou'),
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.$t('rheinfire.metaTitle'),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('rheinfire.isFireBurningInYou'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('rheinfire.isFireBurningInYou'),
        },
      ],
    };
  },
};
</script>
