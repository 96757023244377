<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';

import { RHEINFIRE_QR_REDIRECTION } from '~/constants';

export default {
  layout: 'empty',
  mounted() {
    if (RHEINFIRE_QR_REDIRECTION.includes(this.$route.params.id)) {
      this.$router.push('/rheinfire/homepage');
      return;
    }
    this.fetchCardByQrCode(window.location.href);
  },
  methods: {
    ...mapActions('profile', ['fetchCardByQrCode']),
  },
};
</script>
