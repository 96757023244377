<template>
  <v-btn
    class="go-to-top"
    :class="{ 'go-to-top--visible': isScrollHigher }"
    fab
    width="53"
    height="53"
    :ripple="false"
    color="white"
    @click="goToTop"
    elevation="0"
  >
    <svg-icon name="arrow" width="18" height="16"></svg-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'GoToTop',
  data() {
    return {
      isScrollHigher: false,
    };
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrollHigher = window.scrollY > 100;
    },
    goToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped lang="scss">
.go-to-top {
  position: fixed;
  bottom: 6rem;
  right: 1.5rem;
  padding: 0 !important;
  transform: translateY(-100%);
  visibility: hidden;
  opacity: 0;
  transition: 0.2s $cubic-bezier;
  z-index: 5;

  &::before {
    display: none;
  }
  &:active {
    box-shadow: none;
  }
  &--visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.v-application {
  .go-to-top {
    box-shadow: $box-shadow !important;
  }
}
</style>
