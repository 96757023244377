<template>
  <v-app>
    <RheinfireNavigation />
    <nuxt />
    <Footer />
    <GoToTop />
    <NewCardDialog :redirect-user="true" />
    <CookieControl :locale="$root.$i18n.locale">
      <template v-slot:bar>
        <h3 class="basic-black--text">
          {{ $t('cookieConsent.weUseCookies') }} 🍪
        </h3>
        <p class="dark-grey--text">
          {{ $t('cookieConsent.description') }}
          {{ $t('cookieConsent.legalInformation') }}
          <nuxt-link class="basic-black--text" to="/privacy">
            {{ $t('cookieConsent.privacyPolicy') }}
          </nuxt-link>
          {{ $t('cookieConsent.tolearnMore') }}
        </p>
      </template>
    </CookieControl>
  </v-app>
</template>

<script>
import GoToTop from '~/components/atoms/GoToTop';
import RheinfireNavigation from '~/components/rheinfire/RheinfireNavigation';
import checkAffiliateLinks from '~/middleware/checkAffiliateLinks';
import tabsBroadcast from '~/mixins/tabsBroadcast';

export default {
  name: 'RheinFireLayout',
  components: {
    RheinfireNavigation,
    GoToTop,
    NewCardDialog: () => import('~/components/NewCardDialog.vue'),
    Footer: () => import('~/components/Footer'),
  },
  mixins: [tabsBroadcast],
  middleware: [checkAffiliateLinks],
  head() {
    // TODO: meta tags should be changed
    return {
      titleTemplate: '%s',
      title: this.$t('metaTags.metaTitle'),
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: [
        { title: this.$t('metaTags.metaTitle') },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.$t('metaTags.metaTitle'),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('metaTags.metaDescription'),
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: this.$t('metaTags.metaTitle'),
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: this.$t('metaTags.metaTitle'),
        },
        {
          hid: 'description',
          name: 'description',
          content: this.$t('metaTags.metaDescription'),
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.$t('metaTags.metaDescription'),
        },
      ],
    };
  },
};
</script>
