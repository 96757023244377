<template>
  <ul class="nav__list">
    <li class="nav__item" v-for="item in items" :key="item.id">
      <nuxt-link
        v-if="item.slug"
        :to="item.slug"
        class="nav__link d-block text-lp-body-3 text-decoration-none"
        :class="colorClass"
      >
        {{ item.title }}
      </nuxt-link>
      <a
        v-if="item.link"
        class="nav__link d-block text-lp-body-3 text-decoration-none"
        :class="colorClass"
        :href="item.link"
        :target="item.target"
      >
        {{ item.title }}
      </a>
      <Dropdown
        v-if="item.subItems"
        :items="item.subItems"
        :title="item.title"
        :colorClass="colorClass"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'NavigationList',
  components: {
    Dropdown: () => import('~/components/atoms/Dropdown'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    colorClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.nav {
  &__list {
    list-style: none;
    padding-left: 0;
    &--desktop {
      display: grid;
      grid-auto-flow: column;
      gap: clamp(1.25rem, 2vw, 1.75rem);
      align-items: center;
    }
  }
  &__item {
    &:where(:not(:last-of-type)) {
      margin-bottom: 1.25rem;
    }
  }
  &__list--desktop &__item {
    margin-bottom: 0;
  }
  &__list &__link {
    color: var(--v-basic-black-base);
    &.nuxt-link-exact-active.nuxt-link-active {
      color: var(--v-dark-grey-base) !important;
    }
  }
  &__list--light &__link {
    color: var(--v-white-base);
  }
}
</style>
