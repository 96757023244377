<template>
  <header class="header">
    <div class="header__container container">
      <span class="header__tag text-body-1 text-uppercase dark-grey--text"
        >tap2link business</span
      >
      <h1 class="header__title text-lp-h1 basic-black--text">
        <span>{{ $t('header.businessHeroTitle') }}</span>
      </h1>
      <p class="header__subtitle dark-grey--text">
        <span>{{ $t('header.businessHeroSubtitle') }}</span>
      </p>
      <div class="header__btns d-md-block">
        <Button
          class="header__btn d-inline-block btn--primary ml-0"
          block
          @click="redirect"
        >
          {{ isLoggedIn ? $t('global.goToTheApp') : $t('global.getStarted') }}
        </Button>
        <!--                <p class="d-inline-block dark-grey&#45;&#45;text pr-5">-->
        <!--                  {{ $t('header.orDownload') }}-->
        <!--                </p>-->
        <!--                <div class="d-inline-block">-->
        <!--                  <Button class="btn&#45;&#45;light mr-5">-->
        <!--                    <fa-icon :icon="['fab', 'google-play']" />-->
        <!--                  </Button>-->
        <!--                  <Button class="btn&#45;&#45;light">-->
        <!--                    <fa-icon :icon="['fab', 'apple']" />-->
        <!--                  </Button>-->
        <!--                </div>-->
      </div>
      <nuxt-img
        class="header__image"
        src="/images/business/Hero_business_profile.png"
        sizes="sm:90vw lg:550 xl:600 xxl:710"
        format="webp"
        fetchpriority="high"
        role="presentation"
        preload
      />
    </div>
    <div class="header__dot neutral-medium rounded-circle" />
  </header>
</template>

<script>
import Button from '~/components/atoms/Button';
import loggedInRedirection from '~/mixins/loggedInRedirection';

export default {
  name: 'Header',
  components: {
    Button,
  },
  mixins: [loggedInRedirection],
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  overflow: hidden;
  background: var(--v-neutral-white-base);
  padding: 9rem 0 0;
  width: 100%;
  max-width: 90rem;
  @media #{$sm-and-up} {
    width: calc(100% - 2.5rem);
    border-radius: 12px;
    margin: 1.25rem auto 0;
  }
  @media #{$md-and-up} {
    padding-bottom: 9rem;
  }
  &__container {
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-template-areas: 'tag' 'title' 'subtitle' 'buttons' 'media';
    gap: 1.25rem 0;
    @media #{$md-and-up} {
      grid-template-columns: 1fr 0.8fr;
      grid-template-areas: 'tag media' 'title media' 'subtitle media' 'buttons media';
    }
    @media #{$max-container-width} {
      padding: 0 !important;
    }
  }
  &__tag {
    grid-area: tag;
    align-self: end;
  }
  &__title {
    grid-area: title;
    white-space: pre-line;
  }
  &__subtitle {
    grid-area: subtitle;
    white-space: pre-line;
    font-size: 1.14rem;
    line-height: 1.4;
    z-index: 2;
  }
  &__btns {
    grid-area: buttons;
    z-index: 2;
    @media #{$tiny} {
      display: flex;
      flex-direction: column;
      & > p {
        padding-bottom: 1.25rem;
      }
    }
    & > div {
      margin-bottom: 4rem;
    }
  }
  &__btn {
    width: 100%;
    @media #{$tiny} {
      max-width: 13.87rem;
    }
    @media #{$md-only} {
      margin-right: 18.75rem !important;
    }
  }
  &__image {
    grid-area: media;
    z-index: 1;
    position: relative;
    justify-self: right;
    right: -14px;
    @media #{$sm-and-up} {
      right: -50px;
    }
    @media #{$md-and-up} {
      grid-area: media;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  &__dot {
    position: absolute;
    bottom: -65vw;
    right: -60vw;
    width: 150vw;
    aspect-ratio: 1/1;
    @media #{$sm-and-up} {
      width: 75vw;
      bottom: -28vw;
      right: -8vw;
    }
    @media #{$md-and-up} {
      width: 46.87rem;
      top: 11.25rem;
      right: initial;
      left: 50vw;
    }
  }
}
</style>
