<template>
  <section id="customers" class="customers container">
    <h2 class="text-lp-h2 text-center pb-3">
      {{ $t('businessCard.whatCustomersSay') }}
    </h2>
    <p class="text-lp-body-1 text-center dark-grey--text">
      {{ $t('businessCard.youView') }}
    </p>
    <div class="customers__reviews dark-grey--text mt-11">
      <div v-for="customer in customers" :key="customer.name" class="d-flex">
        <nuxt-img
          :src="customer.src"
          class="mr-6"
          height="80"
          width="80"
          fit="contain"
          format="webp"
          loading="lazy"
        />
        <div>
          <p class="customers__text text-lp-body-2">{{ customer.review }}</p>
          <p class="customers__text text-lp-body-2">{{ customer.name }}</p>
          <p class="customers__text text-lp-subtitle">
            {{ customer.position }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Pricing.vue',
  data() {
    return {
      customers: [
        {
          src: '/images/business/customer1.png',
          review: this.$t('businessCard.message1'),
          name: 'Martin Hofmann,',
          position: this.$t('businessCard.departmentManager'),
        },
        {
          src: '/images/business/customer2.png',
          review: this.$t('businessCard.message2'),
          name: 'Ben Paulis,',
          position: this.$t('businessCard.employee'),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.customers {
  &__reviews {
    display: grid;
    grid-template-columns: repeat(auto-fit, max-content);
    gap: 4rem;
  }
  &__text:not(:first-child) {
    margin-bottom: 0 !important;
  }
}
</style>
