<template>
  <Fragment>
    <nav class="nav">
      <div class="nav__bar claret" :class="navBarClasses">
        <div
          class="nav__container container d-flex justify-space-between align-center"
        >
          <a href="https://www.rheinfire.eu/" target="_blank">
            <svg-icon name="rheinfire-logo" height="46" width="93" />
          </a>
          <svg-icon
            v-if="$vuetify.breakpoint.smAndDown"
            class="nav__hamburger cream--text"
            name="hamburger"
            @click="toggleMenu"
            width="24"
            height="24"
          />
          <div v-else class="nav__content">
            <NavigationList
              :items="rheinFireNavItems"
              class="nav__list--desktop"
              colorClass="white--text"
            />
            <Button color="cream" @click="scrollToId('price')">
              {{ $t('global.getYourMembership') }}
            </Button>
            <LangSwitcher colorClass="white--text" />
          </div>
        </div>
      </div>
    </nav>
    <div>
      <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        temporary
        right
        v-model="drawer"
        width="360"
        color="drawer claret"
      >
        <nav class="drawer__container d-flex flex-column">
          <svg-icon
            class="drawer__close align-self-end white--text mt-11 mb-8"
            name="close"
            width="32"
            height="32"
            @click="toggleMenu"
          />
          <NavigationList :items="rheinFireNavItems" colorClass="white--text" />
          <v-divider class="drawer__divider my-10" />
          <LangSwitcher colorClass="white--text" />
          <Button class="my-10" color="cream" @click="scrollTo">
            {{ $t('global.getYourMembership') }}
          </Button>
        </nav>
      </v-navigation-drawer>
    </div>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

import Button from '~/components/atoms/Button';
import LangSwitcher from '~/components/atoms/LangSwitcher';
import NavigationList from '~/components/atoms/NavigationList';
import loggedInRedirection from '~/mixins/loggedInRedirection';
import navigationItems from '~/mixins/navigationItems';
import scrollToId from '~/utils/scrollToId';

export default {
  components: { LangSwitcher, NavigationList, Button, Fragment },
  mixins: [navigationItems, loggedInRedirection],
  data() {
    return {
      drawer: false,
      scrollLastPosition: 0,
      isScrollUp: true,
      isScrollHigher: false,
    };
  },
  methods: {
    scrollToId,
    scrollTo() {
      this.drawer = false;
      this.scrollToId('price');
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: sticky;
  top: 0;
  z-index: 6;
  &__bar {
    height: 4.875rem;
    transition: 0.2s $cubic-bezier;
    &--hidden {
      transform: translateY(-110%);
      @media #{$md-and-up} {
        transform: none;
      }
    }
    &--shadow {
      box-shadow: $box-shadow;
    }
  }
  &__container {
    height: 100%;
    max-width: unset !important;
    @media #{$md-and-up} {
      padding: 0 clamp(1.25rem, 2.25vw, 2rem) !important;
    }
    @media #{$lg-and-up} {
      padding-left: 0 !important;
      padding-right: 0 !important;
      max-width: 1185px !important;
    }
    @media #{$xl-only} {
      max-width: 1785px !important;
    }
  }
  &__hamburger {
    cursor: pointer;
  }
  &__content {
    display: grid;
    gap: clamp(1.25rem, 2vw, 1.75rem);
    grid-auto-flow: column;
    align-items: center;
  }
  &__button {
    border: 1px solid var(--v-cream-base) !important;
    background: transparent !important;
    color: var(--v-cream-base) !important;
  }
}

.drawer {
  max-width: 100vw !important;
  &__container {
    padding: 1rem clamp(1rem, 5vw, 2.25rem) !important;
  }
  &__close {
    cursor: pointer;
    &:hover,
    &:active {
      color: var(--v-basic-black-base) !important;
    }
  }
  &__divider {
    border-color: var(--v-divider-base) !important;
  }
  &__button {
    border: 1px solid var(--v-cream-base) !important;
    background: transparent !important;
    color: var(--v-cream-base) !important;
  }
}
</style>
