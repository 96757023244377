<template>
  <div>
    <Header />
    <ContactForm />
    <Details />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'contact',
  components: {
    Details: () => import('~/components/Details'),
    Header: () => import('~/components/contact/Header'),
    ContactForm: () => import('~/components/contact/Form'),
  },
  beforeDestroy() {
    this.setSelectedTopic({
      id: 1,
    });
  },
  methods: {
    ...mapActions('contact', ['setSelectedTopic']),
  },
};
</script>

<style scoped></style>
