<template>
  <v-app dark class="app">
    <div v-if="profile" class="profile" :id="customThemeId">
      <Navigation />
      <Header class="profile__header" data-member="true" />
      <main class="profile__content container">
        <RelatedProfiles />
        <v-divider v-if="!hasOwnerAccess" class="mt-2" />
        <template v-if="isChristkindlmarkt">
          <Highlighted />
          <CustomLinks />
          <About />
        </template>
        <ContactDetails v-if="profileHaveContactLinks && !isStaffProfile" />
        <template v-for="item in componentSettings">
          <component
            v-if="item.component === CUSTOM_LINKS"
            :key="item.component + item.id"
            :is="HIGHLIGHTED"
            v-bind="item.props"
          />
          <component :key="item.id" :is="item.component" v-bind="item.props" />
        </template>
        <v-divider v-if="hasOwnerAccess" class="my-2" />
        <div class="profile__settings">
          <MemberStatus
            v-if="hasOwnerAccess"
            :member="memberInfo"
            @membershipStatus="showDialog = $event"
          />
          <ConnectCard v-if="isAdmin" />
        </div>
        <div class="profile__credits">
          <svg-icon
            name="tap2link-logo-long"
            class="profile__logo mt-1"
            @click="goToHomepage"
          />
        </div>
        <nuxt />
      </main>
      <Footer />
    </div>
    <FlashMessage />
    <NewCardDialog />
    <QrCode />
    <Dialog
      v-if="currentOrganizationProfile"
      icon="crown"
      :title="dialogTitle"
      :value="showDialog"
      max-height="80vh"
      @closeDrawer="showDialog = false"
    >
      <component
        :is="activeStep"
        :club-name="currentOrganizationProfile.name"
        :member="memberInfo"
        :owner="isAdmin"
        @showSubscription="activeStep = MEMBERSHIP_STEP.PAYMENT"
        @closeDrawer="closeDrawer"
      />
    </Dialog>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { fetchProfileCustomTheme } from '@/helpers';
import {
  CUSTOM_LINK_TYPE,
  CUSTOM_LINKS,
  HIGHLIGHTED,
  MEMBERSHIP_STEP,
  PROFILE_STATUS,
  PROFILE_TYPE,
} from '~/constants';
import checkAffiliateLinks from '~/middleware/checkAffiliateLinks';
import fetchUserData from '~/mixins/fetchUserData';
import goToDashboard from '~/mixins/goToDashboard';
import tabsBroadcast from '~/mixins/tabsBroadcast';

export default {
  name: 'MemberLayout',
  components: {
    MemberStatus: () => import('~/components/profile/MemberStatus.vue'),
    Highlighted: () => import('~/components/profile/Highlighted.vue'),
    About: () => import('~/components/profile/About.vue'),
    CustomLinks: () => import('~/components/profile/CustomLinks.vue'),
    QrCode: () => import('~/components/QrCode.vue'),
    NewCardDialog: () => import('~/components/NewCardDialog.vue'),
    Dialog: () => import('~/components/Dialog.vue'),
    FlashMessage: () => import('~/components/shared/FlashMessage.vue'),
    MembershipStatus: () => import('~/components/shared/MembershipStatus.vue'),
    SubscriptionList: () => import('~/components/shared/SubscriptionList.vue'),
    Navigation: () => import('~/components/profile/Navigation.vue'),
    Header: () => import('~/components/profile/Header.vue'),
    RelatedProfiles: () => import('~/components/profile/RelatedProfiles.vue'),
    ContactDetails: () => import('~/components/profile/Contact.vue'),
    Footer: () => import('~/components/profile/Footer.vue'),
    ConnectCard: () => import('~/components/profile/ConnectCard.vue'),
  },
  mixins: [goToDashboard, fetchUserData, tabsBroadcast],
  middleware: [checkAffiliateLinks],
  data() {
    return {
      MEMBERSHIP_STEP,
      CUSTOM_LINK_TYPE,
      CUSTOM_LINKS,
      HIGHLIGHTED,
      showDialog: false,
      activeStep: MEMBERSHIP_STEP.STATUS,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'currentOrganizationProfile',
      'isAdmin',
      'isClub',
      'isCompany',
      'isProfileOwner',
      'isStaffProfile',
      'photoUrl',
      'profile',
      'profileHaveContactLinks',
      'staffSettings',
      'customThemeId',
    ]),
    ...mapGetters('organization', ['member']),
    componentSettings() {
      if (!this.staffSettings?.components) return null;
      const components = this.staffSettings.components;
      const updatedList = [];
      for (const item of components) {
        if (this.staffSettings[item.settingsName]) {
          updatedList.push(item);
        }
      }
      return updatedList;
    },
    hasOwnerAccess() {
      return (
        this.currentOrganizationProfile?.profileType === PROFILE_TYPE.CLUB &&
        (this.isProfileOwner || this.isAdmin)
      );
    },
    isChristkindlmarkt() {
      return this.$route.params?.organization === 'Christkindlmarkt.club';
    },
    memberInfo() {
      if (!this.member.length) return null;
      return this.member[0];
    },
    dialogTitle() {
      if (this.activeStep === MEMBERSHIP_STEP.STATUS)
        return this.$t('members.membershipStatus');
      return this.$t('members.payments');
    },
  },
  async created() {
    const profile = this.currentOrganizationProfile;
    if (profile?.profileType === PROFILE_TYPE.CLUB) {
      await this.findAllMembershipTypes(profile.id);
      await this.findAllMembers(profile.id);
    }
    await this.fetchProfile({
      profileUniqueValue: this.currentOrganizationProfile?.id,
      organizationProfile: true,
    });
    if (this.profile?.profileStatus === PROFILE_STATUS.UNACTIVE) {
      this.$router.replace('/profile-not-found');
    }
  },
  mounted() {
    if (this.customThemeId) fetchProfileCustomTheme(this.customThemeId);
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions('organization', ['findAllMembershipTypes', 'findAllMembers']),
    goToHomepage() {
      window.location.href = process.env.baseUrl;
    },
    closeDrawer() {
      this.activeStep = MEMBERSHIP_STEP.STATUS;
      this.showDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  height: 100%;
  &__content {
    position: relative;
    display: grid;
    gap: 1rem;
    margin-bottom: 7.25rem;
    @media #{$under-xs} {
      margin-bottom: 11rem;
    }
  }
  &__credits {
    justify-self: center;
  }
  &__logo {
    width: 127px;
    height: 26px;
  }
  &__button {
    height: 4.375rem;
    border-radius: 12px;
    &--active {
      background-color: var(--v-confirmation-bg-base) !important;
      border: 1px solid var(--v-green-confirmation-base) !important;
      color: var(--v-green-confirmation-base) !important;
    }
    &--expired {
      background-color: var(--v-error-red-light-base) !important;
      border: 1px solid var(--v-error-red-base) !important;
      color: var(--v-error-red-base) !important;
    }
    &--invited {
      background-color: var(--v-light-grey-base) !important;
      border: 1px solid var(--v-light-grey-border-base) !important;
      color: var(--v-dark-grey-base) !important;
    }
  }
}
.app {
  min-height: 100vh;
  padding-top: 0 !important;
  background-color: var(--v-background-base);
  ::v-deep .container {
    padding: 0 1rem !important;
    max-width: 672px !important;
    @media #{$small} {
      max-width: 640px !important;
      padding: 0 !important;
    }
  }
}
</style>
