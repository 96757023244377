<template>
  <Dialog :value="isOpen" @closeDrawer="closeDialog">
    <div v-if="profile" class="d-flex">
      <Avatar
        :avatar-image="getStorageUrl(photoName)"
        :profile-full-name="imagePlaceholder"
        :profile-type="profile.profileType"
        class-variant="avatar--member mr-3"
      />
      <div class="d-flex flex-column justify-center">
        <div class="text-h2 primary-dark--text">
          <span>{{ profile.name }}</span>
        </div>
        <div v-if="membershipType" class="dialog__subtitle text-body-1">
          <fa-icon v-if="icon" :icon="icon" class="mr-2" />
          {{ membershipType }} {{ icon && $t('members.member') }}
        </div>
        <div v-else class="dialog__subtitle text-body-1">
          {{ profile.position }}
        </div>
      </div>
    </div>
    <div class="dialog__qr mx-auto">
      <SwitchWithText
        v-if="membershipType && isProfileOwner"
        v-model="checkIn"
        :left-label="$t('global.profile')"
        :right-label="$t('members.checkIn')"
      />
      <p v-if="checkIn" class="dialog__info text-gray--text">
        {{ $t('profile.qrCodeValid') }}
      </p>
      <QrCodeVue
        :value="checkIn ? checkInQrCodeLink : qrCodeLink"
        render-as="svg"
        class="text-center"
      />
    </div>
    <template v-slot:buttons>
      <Button
        width="100%"
        height="48"
        class="button--rounded button--secondary"
        @click="closeDialog"
      >
        {{ $t('global.close') }}
      </Button>
      <Button
        width="100%"
        height="48"
        class="button--rounded button--primary"
        :disabled="refreshDelay"
        @click="handleButtonClick"
      >
        {{ checkIn ? $t('global.reload') : $t('global.share') }}
      </Button>
    </template>
  </Dialog>
</template>

<script>
import QrCodeVue from 'qrcode.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Avatar from '~/components/atoms/Avatar.vue';
import Dialog from '~/components/Dialog.vue';
import Button from '~/components/shared/Button.vue';
import SwitchWithText from '~/components/shared/SwitchWithText.vue';
import { PROFILE_TYPE } from '~/constants';
import { getStorageUrl } from '~/helpers';
import copyToClipboard from '~/utils/copyToClipboard';

export default {
  name: 'QrCode',
  components: {
    Avatar,
    Button,
    Dialog,
    SwitchWithText,
    QrCodeVue,
  },
  data() {
    return {
      isOpen: false,
      size: 300,
      PROFILE_TYPE,
      // TODO temporary value change
      checkIn: true,
      checkInQrCodeLink: `${Date.now()}`,
      refreshDelay: false,
      timeOutId: null,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'profile',
      'isProfileOwner',
      'qrCodeDialog',
      'currentProfileMembership',
    ]),
    photoName() {
      return this.profile?.photoName ?? '';
    },
    imagePlaceholder() {
      return this.profile.name;
    },
    icon() {
      return this.currentProfileMembership?.iconName ?? '';
    },
    membershipType() {
      return this.currentProfileMembership?.membershipName ?? '';
    },
    qrCodeLink() {
      return `${process.env.baseUrl}${this.$route.fullPath}`;
    },
  },
  watch: {
    qrCodeDialog(newValue) {
      this.isOpen = newValue;
      if (newValue && this.membershipType) {
        this.refreshQrCode();
      }
    },
    checkIn(newValue) {
      if (newValue) {
        this.refreshQrCode();
      }
    },
    refreshDelay: {
      deep: true,
      handler(newValue) {
        if (newValue) return;
        clearTimeout(this.timeOutId);
      },
    },
  },
  methods: {
    ...mapMutations('profile', ['setQrCodeDialog']),
    ...mapActions('profile', ['refreshScannerQrCode']),
    getStorageUrl,
    copyToClipboard,
    closeDialog() {
      // TODO temporary value change
      this.checkIn = true;
      this.isOpen = false;
      this.setQrCodeDialog(false);
    },
    refreshQrCode() {
      this.checkInQrCodeLink = `${this.qrCodeLink}&${Date.now()}`;
      this.refreshScannerQrCode(this.profile.id);
      this.refreshDelay = true;
      this.timeOutId = setTimeout(() => {
        this.refreshDelay = false;
      }, 1000);
    },
    shareProfile() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            text: this.profile.name,
            url: this.qrCodeLink,
          })
          .catch((err) => console.error(err));
      } else {
        this.$root.$emit('show-flash-message', {
          message: this.$i18n.t('global.copySuccessful'),
          type: 'primary',
        });
        this.copyToClipboard(this.qrCodeLink);
      }
    },
    handleButtonClick() {
      if (this.checkIn) this.refreshQrCode();
      else this.shareProfile();
    },
  },
};
</script>

<style scoped lang="scss">
.dialog {
  &__subtitle {
    color: var(--v-text-gray-base) !important;
  }
  &__qr {
    max-width: 330px;
    width: 100%;
    display: grid;
    gap: 0.75rem;
    @media #{$tiny} {
      gap: 1.5rem;
    }
    ::v-deep {
      & svg {
        width: 50% !important;
        height: auto !important;
        @media #{$tiny} {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
  &__info {
    font-size: 0.8125rem;
    @media #{$tiny} {
      font-size: 1rem;
    }
  }
}
</style>
