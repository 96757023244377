<template>
  <div />
</template>

<script>
import Cookie from 'js-cookie';
import { mapActions } from 'vuex';

import { CUSTOM_INVITATION, CUSTOM_THEME } from '~/constants';

export default {
  name: 'Company-event',
  layout: 'empty',
  async mounted() {
    const company = this.$route.params.company;
    Cookie.set(CUSTOM_INVITATION, true);
    Cookie.set(CUSTOM_THEME, company);
    const profile = await this.fetchProfile({
      profileUniqueValue: company,
    });
    delete profile.customLinks;
    profile?.id && Cookie.set('custom-theme-profile', JSON.stringify(profile));
    if (!this.$apolloHelpers.getToken()) {
      return this.$router.push({
        path: '/welcome',
        query: { company },
      });
    }
    window.location.href = `${process.env.baseUrl}/app/dashboard?company=${company}`;
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
  },
};
</script>

<style lang="scss" scoped></style>
