<template>
  <section class="hero">
    <v-container class="hero__container">
      <v-img
        src="/images/rheinfire_seeYou_fallback.png"
        srcset="/images/rheinfire_seeYou_327w.webp 425w, /images/rheinfire_seeYou_835w.webp 900w, /images/rheinfire_seeYou_1072w.webp 1100w"
        alt=""
        class="hero__image rounded-lg d-flex justify-center align-center"
      >
        <h2 class="hero__title font-weight-black white--text text-center">
          {{ $t('rheinfire.seeYou') }}
        </h2>
      </v-img>
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.hero {
  margin-bottom: 5rem;
  &__container {
    height: 35.65rem;
    @media #{$sm-and-up} {
      padding: 0 !important;
    }
  }
  &__image {
    height: 100%;
  }
  &__title {
    font-size: 3.25rem;
    line-height: 1.1;
  }
}
</style>
