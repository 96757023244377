<template>
  <section id="shop" class="shop">
    <div class="shop__container container">
      <div class="shop__container--internal">
        <div class="shop__content">
          <h2 class="text-lp-h2 mb-6">{{ $t('shop.shop') }}</h2>
          <h3 class="text-lp-body-2 black--text mb-2">
            {{ $t('shop.theFastWay') }}
          </h3>
          <p class="text-lp-body-2 dark-grey--text mb-6">
            {{ $t('shop.whichCardSuitsYou') }}
          </p>
          <Button
            class="shop__btn btn--primary"
            href="https://shop.t2l.ink"
            rel="noopener noreferrer"
            target="_blank"
          >
            {{ $t('shop.selectCardNow') }}
          </Button>
        </div>
        <ul class="shop__cards" ref="cards">
          <CardSlide
            v-for="card in cards"
            :key="card.src"
            ref="slide"
            :card="card"
          />
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '~/components/atoms/Button';
import CardSlide from '~/components/CardSlide';
import shopifyButtons from '~/mixins/shopifyButtons';
import shopifyCardsButtons from '~/mixins/shopifyCardsButtons';
export default {
  name: 'Shop',
  components: { CardSlide, Button },
  mixins: [shopifyButtons, shopifyCardsButtons],
  data() {
    return {
      cards: [
        {
          src: '/shop-cards/card-violet.png',
          title: 'Simply Violet',
          id: 'product-component-1662464059147',
        },
        {
          src: '/shop-cards/card_turquoise.png',
          title: 'Island Turquise',
          id: 'product-component-1662463972164',
        },
        {
          src: '/shop-cards/card_black.png',
          title: 'Pure Black',
          id: 'product-component-1662464019767',
        },
        {
          src: '/shop-cards/card_white.png',
          title: 'Diamond White',
          id: 'product-component-1662464103653',
        },
        {
          src: '/shop-cards/card_blue.png',
          title: 'Abyss Blue',
          id: 'product-component-1662464188079',
        },
        {
          src: '/shop-cards/card_qr.png',
          title: 'Abyss Blue - Qr',
          id: 'product-component-1662464143007',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.shop {
  &__container {
    padding-top: clamp(2.5rem, 8vw, 5rem) !important;
    padding-bottom: clamp(2.5rem, 8vw, 5rem) !important;
    @media #{$container-width} {
      border-radius: 8px;
    }
    &--internal {
      position: relative;
      display: grid;
      gap: 3rem;
      @media #{$md-and-up} {
        grid-template-columns: 30% 1fr;
        grid-auto-flow: dense;
      }
    }
  }
  &__content {
    @media #{$sm-and-up} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, min-content);
      grid-auto-flow: column;
    }
    @media #{$md-and-up} {
      display: block;
    }
  }
  &__btn {
    grid-row: 1 / -1;
    place-self: center;
  }
  &__cards {
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 1rem;
    scroll-behavior: smooth;
    cursor: ew-resize;
    @media #{$sm-and-up} {
      width: 90vw;
    }
    @media #{$lg-and-up} {
      width: calc(3 * 300px);
    }
  }
}
.swiper__button {
  position: absolute;
  z-index: 5;
  &-next {
    right: 5%;
  }
}
</style>
