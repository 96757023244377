<template>
  <section class="topics mb-10" :class="{ 'topics--nested': nested }">
    <div
      v-for="FAQSection in currentContent"
      :key="FAQSection.title"
      class="topics__container container"
    >
      <h2 class="text-h2 mb-4">{{ FAQSection.title }}</h2>
      <v-expansion-panels multiple class="topics__content">
        <Panel
          v-for="question in FAQSection.questions"
          :key="question.header"
          :header="question.header"
          :content="question.content"
          :link="question.link"
          :nested="question.nested"
        />
      </v-expansion-panels>
    </div>
  </section>
</template>

<script>
import Panel from '~/components/faq/Panel';
import FAQMixin from '~/mixins/FAQMixin';

export default {
  name: 'PanelSection',
  components: {
    Panel,
  },
  mixins: [FAQMixin],
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentContent() {
      return this.nested ? this.phonesContent : this.FAQContent;
    },
  },
};
</script>

<style lang="scss" scoped>
.topics {
  display: grid;
  gap: 2.5rem;
  &__container {
    width: 100%;
    max-width: 49.375rem;
    @media #{$md-and-up} {
      padding: 0 !important;
    }
  }
  &__content {
    gap: 0.5rem !important;
    .v-expansion-panel {
      border-radius: 0.5rem !important;
    }
  }
  &--nested {
    margin-bottom: 1rem !important;
    ::v-deep {
      .v-expansion-panel {
        background-color: var(--v-white-base) !important;
      }
    }
  }
}
</style>
