<template>
  <div
    class="profile__button d-flex align-center px-4 py-3 mb-4"
    :class="statusClass"
  >
    <fa-icon :icon="statusIcon" class="mr-2" />
    <span class="text-body-1 primary-dark--text">
      {{ membershipName }} {{ $t('members.member') }}
    </span>
    <v-btn icon class="btn ml-auto" @click="openMembershipStatus">
      <fa-icon icon="edit" class="primary--text" />
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { MEMBERSHIP_STATUS } from '~/constants';

export default {
  name: 'MemberStatus.vue',
  props: {
    member: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    membershipName() {
      return this.member?.membershipName ?? '';
    },
    statusClass() {
      if (!this.member?.membershipStatus) return '';
      return `profile__button--${this.member.membershipStatus.toLowerCase()}`;
    },
    statusIcon() {
      if (this.member?.membershipStatus) {
        return {
          [MEMBERSHIP_STATUS.INVITED]: 'question-circle',
          [MEMBERSHIP_STATUS.ACTIVE]: 'check-circle',
          [MEMBERSHIP_STATUS.EXPIRED]: 'exclamation-circle',
        }[this.member.membershipStatus];
      }
      return 'question-circle';
    },
  },
  methods: {
    ...mapActions('payments', ['getCustomerInvoices', 'getAvailablePlans']),
    async openMembershipStatus() {
      this.$emit('membershipStatus', true);
      const subscriberId = this.member?.account?.personIdentifier;
      await this.getAvailablePlans();
      await this.getCustomerInvoices(subscriberId);
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  display: grid;
  place-items: center;
}
</style>
