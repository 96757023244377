<template>
  <li class="slide mb-8" :id="id">
    <div class="slide__container">
      <div class="slide__img d-flex justify-center neutral-white rounded-lg">
        <nuxt-img
          :src="card.src"
          :alt="card.title"
          width="287"
          height="240"
          loading="lazy"
          format="webp"
        />
      </div>
      <div>
        <p class="text-subtitle dark-grey--text pb-3">
          {{ card.title }}
        </p>
        <span class="text-body-2">{{ price }}</span>
      </div>
      <div :id="card.id" class="slide__btn"></div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'CardSlide.vue',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      price: '',
      timeoutId: null,
    };
  },
  computed: {
    id() {
      return this.card.id.replace('product-component-', '');
    },
  },
  mounted() {
    if (process.client) {
      this.timeoutId = setTimeout(() => {
        const div = document.querySelector(`#${this.card.id}`);
        if (div && div.hasChildNodes()) {
          this.updatePrice(div);
          this.adjustButton(div);
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
  methods: {
    updatePrice(div) {
      const price = div.childNodes[0].contentWindow.document.querySelector(
        '[data-element="product.price"]',
      );
      if (price) {
        this.price = price.innerText;
      }
    },
    adjustButton(div) {
      const shopify_btn =
        div.childNodes[0].contentWindow.document.querySelector(
          '.shopify-buy__layout-vertical',
        );
      if (shopify_btn) {
        shopify_btn.style.textAlign = 'right';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide {
  flex: 0 0 min(80vw, 380px);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  list-style: none;
  &:last-of-type {
    margin-right: 200px;
  }
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, max-content);
    row-gap: 1.5rem;
    position: relative;
    max-width: 350px;
  }
  &__img {
    grid-column: span 2;
  }
  &__btn {
    position: absolute;
    bottom: -8px;
    right: 0;
  }
}
</style>
