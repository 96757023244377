<template>
  <v-app dark class="app">
    <div v-if="profile" :id="customThemeId" data-product="true">
      <Navigation />
      <Header />
      <main class="product container">
        <RelatedProfiles />
        <v-divider class="mt-2" />
        <Highlighted />
        <CustomLinks />
        <About />
        <Button
          v-if="isHubSpot"
          class="button--secondary"
          data-button="crm"
          height="48"
          @click="showDialog = true"
        >
          <div class="button__content">
            <fa-icon icon="phone-alt" class="mr-2" />
            {{ $t('products.iWantToBeContacted') }}
            <fa-icon icon="chevron-right" class="icon ml-2" />
          </div>
        </Button>
        <ConnectCard v-if="isCompanyOwner" />
        <nuxt />
      </main>
      <Footer>
        <Button
          height="48"
          class="button--secondary footer__btn--rounded mr-2"
          @click="goToHomepage"
        >
          <fa-icon icon="home" class="mr-2" />
          {{ $t('products.homepage') }}
        </Button>
        <Button
          v-if="isCompanyOwner"
          height="48"
          class="button--primary footer__btn--rounded mr-2"
          @click="goToProfileEdit"
        >
          <fa-icon icon="user-edit" class="mr-2" />
          {{ $t('global.customize') }}
        </Button>
        <Button
          v-else-if="isHubSpot"
          height="48"
          class="button--primary footer__btn--rounded mr-2"
          @click="requestInfo"
        >
          <fa-icon icon="info-circle" class="mr-2" />
          {{ $t('products.requestInfo') }}
        </Button>
      </Footer>
    </div>
    <FlashMessage />
    <NewCardDialog />
    <Dialog
      icon="phone-alt"
      :title="dialogTitle"
      :value="showDialog"
      @closeDrawer="closeDrawer"
    >
      <component
        :is="dialogOption"
        :mail="mail"
        @changeStep="dialogOption = $event"
        @closeDrawer="closeDrawer"
      />
    </Dialog>
  </v-app>
</template>

<script>
import Cookie from 'js-cookie';
import { mapActions, mapGetters } from 'vuex';

import {
  CUSTOM_THEME,
  CUSTOM_URL,
  HUBSPOT_EVENT,
  PROFILE_STATUS,
  REQUEST_STEPS,
} from '~/constants';
import { fetchProfileCustomTheme } from '~/helpers';
import checkAffiliateLinks from '~/middleware/checkAffiliateLinks';
import tabsBroadcast from '~/mixins/tabsBroadcast';

export default {
  name: 'ProductLayout',
  components: {
    Highlighted: () => import('~/components/profile/Highlighted.vue'),
    Dialog: () => import('~/components/Dialog.vue'),
    ContactRequest: () => import('~/components/shared/ContactRequest.vue'),
    SuccessfulRequest: () =>
      import('~/components/shared/SuccessfulRequest.vue'),
    About: () => import('~/components/profile/About.vue'),
    Button: () => import('~/components/shared/Button.vue'),
    CustomLinks: () => import('~/components/profile/CustomLinks.vue'),
    NewCardDialog: () => import('~/components/NewCardDialog.vue'),
    FlashMessage: () => import('~/components/shared/FlashMessage.vue'),
    Navigation: () => import('~/components/profile/Navigation.vue'),
    Header: () => import('~/components/profile/Header.vue'),
    RelatedProfiles: () => import('~/components/profile/RelatedProfiles.vue'),
    Footer: () => import('~/components/profile/Footer.vue'),
    ConnectCard: () => import('~/components/profile/ConnectCard.vue'),
  },
  mixins: [tabsBroadcast],
  middleware: [checkAffiliateLinks],
  data() {
    return {
      showDialog: false,
      mail: false,
      dialogOption: REQUEST_STEPS.CONTACT,
    };
  },
  computed: {
    ...mapGetters('auth', ['userEmail']),
    ...mapGetters('profile', [
      'currentOrganizationProfile',
      'customThemeId',
      'isAdmin',
      'isCompanyOwner',
      'isHubSpot',
      'profile',
      'userProfilesOrganizations',
    ]),
    dialogTitle() {
      if (this.dialogOption === REQUEST_STEPS.CONTACT) {
        return this.$t('products.contactRequest');
      }
      return '';
    },
  },
  async created() {
    Cookie.remove(CUSTOM_URL);
    Cookie.remove(CUSTOM_THEME);
  },
  async mounted() {
    if (this.customThemeId) fetchProfileCustomTheme(this.customThemeId);
    await this.fetchUserProfiles();
    if (this.$apolloHelpers.getToken() && this.profile?.name) {
      this.sendHubspotEvent({
        eventName: HUBSPOT_EVENT.PRODUCT_PAGE_VISIT,
        organizationId: this.currentOrganizationProfile.id,
        payload: { productName: this.profile.name },
      });
    }
  },
  methods: {
    ...mapActions('profile', ['fetchUserProfiles', 'sendHubspotEvent']),
    async requestInfo() {
      const result = await this.sendHubspotEvent({
        eventName: HUBSPOT_EVENT.PRODUCT_REQUEST_DETAILS,
        organizationId: this.currentOrganizationProfile.id,
        payloadData: {
          productName: this.profile?.name,
        },
      });
      if (result) {
        this.dialogOption = REQUEST_STEPS.SUCCESS;
        this.mail = true;
        this.showDialog = true;
      }
    },
    closeDrawer() {
      this.showDialog = false;
      this.dialogOption = REQUEST_STEPS.CONTACT;
      this.mail = false;
    },
    goToHomepage() {
      if (this.isCompanyOwner) {
        const { profileStatus, nickName, id } = this.currentOrganizationProfile;
        const organization =
          profileStatus === PROFILE_STATUS.PUBLIC ? nickName : `company/${id}`;
        window.location.href = `${process.env.baseUrl}/app/${organization}/staff`;
      }
      window.location.href = `${process.env.baseUrl}/app/dashboard`;
    },
    goToProfileEdit() {
      const organization = this.$route.params.organization;
      const product = this.$route.params.id;
      window.location.href = `${process.env.baseUrl}/app/${organization}/product/edit/${product}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.product {
  display: grid;
  gap: 1rem;
  margin-bottom: 7.25rem;
  @media #{$under-xs} {
    margin-bottom: 11rem;
  }
  &__logo {
    justify-self: center;
  }
}
.app {
  min-height: 100vh;
  padding-top: 0 !important;
  background-color: var(--v-background-base);
  ::v-deep .container {
    padding: 0 1rem !important;
    max-width: 672px !important;
    @media #{$small} {
      max-width: 640px !important;
      padding: 0 !important;
    }
  }
}
.button__content {
  @media #{$under-xs} {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.icon {
  height: 12px;
}
</style>
