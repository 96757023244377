<template>
  <div v-if="!isEmpty(currentOrganizationProfile)" class="related">
    <CardLink class="card-link--settings my-4" :internal-link="organizationUrl">
      <Avatar
        :avatar-image="getStorageUrl(currentOrganizationProfile.photoName)"
        :profile-full-name="currentOrganizationProfile.name"
        :profile-type="PROFILE_TYPE.CLUB"
        class-variant="avatar--menu"
      />
      <span class="text-body-1">{{ currentOrganizationProfile.name }}</span>
    </CardLink>
  </div>
</template>

<script>
import isEmpty from 'lodash-es/isEmpty';
import { mapGetters } from 'vuex';

import Avatar from '~/components/atoms/Avatar.vue';
import CardLink from '~/components/atoms/CardLink.vue';
import { PROFILE_TYPE } from '~/constants';
import { getStorageUrl } from '~/helpers';

export default {
  name: 'RelatedProfiles',
  components: {
    Avatar,
    CardLink,
  },
  data() {
    return {
      PROFILE_TYPE,
    };
  },
  computed: {
    ...mapGetters('profile', ['profile', 'currentOrganizationProfile']),
    organizationUrl() {
      const { id, nickName, profileType } = this.currentOrganizationProfile;
      const prefillType =
        profileType === PROFILE_TYPE.COMPANY ? 'company' : 'club';
      const uniqueValue = nickName || id;
      return `/${prefillType}/${uniqueValue}`;
    },
  },
  methods: {
    isEmpty,
    getStorageUrl,
  },
};
</script>
