<template>
  <fragment>
    <div class="dialog">
      <CompactInput
        v-if="owner"
        v-model="fullName"
        :label="$t('members.name')"
        label-color="dark-grey"
      />
      <Dropdown
        v-if="owner"
        :id="membershipId"
        :items="membershipTypes"
        :label="$t('members.membershipType')"
        @chooseItem="chooseItem"
      />
      <div class="dialog__input light rounded-lg px-4 py-2">
        <div class="dialog__label font-weight-bold light-gray--text">
          {{ $t('members.memberSince') }}
        </div>
        <div class="mt-2">{{ memberSince }}</div>
      </div>
      <div
        :class="statusClass"
        class="dialog__input dialog__input--with-button light rounded-lg px-4 py-2"
      >
        <div>
          <div class="dialog__label font-weight-bold light-gray--text">
            {{ $t('members.membershipStatus') }}
          </div>
          <div class="text-capitalize mt-2">
            {{ membershipStatus }}
          </div>
        </div>
        <div>
          <v-btn
            text
            class="dialog__btn primary--text text-capitalize"
            @click="$emit('showSubscription')"
          >
            {{ $t('members.payments') }}
          </v-btn>
        </div>
      </div>
      <Button
        v-if="membershipStatus === MEMBERSHIP_STATUS.ACTIVE"
        width="100%"
        height="48"
        class="button--delete"
        :loading="loading"
        :disabled="disableCancelButton"
        @click="endSubscription"
      >
        {{ $t('members.cancelMySubscription') }}
      </Button>
    </div>
    <div class="dialog__two-columns">
      <Button
        width="100%"
        height="48"
        class="button--rounded button--secondary"
        @click="$emit('closeDrawer')"
      >
        {{ $t('members.close') }}
      </Button>
      <Button
        width="100%"
        height="48"
        class="button--rounded button--primary"
        @click="update(undefined)"
      >
        {{ $t('global.save') }}
      </Button>
    </div>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import { mapActions, mapGetters } from 'vuex';

import Button from '~/components/shared/Button';
import CompactInput from '~/components/shared/CompactInput.vue';
import Dropdown from '~/components/shared/Dropdown';
import { MEMBERSHIP_STATUS } from '~/constants';

export default {
  name: 'MembershipStatus.vue',
  components: {
    Button,
    CompactInput,
    Dropdown,
    Fragment,
  },
  props: {
    member: [Object],
    clubName: [String],
    owner: [Boolean],
  },
  data() {
    return {
      MEMBERSHIP_STATUS,
      newMemberShipTypeId: this.member?.membershipId,
      fullName: '',
    };
  },
  computed: {
    ...mapGetters('profile', ['profile']),
    ...mapGetters('organization', [
      'membershipTypes',
      'getMembershipTypeByUserId',
    ]),
    ...mapGetters('payments', ['loading', 'payments', 'availablePlans']),
    disableCancelButton() {
      return !(
        this.member?.id ||
        this.member?.account?.personIdentifier ||
        this.availablePlans.length > 0
      );
    },
    memberSince() {
      if (!this.member?.createdAt) return '';
      return new Date(this.member.createdAt).toLocaleDateString(
        this.$i18n.locale,
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        },
      );
    },
    statusClass() {
      if (!this.member?.membershipStatus) return '';
      return `dialog__input--${this.member.membershipStatus.toLowerCase()}`;
    },
    membershipId() {
      return this.member?.membershipId ?? '';
    },
    membershipStatus() {
      return this.member?.membershipStatus ?? '';
    },
    membershipName() {
      return this.member?.membershipName ?? '';
    },
    membershipIcon() {
      return this.member?.membershipIcon ?? '';
    },
  },
  watch: {
    member: {
      immediate: true,
      handler(newValue) {
        if (!newValue) return;
        if (newValue?.name) this.fullName = newValue.name;
      },
    },
  },
  methods: {
    ...mapActions('profile', [
      'updateProfileName',
      'fetchProfileOrganizations',
    ]),
    ...mapActions('payments', ['cancelSubscription']),
    ...mapActions('organization', ['updateMembership']),
    chooseItem(id) {
      this.newMemberShipTypeId = id;
    },
    endSubscription() {
      const planName = `${this.clubName} - ${this.member.membershipName}`;
      const productId = this.availablePlans.find(
        (item) => item?.productName === planName,
      )?.product;
      this.cancelSubscription({
        id: this.member.id,
        userId: this.member.account.personIdentifier,
        productId,
      });
    },
    async update() {
      if (this.member?.name !== this.fullName) {
        await this.updateProfileName({
          name: this.fullName,
          profileId: this.member.memberId,
        });
      }
      if (this.member.membershipId !== this.newMemberShipTypeId) {
        const membershipTypeId = this.newMemberShipTypeId;
        await this.updateMembership({
          id: this.member.id,
          membershipTypeId,
          membershipExpireAt: undefined,
        });
      }
      this.$emit('closeDrawer');
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  display: grid;
  grid-gap: 1.5rem;
  overflow-y: scroll;
  max-height: 70vh;
  &__input {
    height: 66px;
    font-size: 1rem;
    line-height: 1.5;
    &--with-button {
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
    }
    &--active {
      background-color: var(--v-confirmation-bg-base) !important;
      border: 1px solid var(--v-green-confirmation-base) !important;
    }
    &--expired {
      background-color: var(--v-error-red-light-base) !important;
      border: 1px solid var(--v-error-red-base) !important;
    }
    &--invited {
      background-color: var(--v-light-base) !important;
      border: 1px solid var(--v-dark-gray-base) !important;
    }
  }
  &__two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
  }
  &__btn {
    letter-spacing: normal;
  }
  &__label {
    font-size: 0.8125rem;
    line-height: 1.2;
  }
  &__subtitle {
    font-size: 0.8125rem;
    line-height: 1.2;
  }
}
</style>
