<template>
  <Fragment>
    <div class="slide">
      <img
        class="slide__image mb-3"
        v-bind:src="slide.image"
        v-bind:srcset="slide.srcset"
      />
      <h4 class="slide__title font-weight-black mb-3">{{ slide.title }}</h4>
      <div
        class="text-body-2 dark-grey--text mb-6"
        v-for="explanation in slide.explanation"
        :key="explanation"
      >
        {{ explanation }}
      </div>
    </div>
    <div class="card rounded-lg white px-6 py-7">
      <div>
        <h4 class="slide__title font-weight-black" :class="slide.titleColor">
          {{ slide.title }}
        </h4>
        <div class="card__text basic-black--text">
          {{ slide.price }}
          <span class="card__text--gray">
            &nbsp;/{{ $t('rheinfire.year') }}
          </span>
        </div>
        <v-divider class="my-6" />
      </div>
      <ul class="text-align-start mb-2">
        <li
          class="dark-grey--text"
          v-for="priceDescription in slide.priceDescription"
          :key="priceDescription"
        >
          {{ priceDescription }}
        </li>
      </ul>
      <div id="membership">
        <Button
          class="slide__button mt-md-6 px-0"
          height="52"
          max-width="280"
          width="100%"
          outlined
          dark
          color="cream"
          @click="
            $router.push(
              `/rheinfire.club/invitation?membership=${slide.membershipName}`,
            )
          "
        >
          <span class="font-weight-bold">
            {{ $t('rheinfire.chooseThisMembership') }}
          </span>
        </Button>
        <div :id="slide.id" class="slide__button mt-1"></div>
      </div>
    </div>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

export default {
  name: 'Slide',
  components: {
    Fragment,
    Button: () => import('@/components/shared/Button.vue'),
  },
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.slide {
  &__image {
    width: 100%;
    aspect-ratio: 305/289;
  }
  &__title {
    font-size: 1.5rem;
  }
  &__button {
    justify-self: center;
  }
}
.card {
  box-shadow: $card-box-shadow;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  & > ul {
    color: var(--v-price-text-gray);
  }
  &__text {
    font-size: 2.25rem;
    line-height: 1;
    @media #{$lg-and-up} {
      font-size: 3.75rem;
    }
    &--gray {
      color: var(--v-price-text-lightgray);
      font-size: 1.5rem;
    }
  }
}
.swiper-slide {
  height: auto;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
}
.swiper__button {
  position: absolute;
  z-index: 10;
  opacity: 1;
  &-next {
    right: 5%;
  }
}
</style>
