<template>
  <div v-if="hasProducts" class="dropdown custom-link rounded-lg px-4">
    <div class="dropdown__button" @click="isOpen = !isOpen">
      <v-img class="dropdown__image" width="24" height="24" :src="photoUrl" />
      <span class="dropdown__text pl-2">{{ $t('products.products') }}</span>
      <span
        class="dropdown__text--toggle text-h4 align-self-center primary--text pr-3"
      >
        {{ isOpen ? $t('products.hide') : $t('products.show') }}
      </span>
      <fa-icon
        :icon="isOpen ? 'minus' : 'plus'"
        class="dropdown__icon align-self-center primary--text"
      />
    </div>
    <div v-if="isOpen">
      <div
        v-for="product in products"
        :key="product.id"
        class="light rounded-lg"
      >
        <nuxt-link
          class="dropdown-item"
          :to="`/${$route.params.organization}/product/${
            product.nickName || product.memberId
          }`"
        >
          <Avatar
            :avatar-image="getStorageUrl(product.photoName)"
            :profile-full-name="product.name"
            :profile-type="PROFILE_TYPE.PERSONAL"
            class-variant="card__image avatar--member ma-2"
          />
          <div class="my-4">
            <div class="dropdown-item--name font-weight-bold basic-black--text">
              {{ product.name }}
            </div>
            <div
              class="dropdown-item--title font-weight-black text-uppercase dark-grey--text mt-2"
            >
              {{ product.position }}
            </div>
          </div>
          <div class="dropdown-item--icon">
            <fa-icon icon="chevron-right" class="primary--text mr-4" />
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Avatar from '~/components/atoms/Avatar.vue';
import { PROFILE_TYPE } from '~/constants';
import { getStorageUrl } from '~/helpers';

export default {
  name: 'ProductDropDown',
  components: {
    Avatar,
  },
  data() {
    return {
      isOpen: false,
      PROFILE_TYPE,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'companyId',
      'userProfilesOrganizations',
      'isCompany',
      'photoUrl',
      'profile',
    ]),
    ...mapGetters('organization', ['company', 'products', 'hasProducts']),
  },
  watch: {
    async companyId(newVal) {
      if (newVal && !this.isCompany) {
        const org = await this.getOrganization({ profileId: this.companyId });
        this.findAllProducts({ organizationUniqueValue: org?.profile?.id });
      }
    },
    userProfilesOrganizations: {
      immediate: true,
      handler(newVal) {
        if (newVal?.length && this.profile?.profileType === PROFILE_TYPE.CLUB) {
          const organizationId = newVal.filter((item) => {
            return item?.organization?.profile?.id === this.profile?.id;
          })[0]?.organization?.id;
          organizationId && this.getCompanyProducts(organizationId);
        }
      },
    },
    profile: {
      immediate: true,
      handler(newVal) {
        if (newVal.profileType === PROFILE_TYPE.COMPANY) {
          this.findAllProducts({ organizationUniqueValue: newVal.id });
        }
      },
    },
  },
  methods: {
    ...mapActions('organization', ['findAllProducts', 'getOrganization']),
    ...mapActions('profile', ['getCompanyProducts']),
    getStorageUrl,
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  &__button {
    display: grid;
    grid-template-columns: min-content 1fr max-content min-content;
    align-content: center;
    height: 63px;
    cursor: pointer;
  }
  &__image {
    border-radius: 50%;
  }
  &__icon {
    max-height: 12px;
    max-width: 12px;
  }
}
.dropdown-item {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  cursor: pointer;
  margin-bottom: 0.75rem;
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
  &--icon {
    align-self: center;
    justify-self: center;
  }
  &--name {
    line-height: 1.2;
  }
  &--title {
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0.1rem;
  }
}
</style>
