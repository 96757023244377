<template>
  <div class="profile__connect">
    <Button
      width="100%"
      height="70"
      class="connect-card__button"
      :class="profileCard ? 'button--secondary' : 'button--primary'"
      @click="handleDialogOpen"
    >
      <p class="d-flex align-center">
        <fa-icon icon="address-card" class="mr-4" />
        {{
          profileCard ? $t('profile.cardConnected') : $t('profile.connectACard')
        }}
      </p>
      <fa-icon
        :class="{ button__icon: profileCard }"
        :icon="profileCard ? 'times' : 'plus-circle'"
        @click.stop="handleDialogOpen"
      />
    </Button>
    <Dialog
      :value="connectCardDialog"
      icon="link"
      :title="$t('profile.connectACard')"
      @closeDrawer="closeConnectCardDialog"
      class="text-gray--text"
    >
      <client-only>
        <div v-if="step === 1">
          <img
            src="/connect-card.gif"
            alt=""
            class="dialog__image d-block mx-auto"
          />
          <p class="text-h2 primary-dark--text my-6">
            {{ $t('profile.connect.nfcConnection') }}:
          </p>
          <p class="dialog__description text-body-1 my-2">
            {{ $t('global.mobileOnly') }}
          </p>
          <p class="dialog__description text-body-1">
            {{ $t('profile.connect.holdCard') }}
          </p>
        </div>
        <div
          v-if="step === 2"
          class="dialog__response d-flex flex-column align-center justify-center"
        >
          <fa-icon icon="check-circle" class="dialog__icon primary--text" />
          <p class="text-h1 primary-dark--text mt-7">
            {{ $t('profile.cardConnected') }}!
          </p>
        </div>
        <div
          v-if="step === 3"
          class="dialog__response d-flex flex-column align-center justify-center"
        >
          <div
            class="d-flex flex-column align-center justify-center flex-grow-1"
          >
            <fa-icon
              icon="question-circle"
              class="dialog__icon error-red--text"
            />
            <p class="text-h1 primary-dark--text mt-7">
              {{ error.title }}
            </p>
          </div>
          <div class="font-weight-medium">
            <span>{{ error.info }}</span>
            <p>
              {{ $t('profile.stillHavingIssues') }}
              <a
                class="dialog__link basic-black--text"
                href="mailto:support@t2l.ink"
                >{{ $t('global.contactUs') }}</a
              >.
            </p>
          </div>
        </div>
      </client-only>
      <template v-slot:buttons>
        <Button
          v-if="step !== 2"
          height="48"
          :class="{ 'button--expanded': step === 1 }"
          class="button--rounded button--secondary"
          @click="closeConnectCardDialog"
        >
          {{ $t('global.close') }}
        </Button>
        <Button
          v-if="step === 2"
          height="48"
          class="button--rounded button--secondary button--expanded"
          @click="closeConnectCardDialog"
        >
          {{ $t('global.ok') }}
        </Button>
        <Button
          v-if="step === 3"
          height="48"
          class="button--rounded button--primary"
          @click="resetConnection"
        >
          {{ $t('global.tryAgain') }}
        </Button>
      </template>
    </Dialog>
    <Dialog
      :value="disconnectCardDialog"
      @closeDrawer="closeDisconnectCardDialog"
      icon="unlink"
      :title="$t('profile.disconnectCard')"
    >
      <span class="text-gray--text">
        {{ $t('profile.disconnect.question') }}
      </span>
      <span class="text-gray--text">
        {{ $t('profile.disconnect.reconnectInformation') }}
      </span>
      <v-divider class="mx-2" />
      <template v-slot:buttons>
        <Button
          width="100%"
          height="48"
          class="button--rounded button--delete"
          @click="handleDisconnectCard"
        >
          {{ $t('global.disconnect') }}
        </Button>
        <Button
          width="100%"
          height="48"
          class="button--rounded button--primary"
          @click="closeDisconnectCardDialog"
        >
          {{ $t('global.keepCard') }}
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import { mapActions, mapGetters } from 'vuex';

import Dialog from '@/components/Dialog.vue';
import Button from '@/components/shared/Button.vue';
import { COOKIE_TYPE } from '~/constants';

export default {
  name: 'ConnectCard',
  components: {
    Button,
    Dialog,
  },
  data() {
    return {
      connectCardDialog: false,
      disconnectCardDialog: false,
      step: 1,
      listenerId: null,
      counterId: null,
      error: {
        title: `${this.$t('error.somethingWentWrong')}...`,
        info: this.$t('profile.nfcActivated'),
      },
      identifier_int: '',
    };
  },
  computed: {
    ...mapGetters('profile', [
      'profile',
      'profileCard',
      'currentProfileOrganization',
    ]),
  },
  watch: {
    identifier_int(newValue) {
      if (newValue) {
        this.clearListener(false);
        this.step = 2;
      }
    },
  },
  mounted() {
    Cookie.remove(COOKIE_TYPE.CARD_CONNECT_SCANNER);
    Cookie.remove(COOKIE_TYPE.CARD_CONNECT_RESPONSE);
  },
  destroyed() {
    Cookie.remove(COOKIE_TYPE.CARD_CONNECT_SCANNER);
    Cookie.remove(COOKIE_TYPE.CARD_CONNECT_RESPONSE);
    this.clearListener(false);
  },
  methods: {
    ...mapActions('profile', [
      'fetchCard',
      'fetchProfile',
      'addCardToProfile',
      'disconnectProfileCard',
    ]),
    handleDialogOpen() {
      if (this.profileCard) {
        this.disconnectCardDialog = true;
      } else {
        this.openConnectCardDialog();
      }
    },
    openConnectCardDialog() {
      Cookie.remove(COOKIE_TYPE.CARD_CONNECT_RESPONSE);
      this.startListener();
      this.step = 1;
      this.connectCardDialog = true;
    },
    closeConnectCardDialog() {
      Cookie.remove(COOKIE_TYPE.CARD_CONNECT_SCANNER);
      this.clearListener(false);
      if (this.step !== 2) {
        this.identifier_int = '';
      }
      this.fetchProfile({
        organizationUniqueValue: this.$route.params.organization,
        profileUniqueValue: this.profile?.id,
      });
      this.connectCardDialog = false;
    },
    resetConnection() {
      this.step = 1;
      Cookie.set(COOKIE_TYPE.CARD_CONNECT_SCANNER, true);
      this.startListener();
    },
    startListener() {
      Cookie.set(COOKIE_TYPE.CARD_CONNECT_SCANNER, true);
      this.listenerId = setInterval(async () => {
        const responseId = Cookie.get(COOKIE_TYPE.CARD_CONNECT_RESPONSE);
        if (responseId) {
          const card = await this.fetchCard(responseId);
          const organization = this.currentProfileOrganization.organization;
          if (
            card.organization?.id &&
            card.organization?.id !== organization?.id
          ) {
            this.error = {
              title: this.$t('error.cardDoesNotBelong', {
                companyName: organization?.profile.name,
              }),
              info: this.$t('profile.checkClubCardRelation'),
            };
            this.clearListener(true);
          } else {
            const isCardConnected = this.addCardToProfile({
              profileId: this.profile?.id,
              identifier_int: responseId,
            });
            if (isCardConnected) {
              this.step = 2;
            }
            this.clearListener(!isCardConnected);
          }
        }
      }, 500);
    },
    clearListener(failed) {
      if (this.listenerId) {
        Cookie.remove(COOKIE_TYPE.CARD_CONNECT_SCANNER);
        clearInterval(this.listenerId);
        this.listenerId = null;
      }
      if (failed) {
        this.step = 3;
      }
    },
    handleDisconnectCard() {
      this.disconnectProfileCard(this.profileCard?.id);
      this.closeDisconnectCardDialog();
    },
    closeDisconnectCardDialog() {
      this.disconnectCardDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.connect-card {
  &__button {
    border-radius: 8px !important;
    &::v-deep {
      .v-btn__content {
        justify-content: space-between !important;
      }
    }
  }
}

.dialog {
  &__response {
    height: 250px;
  }
  &__image {
    width: 100%;
    max-width: 300px;
  }
  &__icon {
    width: 40px;
    height: 40px;
  }
  &__description {
    white-space: pre-line;
  }
  &__link {
    font-weight: 600;
  }
}

.button {
  &__icon {
    width: 22px;
    height: 22px;
  }
}
</style>
