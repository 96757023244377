<template>
  <div />
</template>

<script>
import { mapGetters } from 'vuex';

import { PROFILE_STATUS } from '~/constants';
import profileMixin from '~/mixins/profileMixin';

export default {
  name: 'Profile',
  mixins: [profileMixin],
  layout: 'profile',
  computed: {
    ...mapGetters('profile', ['profile']),
  },
  mounted() {
    if (this.profile?.profileStatus === PROFILE_STATUS.PUBLIC) {
      this.$router.push(`/${this.profile.nickName}`);
    }
  },
};
</script>
