import { render, staticRenderFns } from "./BusinessPricing.vue?vue&type=template&id=288a8d4e&scoped=true&"
import script from "./BusinessPricing.vue?vue&type=script&lang=js&"
export * from "./BusinessPricing.vue?vue&type=script&lang=js&"
import style0 from "./BusinessPricing.vue?vue&type=style&index=0&id=288a8d4e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "288a8d4e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VDivider})
