<template>
  <div>
    <Eula class="eula--no-scrollbar" />
    <Details />
  </div>
</template>

<script>
import Eula from '~/components/Eula';

export default {
  name: 'eula',
  components: {
    Eula,
    Details: () => import('~/components/Details'),
  },
};
</script>

<style lang="scss" scoped>
.eula {
  margin-top: 5rem;
  &__container {
    max-width: 790px;
  }
  &__content {
    display: grid;
    gap: 1.125rem;
    & > * {
      font-size: 1.125rem;
      line-height: 1.4;
    }
    & > p:not(.label) {
      white-space: pre-line;
    }
  }
  &__subtitle {
    color: var(--v-primary-dark-base) !important;
  }
  &__link {
    text-decoration: none;
    color: var(--v-text-gray-base);
    word-break: break-all;
  }
}
p {
  margin-bottom: 0 !important;
}
.link {
  word-break: break-all;
}
</style>
