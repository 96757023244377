<template>
  <section class="business">
    <div class="container">
      <div class="business__card neutral-white rounded-lg pt-10 pb-md-10 px-3">
        <div
          class="business__container business__container--grid container--internal"
        >
          <h2 class="business__title text-lp-h2 basic-black--text mb-6">
            {{ $t('businessCard.t2lBusiness') }} 🚀
          </h2>
          <p class="business__info text-lp-body-2 dark-grey--text">
            {{ $t('businessCard.t2lBusinessInfo') }}
          </p>
          <NuxtLinkButton
            to="/business"
            class="business__btn button--lp my-5 mt-sm-8"
          >
            {{ $t('businessCard.discoverWhatWeOffer') }}
          </NuxtLinkButton>
        </div>
      </div>
      <div class="business__container container--internal px-3">
        <h2 class="business__title text-lp-h2 basic-black--text mb-3">
          {{ $t('businessCard.businessCard') }}
        </h2>
        <p class="business__info text-lp-body-2 dark-grey--text">
          {{ $t('businessCard.shareContactsAndLinks') }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import NuxtLinkButton from '~/components/shared/NuxtLinkButton.vue';

export default {
  name: 'BusinessCard',
  components: {
    NuxtLinkButton,
  },
};
</script>

<style scoped lang="scss">
.business {
  &__container {
    max-width: 28.75rem;
    @media #{$md-and-up} {
      max-width: 49.37rem;
    }
    &--grid {
      @media #{$md-and-up} {
        display: grid;
        grid-template-columns: 1fr 0.7fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 'title button' 'info button';
      }
    }
  }
  &__card {
    margin-bottom: clamp(4rem, 8vw, 5rem);
  }
  &__title {
    grid-area: title;
  }
  &__info {
    grid-area: info;
  }
  &__btn {
    grid-area: button;
    width: 100%;
    @media #{$sm-and-up} {
      width: unset;
    }
    @media #{$md-and-up} {
      justify-self: center;
      align-self: center;
    }
  }
}
</style>
