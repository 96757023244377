<template>
  <div class="bg" v-if="loading">
    <div class="d-flex justify-center align-center">
      <img src="/loading.gif" alt="" width="50" height="50" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner.vue',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  background: var(--v-loading-bg-base);
  opacity: 0.6;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  & div {
    height: 100vh;
  }
}
</style>
