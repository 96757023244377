<template>
  <div
    v-if="sortedHighlightedLinks.length"
    class="highlighted d-flex justify-space-between"
    :data-company="company"
  >
    <a
      v-for="tile in sortedHighlightedLinks"
      :key="tile.id"
      :href="createExternalLink(tile.link)"
      target="_blank"
      class="tile d-flex align-center justify-center"
    >
      <fa-icon
        v-if="tile.iconName && !tile.photoName"
        :icon="getIconName(tile.iconName)"
        class="tile__icon mr-1"
        :style="tile.iconColor"
      />
      <v-img
        v-if="tile.photoName"
        :src="getStorageUrl(tile.photoName)"
        class="tile__icon text-dark--text mr-1"
      />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { createExternalLink, getIconName, getStorageUrl } from '~/helpers';

export default {
  name: 'Highlighted',
  props: {
    company: [Boolean],
  },
  computed: {
    ...mapGetters('profile', ['highlighted']),
    ...mapGetters('organization', ['organizationHighlighted']),
    links() {
      if (this.company) return this.organizationHighlighted;
      return this.highlighted;
    },
    sortedHighlightedLinks() {
      return this.links
        .map((link) => {
          return {
            ...link,
            iconColor: {
              color: link.iconColor,
            },
          };
        })
        .sort((first, second) => {
          return first.order - second.order;
        });
    },
  },
  methods: {
    getStorageUrl,
    getIconName,
    createExternalLink,
  },
};
</script>

<style lang="scss" scoped>
.highlighted {
  gap: 0.5rem;
  @supports not ((scroll-behavior: smooth) and (gap: 20px)) {
    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
.tile {
  width: 100%;
  height: 52px;
  border: 2px solid var(--v-light-base);
  border-radius: 12px;
  &:hover {
    border-color: var(--v-hover-gray-base);
  }
  &:active {
    border-color: var(--v-primary-base);
  }
  &__icon {
    width: 23px;
    max-width: 23px !important;
    height: 23px;
  }
}
</style>
