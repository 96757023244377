<template>
  <div />
</template>

<script>
import profileMixin from '~/mixins/profileMixin';

export default {
  name: 'ClubProfile',
  mixins: [profileMixin],
  layout: 'profile',
};
</script>
