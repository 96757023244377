<template>
  <section class="contact-form mt-10">
    <div class="contact-form__container container">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <v-form
          class="contact-form__form"
          @submit.prevent="handleSubmit(handleConfirm)"
        >
          <Input
            v-model="fullName"
            placeholder="Max Mustermann"
            :label="$t('contact.name')"
            rules="required"
          />
          <Input
            v-model="email"
            placeholder="max.mustermann@email.de"
            :label="$t('contact.emailAddress')"
            rules="required|email"
          />
          <Dropdown
            v-if="selectedTopic"
            :id="selectedTopic.id"
            :items="topics"
            :label="$t('contact.topic')"
            @selectedItem="selectTopic"
          />
          <Textarea
            v-model="message"
            :placeholder="$t('contact.messagePlaceholder')"
            :label="$t('contact.yourMessage')"
            rows="7"
            rules="required"
          />
          <Checkbox
            v-model="privacyPolicy"
            rules="required"
            error-message-class="orange--text"
          >
            <span class="black--text">
              {{ $t('contact.privacy.labelOne') }}
              <nuxt-link to="/privacy" class="black--text">
                {{ $t('contact.privacy.link') }}
              </nuxt-link>
              {{ $t('contact.privacy.labelTwo') }}
            </span>
          </Checkbox>
          <Checkbox
            v-model="termsOfUse"
            rules="required"
            error-message-class="orange--text"
          >
            <span class="black--text">
              {{ $t('contact.terms.labelOne') }}
              <span
                class="black--text text-decoration-underline"
                @click.stop.prevent="showDialog = true"
              >
                {{ $t('contact.terms.link') }}
              </span>
              {{ $t('contact.terms.labelTwo') }}
            </span>
          </Checkbox>
          <Button
            class="contact-form__button btn--primary"
            height="44"
            :loading="loading"
            type="submit"
          >
            {{ $t('contact.send') }}
          </Button>
        </v-form>
      </ValidationObserver>
      <div v-if="showSuccessMessage" class="mt-6">
        <fa-icon icon="check" class="primary--text mr-4" />
        <span class="text-h3 black--text">
          {{ $t('contact.messageSuccessfullySent') }}
        </span>
      </div>
    </div>
    <Dialog
      icon="shield-alt"
      :title="$t('login.termsOfUse')"
      :value="showDialog"
      @closeDrawer="showDialog = false"
    >
      <component :is="TERMS_OF_USE" />
      <v-divider />
      <Button
        width="100%"
        height="48"
        color="light-main"
        class="button--rounded button--light mt-auto"
        @click="showDialog = false"
      >
        {{ $t('global.ok') }}
      </Button>
    </Dialog>
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';

import Button from '~/components/atoms/Button';
import Dialog from '~/components/Dialog';
import Checkbox from '~/components/form/Checkbox';
import Dropdown from '~/components/form/Dropdown';
import Input from '~/components/form/Input';
import Textarea from '~/components/form/Textarea';
import { TERMS_OF_USE } from '~/constants';
import contactTopics from '~/mixins/contactTopics';
import watchValidation from '~/mixins/watchValidation';

export default {
  name: 'ContactForm',
  components: {
    ValidationObserver,
    Input,
    Textarea,
    Checkbox,
    Dropdown,
    Dialog,
    Button,
    TermsOfUse: () => import('~/components/TermsOfUse'),
  },
  mixins: [contactTopics, watchValidation],
  data() {
    return {
      fullName: '',
      email: '',
      message: '',
      privacyPolicy: null,
      termsOfUse: null,
      showDialog: false,
      showSuccessMessage: false,
      selectedTopic: this.getSelectedTopic,
      TERMS_OF_USE,
    };
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapGetters('contact', ['getSelectedTopic']),
  },
  mounted() {
    this.selectedTopic = this.getSelectedTopic;
  },
  methods: {
    ...mapActions(['sendContactMail']),
    resetForm() {
      this.$refs.form.reset();
      this.fullName = '';
      this.email = '';
      this.message = '';
      this.privacyPolicy = null;
      this.termsOfUse = null;
      if (this.topics.length) {
        this.selectTopic(this.topics[0]);
      }
    },
    async handleConfirm() {
      const message = `${this.selectedTopic.text}\n ${this.message}`;
      this.showSuccessMessage = false;
      this.showSuccessMessage = await this.sendContactMail({
        name: this.fullName,
        email: this.email,
        message,
      });
      if (this.showSuccessMessage) this.resetForm();
    },
    async selectTopic(topic) {
      this.selectedTopic = topic;
      this.setSelectedTopic(topic);
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-form {
  &__container {
    max-width: 49.375rem;
    @media #{$md-and-up} {
      padding: 0 !important;
    }
  }
  &__form {
    display: grid;
    gap: 1.5rem;
  }
  &__button {
    justify-self: start;
  }
}
</style>
