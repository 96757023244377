<template>
  <section class="ability white--text">
    <v-container class="ability__container">
      <h3 class="ability__title font-weight-black text-center">
        {{ $t('rheinfire.boosterCardCanDoThat') }}
      </h3>
      <div
        class="ability__card"
        v-for="(card, index) in abilityCards"
        :key="card.title"
      >
        <img class="ability__image mb-16 mb-sm-0" :src="card.image" alt="" />
        <div class="ability__content" :class="{ 'ml-sm-auto': index === 1 }">
          <h4 class="ability__subtitle font-weight-black mb-4">
            {{ card.title }}
          </h4>
          <p
            class="ability__explanation font-weight-medium rheinfire-gray--text"
            v-for="text in card.explanation"
            :key="text"
          >
            {{ text }}
          </p>
        </div>
      </div>
    </v-container>
  </section>
</template>
<script>
export default {
  computed: {
    abilityCards() {
      return [
        {
          title: this.$t('rheinfire.exclusiveBoosterCard'),
          explanation: [this.$t('rheinfire.asAMemberOfHeatClub')],
          image: '/images/rheinfire_exclusive.png',
        },
        {
          title: this.$t('rheinfire.shareYourContact'),
          explanation: [
            this.$t('rheinfire.thanksToNFCTech'),
            this.$t('rheinfire.superSimple'),
          ],
          image: '/images/rheinfire_nfc.png',
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.ability {
  padding-bottom: 5rem;
  background: var(--v-black-base);
  @media #{$sm-and-up} {
    background: initial;
  }
  &__title {
    font-size: 2rem;
    line-height: 1.4 !important;
  }
  &__content {
    grid-area: content;
    @media #{$sm-and-up} {
      max-width: 19rem;
    }
  }
  &__container {
    display: grid;
    gap: 5rem;
    padding-top: 5rem !important;
    @media #{$sm-and-up} {
      background: var(--v-black-base);
    }
    @media #{$md-and-up} {
      border-radius: 0.7rem;
    }
    @media #{$lg-and-up} {
      padding: 5rem 8.8rem !important;
    }
  }
  &__card {
    display: grid;
    gap: 0;
    align-items: center;
    grid-template-areas: 'image' 'content';
    @media #{$sm-and-up} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: 'image content';
    }
    &:first-of-type {
      @media #{$sm-and-up} {
        grid-template-areas: 'content image';
      }
    }
  }
  &__subtitle {
    font-size: 1.125rem;
    line-height: 1.4;
  }
  &__explanation {
    line-height: 1.4;
    @media #{$md-and-up} {
      font-size: 1.125rem;
    }
  }
  &__image {
    width: 100vw;
    margin: 0 -1.5rem;
    grid-area: image;
    @media #{$sm-and-up} {
      width: 100%;
    }
  }
}
</style>
