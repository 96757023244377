<template>
  <ValidationProvider
    v-slot="{ errors }"
    class="form-control d-block"
    :rules="rules"
    :name="name"
    mode="eager"
  >
    <div
      class="input-compact light rounded-lg"
      :class="`${compactClass} ${errors.length && 'input-compact--error'}`"
    >
      <div class="input-compact__wrapper d-flex align-center px-4 py-2">
        <fa-icon
          v-if="prepend"
          :icon="prepend"
          class="input-compact__prepend mr-3"
          :style="{
            color: prependColor,
          }"
        />
        <v-img
          v-if="prependImage"
          :src="prependImage"
          width="18"
          height="18"
          class="input-compact__prepend flex-grow-0 mr-3"
        />
        <div class="flex-grow-1">
          <label
            v-if="label"
            :for="label + inputId"
            :class="`${labelColor}--text`"
            class="input-compact__label d-block font-weight-bold"
          >
            {{ label }}{{ isRequired }}
          </label>
          <p class="d-flex align-center">
            <span class="input-compact__prefill text-gray--text">
              {{ prefillText }}
            </span>
            <v-text-field
              :id="label + inputId"
              v-bind="$attrs"
              class="form-input font-weight-medium"
              :class="{ 'form-error': errors.length }"
              dense
              :type="fieldType"
              height="25"
              :value="value"
              v-on="$listeners"
              @input="$emit('input', $event)"
            >
              <template v-slot:append>
                <fa-icon
                  v-if="!append"
                  v-show="errors.length"
                  class="input-compact__cross"
                  :class="errors.length ? 'error-red--text' : 'primary--text'"
                  icon="times"
                />
                <fa-icon
                  v-if="type === 'password' && !append"
                  v-show="!errors.length"
                  class="input-compact__eye primary--text"
                  :icon="visible ? 'eye' : 'eye-slash'"
                  @click.stop="visible = !visible"
                />
              </template>
              <template v-slot:append-outer>
                <v-btn
                  v-if="append"
                  :class="appendClass"
                  color="primary"
                  icon
                  @click.stop="$emit('appendClick')"
                >
                  <fa-icon :icon="append" />
                </v-btn>
              </template>
            </v-text-field>
          </p>
        </div>
      </div>
    </div>
    <div v-show="errors.length" class="error-red--text mt-2 pl-5">
      {{ errors[0] }}
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    compactClass: {
      type: String,
      default: '',
    },
    prependImage: {
      type: String,
      default: '',
    },
    prepend: {
      type: [String, Array],
      default: '',
    },
    prependColor: {
      type: String,
      default: '#747690',
    },
    labelColor: {
      type: String,
      default: 'light-gray',
    },
    append: {
      type: [String, Array],
      default: '',
    },
    appendClass: {
      type: String,
      default: 'input-compact__append',
    },
    inputId: {
      type: String,
      default: '',
    },
    prefillText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    fieldType() {
      return this.visible ? 'text' : this.type;
    },
    isRequired() {
      if (typeof this.rules === 'string' && this.rules.includes('required'))
        return '*';
      if (typeof this.rules === 'object' && this.rules.required) return '*';
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.input-compact {
  border: 1px solid var(--v-input-border-gray-base) !important;
  padding: 1px;
  &:focus-within {
    border-width: 2px;
    .input-compact {
      &__label,
      &__prepend {
        color: var(--v-text-dark-base) !important;
        border-radius: 50% !important;
      }
      &__wrapper {
        border-color: var(--v-primary-base);
        background-color: var(--v-white-base);
      }
      &__cross {
        display: none;
      }
      &__eye {
        display: block !important;
      }
    }
  }
  &--error {
    border-color: var(--v-error-red-light-base) !important;
    background-color: var(--v-error-red-light-base) !important;
    .input-compact__wrapper {
      border-color: var(--v-error-red-base);
    }
  }
  &--green {
    border-color: green !important;
  }
  &__wrapper {
    position: relative;
    border: 2px solid var(--v-light-base);
    border-radius: inherit;
    background-color: var(--v-light-base);
  }
  &__label {
    font-size: 0.8125rem;
    line-height: 1.2;
    margin-bottom: 0.375rem;
    &:first-letter {
      text-transform: uppercase !important;
    }
  }
  &__prefill {
    margin-bottom: -3px;
  }
  &__cross,
  &__eye {
    position: absolute;
    top: 0;
    transform: translateY(-8px);
    right: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &__append {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 25px;
    & .svg-inline--fa {
      width: 20px !important;
      height: 20px !important;
    }
  }
  &__append-search {
    position: absolute;
    top: 0;
    transform: translateY(2px);
    right: -2px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  &__prepend {
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}
.form {
  &-input {
    margin: 0 !important;
    ::v-deep {
      fieldset {
        display: none !important;
      }
      .v-text-field__details {
        display: none;
      }
      .v-input__slot {
        margin-bottom: 0 !important;
        &:before,
        &:after {
          display: none;
        }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input::placeholder {
          font-size: 1rem;
          line-height: 1.5;
          color: var(--v-text-gray-base);
          font-weight: 500 !important;
        }
      }
      input {
        font-size: 1rem;
        line-height: 1.5;
        color: var(--v-primary-dark-base) !important;
        padding: 0 !important;
      }
    }
  }
  &-error {
    ::v-deep {
      input {
        color: var(--v-primary-dark-base) !important;
      }
    }
  }
}
.v-input--is-disabled {
  pointer-events: auto !important;
  ::v-deep {
    input {
      color: var(--v-text-gray-base) !important;
      opacity: 1 !important;
      cursor: default;
    }
  }
}
.disabled-dark {
  .v-input--is-disabled {
    ::v-deep {
      input {
        color: var(--v-primary-dark-base) !important;
        opacity: 1 !important;
      }
    }
  }
}
</style>
