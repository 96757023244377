<template>
  <div class="not-found container">
    <div class="not-found__content">
      <svg-icon name="not-found" width="174" height="114" />
      <span class="text-h1">Oops.</span>
      <span class="text-h2">{{ $t('profile.profileNotFound') }}</span>
      <p>
        <span class="d-block text-body-2 light-black--text">
          {{ $t('profile.profileNotFoundDescription1') }}
        </span>
        <span class="d-block text-center text-body-2 light-black--text">
          <nuxt-link to="/contact">{{ $t('profile.contactUs') }}</nuxt-link>
          {{ $t('profile.profileNotFoundDescription2') }}
        </span>
      </p>
    </div>
    <div class="not-found__footer mb-11">
      <Button
        height="48"
        width="100%"
        class="button--rounded button--primary"
        @click="goHome"
      >
        {{ $t('profile.goHome') }}
      </Button>
    </div>
  </div>
</template>

<script>
import vhOnMobile from '~/mixins/vhOnMobile';
export default {
  name: 'ProfileNotFound',
  components: {
    Button: () => import('~/components/shared/Button.vue'),
  },
  mixins: [vhOnMobile],
  layout: 'empty',
  methods: {
    goHome() {
      if (this.$apolloHelpers.getToken()) {
        window.location.href = `${process.env.baseUrl}/app/dashboard`;
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-rows: 1fr max-content;
  &__content {
    align-self: center;
    display: grid;
    grid-auto-rows: max-content;
    justify-items: center;
    gap: 1rem;
  }
  &__footer {
    width: 100%;
  }
}
</style>
