<template>
  <div data-organization-id />
</template>

<script>
import profileMixin from '~/mixins/profileMixin';

export default {
  name: 'ClubMemberProfile',
  mixins: [profileMixin],
  layout: 'member',
};
</script>
