<template>
  <div class="app-container d-flex flex-column">
    <LoginNavigation>
      <nuxt-link
        class="d-flex align-center"
        :to="{ path: '/', query: { ...$route.query } }"
      >
        <svg-icon
          class="primary--text"
          name="tap2link-logo"
          width="54"
          height="25"
        />
      </nuxt-link>
      <div class="d-flex align-center">
        <p class="text-body-2 mr-3">{{ $t('login.noAccount') }}</p>
        <Button
          height="44"
          class="button--rounded button--secondary text-button-2"
          @click="routerPushWithQuery('/register')"
        >
          {{ $t('login.registerNow') }}
        </Button>
      </div>
    </LoginNavigation>
    <main class="request d-flex flex-column container">
      <div v-show="custom" class="request__header">
        <div class="d-flex flex-column justify-center">
          <h1 class="text-h1 primary-dark--text">
            {{ $t('login.resetPassword') }}
          </h1>
          <p class="text-body-2 dark-grey--text mb-2">
            {{ $t('login.recoveryLink') }}
          </p>
        </div>
        <v-img
          :src="customLogo"
          class="ml-auto rounded-lg"
          width="68"
          max-height="68"
          :alt="`${customCompanyName}logo`"
        />
      </div>
      <h1 v-show="!custom" class="text-h1 primary-dark--text">
        {{ $t('login.resetPassword') }}
      </h1>
      <p v-show="!custom" class="text-body-2 dark-grey--text mb-2">
        {{ $t('login.recoveryLink') }}
      </p>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <v-form
          class="request__form d-flex flex-column"
          @submit.prevent="handleSubmit(handleRecoveryRequest)"
        >
          <CompactInput
            v-model="loginOrEmail.value"
            :label="loginOrEmail.label"
            :placeholder="
              $t('login.insertPlaceholder', { inputLabel: loginOrEmail.label })
            "
            rules="required|email"
            @focus="focusInput"
          />
          <Button
            width="100%"
            height="64"
            class="request__btn button--rounded button--primary"
            :class="!isMobile && 'mt-auto'"
            type="submit"
          >
            {{ isSent ? $t('login.sendAgain') : $t('login.sendRecoveryMail') }}
          </Button>
        </v-form>
      </ValidationObserver>
    </main>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions } from 'vuex';

import customTheme from '~/mixins/customTheme';

export default {
  name: 'request',
  components: {
    LoginNavigation: () => import('~/components/shared/AppNavigation.vue'),
    Button: () => import('~/components/shared/Button.vue'),
    CompactInput: () => import('~/components/shared/Compact.vue'),
    ValidationObserver,
  },
  mixins: [customTheme],
  layout: 'app',
  data() {
    return {
      loginOrEmail: {
        value: '',
        label: this.$t('login.loginOrEmail'),
      },
      isSent: false,
      isMobile: false,
    };
  },
  methods: {
    ...mapActions('auth', ['sendPasswordRecoveryRequest']),
    async handleRecoveryRequest() {
      const response = await this.sendPasswordRecoveryRequest(
        this.loginOrEmail.value,
      );
      if (response) {
        this.$root.$emit('show-flash-message', {
          message: this.$t('login.requestSent'),
          type: 'primary',
        });
        this.isSent = true;
      }
    },
    focusInput() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding-bottom: 5rem !important;
}

.request {
  min-height: 100vh;
  margin-top: 8rem;
  gap: 1rem;
  &__btn {
    &::v-deep {
      .v-btn__content {
        line-height: 1.55;
      }
    }
  }
  &__form {
    height: 100%;
    gap: 1rem;
  }
  &__message {
    font-size: 0.75rem;
    line-height: 1;
    letter-spacing: 0.1em;
  }
}
</style>
