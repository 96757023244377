<template>
  <section id="pricing" class="pricing light-grey">
    <h2 class="text-lp-h2 text-center pb-8">
      {{ $t('businessCard.pricing') }}
    </h2>
    <div class="container">
      <div class="pricing__table">
        <div
          class="pricing__column"
          v-for="(column, index) in columns"
          :key="column.title"
        >
          <h3 class="text-lp-h3">
            {{ column.title }}
            <span v-if="index === 2" class="dark-grey--text">
              {{ $t('businessCard.optional') }}
            </span>
          </h3>
          <v-divider class="light-hover" />
          <p>
            <span class="pricing__currency">
              {{ column.price }}
            </span>
            <span v-if="index !== 2" class="text-lp-body-2 dark-grey--text">
              {{ $t('businessCard.perEmployee') }}
            </span>
          </p>
          <v-divider class="light-hover" />
          <ul class="pricing__list dark-grey--text">
            <li
              class="text-lp-body-2 mb-4"
              v-for="item in column.features"
              :key="item"
            >
              <fa-icon class="mr-4" icon="check" />
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BusinessPricing',
  data() {
    return {
      columns: [
        {
          title: this.$t('businessCard.monthlyCosts'),
          price: '1€',
          features: [
            this.$t('businessCard.adminPanel'),
            this.$t('businessCard.companyPage'),
            this.$t('businessCard.employeePage'),
            this.$t('businessCard.premiumSupport'),
            this.$t('businessCard.loginPerEmployee'),
          ],
        },
        {
          title: this.$t('businessCard.nfcBusinessCards'),
          price: '10€',
          features: [
            this.$t('businessCard.materialChoices'),
            this.$t('businessCard.premiumFinishes'),
            this.$t('businessCard.nfcAndQr'),
            this.$t('businessCard.ecoFriendly'),
            this.$t('businessCard.easyConfiguration'),
          ],
        },
        {
          title: this.$t('businessCard.design'),
          price: '2000€',
          features: [
            this.$t('businessCard.customDesign'),
            this.$t('businessCard.cardDesign'),
            this.$t('businessCard.seniorDesigner'),
            this.$t('businessCard.oneHour'),
            this.$t('businessCard.threeRoundOfDesigns'),
          ],
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.pricing {
  position: relative;
  padding: 80px 0 !important;
  &__table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 4rem;
  }
  &__column {
    display: grid;
    grid-template-rows: repeat(4, max-content) 1fr max-content;
    row-gap: 2rem;
  }
  &__currency {
    font-size: 4rem;
    line-height: 0.9;
  }
  &__list {
    list-style: none;
    padding: 0;
    & li {
      text-indent: -2.5rem;
      padding-left: 2.5rem;
    }
  }
}
</style>
