<template>
  <div class="work-item">
    <nuxt-img
      class="work-item__image mb-5 mb-sm-0"
      format="webp"
      fit="contain"
      :src="src"
      :alt="alt"
      sizes="sm:100vw lg:400px"
      placeholder
      loading="lazy"
    />
    <div class="work-item__content">
      <h3 class="text-lp-h3 basic-black--text mb-2 mb-sm-3">{{ title }}</h3>
      <div class="text-lp-body-2 dark-grey--text">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorkItem',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
      default: '',
    },
    srcset: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.work-item {
  display: grid;
  grid-template-areas: 'image' 'content';
  grid-template-columns: minmax(0, 1fr);
  &:nth-child(odd) {
    @media #{$sm-and-up} {
      grid-template-areas: 'content image';
    }
  }
  &:nth-child(even) &__content {
    @media #{$sm-and-up} {
      padding: 0 clamp(2rem, 6vw, 6rem);
    }
  }
  &:nth-child(odd) &__content {
    @media #{$sm-and-up} {
      padding-right: clamp(2rem, 6vw, 6rem);
    }
  }
  @media #{$sm-and-up} {
    grid-template-areas: 'image content';
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: clamp(0rem, 2.5vw, 2rem);
  }
  &__content {
    grid-area: content;
  }
  &__image {
    grid-area: image;
    width: 100%;
    aspect-ratio: 305/289;
    border-radius: 6px;
  }
}
</style>
