<template>
  <div>
    <ValidationProvider
      v-slot="{ errors }"
      class="form-control"
      :rules="rules"
      :name="name"
      mode="eager"
    >
      <div class="d-flex justify-space-between align-center mb-2">
        <label
          v-if="label"
          :for="label"
          class="form-label text-h3 d-block"
          :class="errors.length ? 'orange--text' : 'basic-black--text'"
        >
          {{ label }}
        </label>
        <span
          v-if="errors.length"
          class="d-flex form-error subtitle-2 orange--text"
        >
          {{ errors[0] }}
        </span>
      </div>
      <v-text-field
        :id="label"
        :placeholder="placeholder"
        :type="type"
        class="form-input body-2"
        :class="{ 'form-input--error': errors.length }"
        outlined
        dense
        height="48"
        :autocomplete="autocomplete"
        background-color="light-grey"
        :value="value"
        v-bind="$attrs"
        v-on="$listeners"
        @input="$emit('input', $event)"
      />
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Input',
  components: {
    ValidationProvider,
  },
  model: {
    event: 'input',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
};
</script>

<style lang="scss" scoped>
.form {
  &-label,
  &-error {
    line-height: 1.4;
    letter-spacing: 0.15px !important;
    @media #{$xs-only} {
      font-size: 0.875rem !important;
    }
  }
  &-input {
    &::v-deep {
      fieldset {
        border: 1px solid var(--v-light-grey-base);
        border-radius: 6px;
        transition: none;
      }
      input {
        &::placeholder {
          color: var(--v-light-black-base) !important;
        }
      }
      .v-input__slot {
        margin-bottom: 0 !important;
        padding: 0 1rem !important;
      }
      .v-input__append-inner {
        align-self: center;
        margin-top: 0 !important;
      }
    }
    &--error {
      &::v-deep {
        fieldset {
          border-color: var(--v-orange-base);
        }
        input {
          color: var(--v-orange-base) !important;
          &::placeholder {
            color: var(--v-light-black-base) !important;
          }
        }
      }
    }
  }
}
.v-text-field--outlined {
  ::v-deep {
    .v-text-field__details {
      display: none;
    }
  }
  &.v-input--is-focused ::v-deep fieldset {
    border-color: var(--v-light-black-base);
  }
}
</style>
