<template>
  <Fragment>
    <Header />
    <Tutorials />
    <Details />
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

export default {
  name: 'tutorials',
  components: {
    Fragment,
    Header: () => import('~/components/tutorials/Header.vue'),
    Tutorials: () => import('~/components/tutorials/Tutorials.vue'),
    Details: () => import('~/components/Details.vue'),
  },
};
</script>

<style scoped></style>
