<template>
  <v-btn
    v-bind="$attrs"
    :color="color"
    :ripple="ripple"
    class="button text-button-1"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  box-shadow: none;
  min-width: min-content !important;
  text-transform: inherit;
  border-radius: 6px;
  &__icon--prepend {
    height: 12px;
  }
  &::before {
    display: none;
  }
  &:active {
    box-shadow: none;
  }
  //&::v-deep {
  //  .v-btn__content {
  //    font-size: 1rem;
  //    letter-spacing: normal;
  //    line-height: 1.4;
  //  }
  //}
  &--square {
    padding: 0 !important;
  }
  &--delete {
    background-color: var(--v-error-red-light-base) !important;
    color: var(--v-error-red-base) !important;
    &:hover {
      background-color: var(--v-error-red-light-hover-base) !important;
    }
    &:active {
      background-color: var(--v-error-red-light-active-base) !important;
    }
  }
  &--primary {
    background-color: var(--v-primary-base) !important;
    color: var(--v-light-base) !important;
    &:hover {
      background-color: var(--v-primary-hover-base) !important;
    }
    &:active {
      background-color: var(--v-primary-active-base) !important;
    }
  }
  &--secondary {
    background-color: var(--v-light-main-base) !important;
    color: var(--v-primary-base) !important;
    &:hover {
      background-color: var(--v-light-hover-base) !important;
    }
    &:active {
      background-color: var(--v-primary-base) !important;
      color: var(--v-white-base) !important;
    }
  }
  &--light {
    background-color: var(--v-light-main-base) !important;
    color: var(--v-primary-base) !important;
    &::v-deep {
      .v-btn__content {
        font-weight: bold;
      }
    }
    &:hover {
      background-color: var(--v-light-base) !important;
    }
    &:active {
      background-color: var(--v-custom-link-base) !important;
    }
  }
  &--profile-primary {
    background-color: var(--v-light-base) !important;
    color: var(--v-primary-dark-base) !important;
    border-radius: 8px !important;
    &::v-deep {
      .v-btn__content {
        font-weight: bold;
      }
    }
    &:hover {
      border: 1px solid var(--v-button-hover-gray-base) !important;
    }
    &:active {
      border: 1px solid var(--v-primary-base) !important;
    }
  }
  &--profile-secondary {
    background-color: var(--v-white-base) !important;
    color: var(--v-dark-grey-base) !important;
    border-radius: 8px;
    border: 1px solid var(--v-button-gray-base) !important;
    &:hover {
      border: 1px solid var(--v-button-hover-gray-base) !important;
    }
    &:active {
      border: 1px solid var(--v-primary-base) !important;
    }
  }
  &--rounded {
    border-radius: 8px !important;
  }
  &--expanded {
    grid-column: 1 / span 2;
  }
  &--outlined {
    border: 1px solid var(--v-primary-dark-base) !important;
    color: var(--v-primary-dark-base) !important;
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--dark {
    font-weight: normal !important;
    &:active,
    &:hover {
      background-color: var(--v-light-base) !important;
      color: var(--v-primary-dark-base) !important;
    }
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--cream {
    border: 1px solid var(--v-cream-base) !important;
    color: var(--v-cream-base) !important;
    &:hover,
    &:active {
      color: var(--v-basic-black-base) !important;
      background-color: var(--v-cream-base) !important;
    }
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--brown {
    border: none !important;
    border-radius: 11px !important;
    background-color: var(--v-cream-base) !important;
    color: var(--v-basic-black-base) !important;
    &:hover,
    &:active {
      background-color: #e8cf92 !important;
    }
    &:active {
      border: solid 2px black !important;
      box-sizing: border-box;
    }
  }
}
</style>
