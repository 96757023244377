<template>
  <div class="layout">
    <LazyHydrate when-idle><BusinessHeader /></LazyHydrate>
    <LazyHydrate never>
      <div class="container container--internal">
        <h2 class="text-lp-h2 basic-black--text pb-3">
          {{ $t('businessCard.oneLessToDo') }}
        </h2>
        <p class="text-body-1 dark-grey--text">
          {{ $t('businessCard.benefitsInfo') }}
        </p>
      </div>
    </LazyHydrate>
    <LazyHydrate when-visible><Advantages /></LazyHydrate>
    <LazyHydrate never>
      <div class="container container--internal">
        <h2 class="text-lp-h2 basic-black--text pb-3">
          {{ $t('businessCard.premiumServices') }}
        </h2>
        <p class="text-body-1 dark-grey--text">
          {{ $t('businessCard.customized') }}
        </p>
      </div>
    </LazyHydrate>
    <LazyHydrate when-visible><PremiumServices /></LazyHydrate>
    <LazyHydrate when-visible><BusinessPricing /></LazyHydrate>
    <LazyHydrate when-visible><GetInTouch /></LazyHydrate>
    <LazyHydrate never><BusinessCustomers /></LazyHydrate>
    <LazyHydrate when-visible><Partners /></LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

import BusinessHeader from '~/components/BusinessHeader.vue';

export default {
  name: 'ForBusiness',
  components: {
    LazyHydrate,
    BusinessHeader,
    BusinessCustomers: () => import('~/components/BusinessCustomers.vue'),
    Advantages: () => import('~/components/Advantages.vue'),
    PremiumServices: () => import('~/components/PremiumServices.vue'),
    BusinessPricing: () => import('~/components/BusinessPricing.vue'),
    GetInTouch: () => import('~/components/GetInTouch.vue'),
    Partners: () => import('~/components/Partners.vue'),
  },
  layout: 'landing-page',
};
</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  gap: clamp(4rem, 8vw, 5rem);
}
</style>
