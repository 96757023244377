<template>
  <div class="video-card__wrapper">
    <div
      class="video-card rounded light-grey d-flex flex-column justify-space-between"
      @click="dialog = true"
    >
      <div class="px-6 pt-6">
        <div class="video-card__video video">
          <iframe
            :src="video.link"
            frameborder="0"
            allowfullscreen=""
            class="video__frame"
          />
        </div>
        <h3 class="text-lp-h3 mt-6 mb-3">{{ video.title }}</h3>
        <p class="video-card__description body-2 dark-grey--text mb-6">
          {{ video.description }}
        </p>
      </div>
      <div class="video-card__time py-4">
        <div class="dark-grey--text text-right px-4">
          <fa-icon icon="clock" />
          <span>{{ video.time }}</span>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      width="800"
      height="600"
      @click="dialog = !dialog"
    >
      <div class="white rounded pa-10">
        <div class="video-card__video video">
          <iframe
            :src="video.link"
            frameborder="0"
            allowfullscreen=""
            class="video__frame"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'VideoThumbnail',
  props: {
    video: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.video-card {
  height: 100%;
  &__wrapper {
    height: 100%;
  }
  &__video {
    border-radius: 4px;
  }
  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5;
    @media #{$md-and-up} {
      font-size: 1.125rem;
      line-height: 1.44;
    }
  }
  &__time {
    border-top: 1px solid var(--v-neutral-medium-base);
    font-size: 1rem;
    line-height: 1.38;
  }
}
.video {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  &__frame {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
