import { render, staticRenderFns } from "./Hero.vue?vue&type=template&id=152da749&scoped=true&"
import script from "./Hero.vue?vue&type=script&lang=js&"
export * from "./Hero.vue?vue&type=script&lang=js&"
import style0 from "./Hero.vue?vue&type=style&index=0&id=152da749&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152da749",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VContainer,VImg})
