<template>
  <Spinner loading />
</template>

<script>
import Cookie from 'js-cookie';
import { mapActions, mapMutations } from 'vuex';

import Spinner from '@/components/Spinner.vue';
import { CLUB_MEMBERSHIP, COMPANY_INVITATION, CUSTOM_THEME } from '~/constants';

export default {
  name: 'CompanyInvitation',
  components: { Spinner },
  layout: 'empty',
  data() {
    return {
      company: '',
      membership: '',
    };
  },
  async mounted() {
    this.company = this.$route.params.company;
    this.membership = this.$route.query.membership;
    Cookie.set(COMPANY_INVITATION, true);
    Cookie.set(CUSTOM_THEME, this.company);
    Cookie.set(CLUB_MEMBERSHIP, this.membership);
    const profile = await this.fetchProfile({
      profileUniqueValue: this.company,
    });
    if (profile) {
      delete profile.customLinks;
      profile?.id &&
        Cookie.set('custom-theme-profile', JSON.stringify(profile));
    }
    if (!profile) {
      this.setFlashMessage({
        type: 'error',
        message: 'Invitation not found - please check your url',
      });
    } else {
      this.redirect();
    }
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    ...mapMutations(['setFlashMessage']),
    redirect() {
      if (!this.$apolloHelpers.getToken()) {
        return this.$router.push({
          path: '/welcome',
          query: { company: this.company },
        });
      }
      window.location.href = `${process.env.baseUrl}/app/dashboard?company=${this.company}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
