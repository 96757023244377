<template>
  <section id="advantages" class="advantages">
    <div class="advantages__container container">
      <div class="advantages__content">
        <Advantage v-for="card in cards" :key="card.id" :card="card" />
      </div>
    </div>
  </section>
</template>

<script>
import Advantage from '~/components/atoms/Advantage';
import advantages from '~/mixins/advantages';

export default {
  name: 'Advantages',
  components: { Advantage },
  mixins: [advantages],
  computed: {
    cards() {
      if (this.$route.name === 'index') return this.advantages;
      return this.businessAdvantages;
    },
  },
};
</script>

<style scoped lang="scss">
.advantages {
  &__container {
    @media #{$max-container-width} {
      padding: 0 !important;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 2.5rem 2rem;
    @media #{$sm-and-up} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}
</style>
