<template>
  <header class="header">
    <div class="header__container container">
      <h1 class="header__title text-lp-h1 basic-black--text mt-md-0">
        {{ $t('header.heroTitle') }}
      </h1>
      <p class="header__subtitle dark-grey--text">
        <span class="text-lp-body-1 d-inline-block mb-5">
          {{ $t('header.heroSubtitle') }}
        </span>
        <span class="text-lp-body-2">{{ $t('header.heroInfo') }}</span>
      </p>
      <div class="header__btns d-md-block mt-1 mt-md-5">
        <NuxtLinkButton to="/login" class="header__btn button--lp ma-5 ml-0">
          {{ $t('global.getStarted') }}
        </NuxtLinkButton>
        <!--        //TODO to be uncommented when we have links to applications-->
        <!--        <p class="d-inline-block dark-grey&#45;&#45;text pr-5">-->
        <!--          {{ $t('header.orDownload') }}-->
        <!--        </p>-->
        <!--        <div class="d-inline-block">-->
        <!--          <Button class="btn&#45;&#45;light mr-5">-->
        <!--            <fa-icon :icon="['fab', 'google-play']" />-->
        <!--          </Button>-->
        <!--          <Button class="btn&#45;&#45;light">-->
        <!--            <fa-icon :icon="['fab', 'apple']" />-->
        <!--          </Button>-->
        <!--        </div>-->
      </div>
      <video
        class="header__video"
        poster="/video/video_thumbnail.png"
        loop
        muted
        autoplay
        playsinline
        width="100%"
      >
        <source src="/video/t2l_clip-600.mov" type="video/quicktime" />
        <source src="/video/t2l_clip-600.webm" type="video/webm" />
      </video>
    </div>
    <div class="header__dot neutral-medium rounded-circle" />
  </header>
</template>

<script>
import NuxtLinkButton from '~/components/shared/NuxtLinkButton.vue';
import loggedInRedirection from '~/mixins/loggedInRedirection';

export default {
  name: 'Header',
  components: {
    NuxtLinkButton,
  },
  mixins: [loggedInRedirection],
};
</script>

<style scoped lang="scss">
.header {
  position: relative;
  overflow: hidden;
  background: var(--v-neutral-white-base);
  padding-top: 4.87rem;
  width: 100%;
  max-width: 90rem;
  @media #{$sm-and-up} {
    width: calc(100% - 2.5rem);
    border-radius: 12px;
    margin: 1.25rem auto 0;
  }
  &__container {
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content max-content 25rem;
    grid-template-areas: 'title' 'subtitle' 'buttons' 'video';
    @media #{$sm-and-up} {
      display: grid;
      grid-template-columns: 0.8fr;
      grid-template-rows: 0.75fr repeat(2, 0.5fr) 18.75rem;
      grid-template-areas: 'title' 'subtitle' 'buttons' 'video';
    }
    @media #{$md-and-up} {
      grid-template-columns: 1fr 0.8fr;
      grid-template-rows: 0.75fr repeat(2, 0.5fr) 1fr;
      grid-template-areas: '_ video' 'title video' 'subtitle video' 'buttons video';
      gap: 0 4rem;
    }
    @media #{$max-container-width} {
      padding: 0 !important;
    }
  }
  &__title {
    grid-area: title;
    margin: 3.3rem 0 0.62rem;
  }
  &__subtitle {
    grid-area: subtitle;
    z-index: 2;
  }
  &__btns {
    grid-area: buttons;
    z-index: 2;
    @media #{$tiny} {
      display: flex;
      flex-direction: column;
      & > p {
        padding-bottom: 1.25rem;
      }
    }
    & > div {
      @media #{$md-only} {
        margin-bottom: 4rem;
      }
    }
  }
  &__btn {
    width: 100%;
    @media #{$tiny} {
      max-width: 13.87rem;
    }
    @media #{$md-only} {
      margin-right: 18.75rem !important;
    }
  }
  &__video {
    grid-area: video;
    z-index: 1;
    position: absolute;
    bottom: 0;
    @media #{$sm-and-up} {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 29rem;
      width: 29rem;
    }
  }
  &__dot {
    position: absolute;
    bottom: -65vw;
    right: -60vw;
    width: 150vw;
    aspect-ratio: 1/1;
    @media #{$sm-and-up} {
      width: 75vw;
      bottom: -28vw;
      right: -8vw;
    }
    @media #{$md-and-up} {
      width: 46.87rem;
      top: 11.25rem;
      right: initial;
      left: 50vw;
    }
  }
}
</style>
