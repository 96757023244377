<template>
  <Fragment>
    <nav class="nav">
      <div class="nav__bar" :class="navBarClasses">
        <div
          class="nav__container container d-flex justify-space-between align-center"
        >
          <nuxt-link to="/">
            <svg-icon name="tap2link-logo" height="24" width="63" />
          </nuxt-link>
          <svg-icon
            v-if="$vuetify.breakpoint.smAndDown"
            class="nav__hamburger"
            name="hamburger"
            @click="toggleMenu"
            width="24"
            height="24"
          />
          <div v-else class="nav__content">
            <NavigationList
              class="nav__list--desktop"
              :items="navItems"
              colorClass="basic-black--text"
            />
            <Button
              href="https://shop.t2l.ink"
              target="_blank"
              class="btn--light"
            >
              {{ $t('global.shop') }}
            </Button>
            <NuxtLinkButton to="/welcome" class="button--lp">
              {{
                isLoggedIn ? $t('global.goToTheApp') : $t('global.getStarted')
              }}
            </NuxtLinkButton>
            <LangSwitcher colorClass="basic-black--text" />
          </div>
        </div>
      </div>
    </nav>
    <div>
      <v-navigation-drawer
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        temporary
        right
        v-model="drawer"
        width="280"
        color="white"
      >
        <nav class="d-flex flex-column px-9">
          <svg-icon
            class="nav__close align-self-end basic-black--text mt-11 mb-8"
            name="close"
            width="32"
            height="32"
            @click="toggleMenu"
          />
          <NavigationList
            :items="footerItems"
            class="mb-5"
            colorClass="basic-black--text"
          />
          <LangSwitcher colorClass="basic-black--text" />
          <v-divider class="nav__divider my-10" />
          <NuxtLinkButton to="/welcome" class="button--lp">
            {{ isLoggedIn ? $t('global.goToTheApp') : $t('global.getStarted') }}
          </NuxtLinkButton>
          <Button
            href="https://shop.t2l.ink"
            target="_blank"
            class="btn--light my-5"
          >
            {{ $t('global.shop') }}
          </Button>
        </nav>
      </v-navigation-drawer>
    </div>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';
import { mapGetters } from 'vuex';

import Button from '~/components/atoms/Button';
import LangSwitcher from '~/components/atoms/LangSwitcher';
import NavigationList from '~/components/atoms/NavigationList';
import NuxtLinkButton from '~/components/shared/NuxtLinkButton.vue';
import loggedInRedirection from '~/mixins/loggedInRedirection';
import navigationItems from '~/mixins/navigationItems';

export default {
  components: {
    NuxtLinkButton,
    LangSwitcher,
    NavigationList,
    Button,
    Fragment,
  },
  mixins: [navigationItems, loggedInRedirection],
  data() {
    return {
      drawer: false,
      scrollLastPosition: 0,
      isScrollUp: true,
      isScrollHigher: false,
    };
  },
  computed: {
    ...mapGetters('blog', ['posts']),
    navItems() {
      const navbarItems = [
        {
          id: 1,
          title: this.$t('global.home'),
          slug: '/',
        },
        {
          id: 2,
          title: this.$t('global.forBusiness'),
          slug: '/business',
        },
      ];
      const moreItems = [
        {
          id: '3.1',
          title: this.$t('global.FAQ'),
          slug: '/faq',
        },
      ];
      if (this.$i18n.locale === 'de') {
        return [
          ...navbarItems,
          {
            id: 3,
            title: this.$t('global.blog'),
            subItems: [
              ...this.posts,
              ...moreItems,
              {
                id: '3.2',
                title: this.$t('global.tutorials'),
                slug: '/tutorials',
              },
            ],
          },
        ];
      } else {
        return [
          ...navbarItems,
          {
            id: 3,
            title: this.$t('global.blog'),
            subItems: [...this.posts, ...moreItems],
          },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;
  &__bar {
    height: 4.875rem;
    transition: 0.2s $cubic-bezier;
    &--hidden {
      transform: translateY(-110%);
      @media #{$md-and-up} {
        transform: none;
      }
    }
    &--shadow {
      box-shadow: $box-shadow;
    }
  }
  &__container {
    height: 100%;
    @media #{$max-container-width} {
      padding: 0 !important;
    }
  }
  &__hamburger {
    cursor: pointer;
  }
  &__content {
    display: grid;
    gap: 1.75rem;
    grid-auto-flow: column;
    align-items: center;
  }
  &__close {
    cursor: pointer;
    &:hover,
    &:active {
      color: var(--v-basic-black-base) !important;
    }
  }
  &__divider {
    border-color: var(--v-divider-base) !important;
  }
  &__button {
    border: 1px solid var(--v-cream-base) !important;
    background: transparent !important;
    color: var(--v-cream-base) !important;
  }
}
</style>
