<template>
  <section id="meeting" class="contact pt-10 pb-md-10 pb-3 mb-10">
    <div class="d-flex flex-wrap justify-space-between align-center container">
      <div class="contact__card basic-black text-center py-10">
        <h2 class="text-lp-h2 light-grey--text mb-6">
          {{ $t('businessCard.getInTouchToLearnMore') }}
        </h2>
        <p class="text-lp-body-2 light-main--text">
          {{ $t('businessCard.areYouLookingFor') }}
        </p>
        <Button
          class="contact__button d-inline-block btn--light my-5 mt-sm-8 mr-8"
          @click="findAnAppointment"
        >
          {{ $t('businessCard.bookAnAppointment') }}
        </Button>
        <Button
          class="contact__button d-inline-block btn--primary my-5 mt-sm-8"
          @click="goToContact"
        >
          {{ $t('businessCard.leaveUsMessage') }}
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'Pricing.vue',
  components: {
    Button: () => import('@/components/atoms/Button'),
  },
  methods: {
    ...mapActions('contact', ['setSelectedTopic']),
    findAnAppointment() {
      window.location.href = 'https://calendar.app.google/o7c43Pg4yhHgf8r86';
    },
    goToContact() {
      this.setSelectedTopic({
        id: 2,
      });
      this.$router.push('/contact');
    },
  },
};
</script>

<style scoped lang="scss">
.contact {
  margin-top: -5rem;
  background: linear-gradient(
    var(--v-light-grey-base) 0%,
    var(--v-light-grey-base) 50%,
    var(--v-white-base) 50%,
    var(--v-white-base) 100%
  );
  &__card {
    width: 100%;
    padding: 0 6.5rem;
  }
  &__button.white {
    border-color: var(--v-light-grey-base) !important;
  }
}
</style>
