<template>
  <div class="badge" v-once>
    <span class="font-weight-medium dark-grey--text mb-2">Powered by</span>
    <svg-icon name="tap2link-logo-long" height="22" width="104" />
  </div>
</template>

<script>
export default {
  name: 'PoweredBy.vue',
};
</script>

<style lang="scss" scoped>
.badge {
  display: grid;
  justify-items: center;
  font-size: 0.875rem;
  line-height: 1;
}
</style>
