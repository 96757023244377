<template>
  <div class="avatar" :class="classVariant">
    <v-img
      v-show="avatarImage"
      :src="avatarImage"
      contain
      class="avatar__image"
      alt=""
    />
    <div
      v-show="!avatarImage"
      class="avatar__placeholder align-center justify-center dark-background text-center text-gray--text"
    >
      {{ placeholderString }}
    </div>
    <fa-icon v-if="icon" class="avatar__icon" :class="iconClass" :icon="icon" />
    <nuxt-link
      v-if="organizationUrl && organizationPicture"
      :to="badgeUrl"
      class="avatar__badge"
    >
      <v-img :src="organizationPicture" />
    </nuxt-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { PROFILE_TYPE } from '~/constants';
import { getStorageUrl } from '~/helpers';

export default {
  name: 'Avatar',
  props: {
    avatarImage: {
      type: String,
      default: '',
    },
    profileFullName: {
      type: String,
      default: '',
    },
    profileType: {
      type: String,
      default: PROFILE_TYPE.PERSONAL,
    },
    classVariant: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    organizationUrl: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['currentOrganizationProfile']),
    iconClass() {
      if (this.icon === 'check-circle') return 'check-in-green--text';
      if (this.icon === 'exclamation-circle') return 'error-red--text';
      return '';
    },
    placeholderString() {
      if (this.profileType === PROFILE_TYPE.PERSONAL) {
        return this.profileFullName
          .split(' ', 2)
          .map((item) => item[0]?.toUpperCase())
          .join('');
      }
      return this.profileFullName?.[0]?.toUpperCase() || '';
    },
    organizationPicture() {
      return getStorageUrl(this.currentOrganizationProfile?.photoName);
    },
    badgeUrl() {
      const urlPartials = this.$route.fullPath.split('/');
      urlPartials.pop();
      return urlPartials.join('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  border-radius: 12px;
  &__image,
  &__placeholder {
    display: flex;
    border-radius: 12px;
    line-height: 1;
    width: 100%;
    height: 100%;
  }
  &::v-deep {
    .v-image__image {
      background-size: cover !important;
    }
  }
  &__icon {
    font-size: 16px;
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  &--profile {
    max-width: 150px;
    width: 150px;
    height: 150px;
    font-size: 4.375rem;
  }
  &--card {
    max-width: 100px;
    width: 100px;
    height: 100px;
    font-size: 3rem;
  }
  &--member {
    width: 80px;
    height: 80px;
    font-size: 1.5rem;
    box-shadow: unset;
  }
  &--check-in {
    width: 92px;
    height: 92px;
    max-height: 92px;
    font-size: 3rem;
    border-radius: 8px;
  }
  &--invitation {
    width: 140px;
    height: 140px;
    font-size: 1.2rem;
    box-shadow: unset;
  }
  &--navigation {
    width: 46px;
    height: 46px;
    border-radius: 16px;
    border: 3px solid var(--v-white-base);
    box-shadow: $small-image-shadow;
  }
  &--menu {
    width: 24px;
    height: 24px;
    font-size: 0.75rem;
    box-shadow: none;
  }
  &--badge {
    width: 16px;
    height: 16px;
    font-size: 0.5rem;
  }
  &--additional {
    transform: translateX(-25px);
    z-index: -1;
  }
}
</style>
