<template>
  <Fragment>
    <Header />
    <Topics />
    <Details />
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-frag';

export default {
  name: 'faq',
  components: {
    Fragment,
    Topics: () => import('~/components/faq/Topics.vue'),
    Details: () => import('~/components/Details.vue'),
    Header: () => import('~/components/faq/Header.vue'),
  },
};
</script>

<style scoped></style>
