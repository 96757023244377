<template>
  <v-app dark class="app" :id="customThemeId">
    <FlashMessage />
    <NewCardDialog />
    <nuxt />
    <CookieControl :locale="$root.$i18n.locale">
      <template v-slot:bar>
        <h3 class="basic-black--text">
          {{ $t('cookieConsent.weUseCookies') }} 🍪
        </h3>
        <p class="dark-grey--text">
          {{ $t('cookieConsent.description') }}
          {{ $t('cookieConsent.legalInformation') }}
          <nuxt-link class="basic-black--text" to="/privacy">
            {{ $t('cookieConsent.privacyPolicy') }}
          </nuxt-link>
          {{ $t('cookieConsent.tolearnMore') }}
        </p>
      </template>
    </CookieControl>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { fetchProfileCustomTheme } from '~/helpers';
import checkAffiliateLinks from '~/middleware/checkAffiliateLinks';
import tabsBroadcast from '~/mixins/tabsBroadcast';

export default {
  components: {
    NewCardDialog: () => import('~/components/NewCardDialog.vue'),
    FlashMessage: () => import('~/components/shared/FlashMessage.vue'),
  },
  mixins: [tabsBroadcast],
  middleware: [checkAffiliateLinks],
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  computed: {
    ...mapGetters('profile', ['customThemeId']),
  },
  watch: {
    customThemeId(newValue) {
      if (newValue) {
        fetchProfileCustomTheme(newValue);
      }
    },
  },
  async created() {
    const customTheme = this.$route.query?.company;
    if (process.client && customTheme) {
      const root = document.getElementById('__nuxt');
      root.classList.add(customTheme);
    }
    if (this.$apolloHelpers.getToken()) {
      await this.fetchUserProfiles();
    }
  },
  methods: {
    ...mapActions('profile', ['fetchUserProfiles']),
  },
};
</script>
<style lang="scss" scoped>
.app {
  padding-top: 0 !important;
  background-color: var(--v-background-base);
  ::v-deep {
    .v-application--wrap {
      overflow: hidden;
      min-height: unset;
    }
    .container {
      width: 100%;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      max-width: 672px !important;
      @media #{$small} {
        max-width: 640px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    .welcome__title--custom {
      font-size: 1.875rem;
      line-height: 1.4;
    }
    .login__header,
    .token__header,
    .request__header,
    .register__header {
      display: grid;
      grid-template-columns: 1fr max-content;
    }
    .token__header,
    .request__header {
      align-items: center;
    }
    .token__title,
    .request__title,
    .welcome__description {
      white-space: pre-line;
    }
    .welcome__content {
      gap: 3rem;
      @media #{$under-xs} {
        gap: 1rem;
        grid-row: unset;
      }
    }
  }
}
</style>
