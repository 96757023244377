<template>
  <v-app dark class="app">
    <div v-if="profile" class="profile" :id="customThemeId">
      <Navigation />
      <Header />
      <main class="profile__content container">
        <MembersDropDown v-if="isChristkindlmarkt" />
        <Team v-if="!isChristkindlmarkt && displayTeams" />
        <v-divider v-if="displayTeams" class="divider-gray my-2" />
        <Highlighted v-if="profileHaveCustomLinks" />
        <CustomLinks v-if="profileHaveCustomLinks" />
        <ProductDropDown />
        <v-divider v-if="profileHaveCustomLinks" class="divider-gray my-2" />
        <About />
        <client-only>
          <ContactDetails
            v-if="profileHaveContactLinks"
            :is-profile-owner="isProfileOwner"
          />
        </client-only>
        <Teams />
        <div class="profile__credits">
          <svg-icon
            name="tap2link-logo-long"
            class="profile__logo mt-1"
            @click="goToHomepage"
          />
        </div>
        <nuxt />
      </main>
      <Footer />
    </div>
    <FlashMessage />
    <NewCardDialog />
    <QrCode />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { fetchProfileCustomTheme } from '@/helpers';
import { CUSTOM_LINK_TYPE, PROFILE_TYPE } from '~/constants';
import checkAffiliateLinks from '~/middleware/checkAffiliateLinks';
import fetchUserData from '~/mixins/fetchUserData';
import tabsBroadcast from '~/mixins/tabsBroadcast';

export default {
  name: 'ProfileLayout',
  components: {
    Highlighted: () => import('~/components/profile/Highlighted.vue'),
    ProductDropDown: () => import('~/components/shared/ProductDropDown.vue'),
    MembersDropDown: () => import('~/components/shared/MembersDropDown.vue'),
    QrCode: () => import('~/components/QrCode.vue'),
    NewCardDialog: () => import('~/components/NewCardDialog.vue'),
    FlashMessage: () => import('~/components/shared/FlashMessage.vue'),
    Navigation: () => import('~/components/profile/Navigation.vue'),
    Header: () => import('~/components/profile/Header.vue'),
    Team: () => import('~/components/profile/Team.vue'),
    CustomLinks: () => import('~/components/profile/CustomLinks.vue'),
    About: () => import('~/components/profile/About.vue'),
    ContactDetails: () => import('~/components/profile/Contact.vue'),
    Teams: () => import('~/components/profile/Organizations.vue'),
    Footer: () => import('~/components/profile/Footer.vue'),
  },
  mixins: [fetchUserData, tabsBroadcast],
  middleware: [checkAffiliateLinks],
  data() {
    return {
      CUSTOM_LINK_TYPE,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'customLinks',
      'customThemeId',
      'highlighted',
      'isClub',
      'isCompany',
      'isProfileOwner',
      'photoUrl',
      'profile',
      'profileHaveContactLinks',
    ]),
    displayTeams() {
      return this.isClub || this.isCompany;
    },
    isChristkindlmarkt() {
      return this.$route.params?.organization === 'Christkindlmarkt.club';
    },
    profileHaveCustomLinks() {
      return !!(
        [...this.customLinks, ...this.highlighted].length ||
        this.profile?.websiteUrl
      );
    },
  },
  async created() {
    if (this.profile?.profileType === PROFILE_TYPE.CLUB && this.profile?.id) {
      await this.findAllMembershipTypes(this.profile.id);
      await this.findAllMembers(this.profile.id);
    }
  },
  mounted() {
    if (this.customThemeId) fetchProfileCustomTheme(this.customThemeId);
  },
  methods: {
    ...mapActions('organization', ['findAllMembershipTypes', 'findAllMembers']),
    goToHomepage() {
      window.location.href = process.env.baseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  height: 100%;
  &__content {
    position: relative;
    display: grid;
    gap: 1rem;
    margin-bottom: 7.25rem;
    @media #{$under-xs} {
      margin-bottom: 11rem;
    }
  }
  &__credits {
    justify-self: center;
  }
  &__logo {
    width: 127px;
    height: 26px;
  }
}
.app {
  min-height: 100vh;
  padding-top: 0 !important;
  background-color: var(--v-background-base);
  ::v-deep .container {
    padding: 0 1rem !important;
    max-width: 672px !important;
    @media #{$small} {
      max-width: 640px !important;
      padding: 0 !important;
    }
  }
}
</style>
