var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"rules":_vm.rules,"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c('div',{staticClass:"dropdown d-flex align-center light rounded-lg",class:{ 'dropdown--error': errors.length }},[_c('v-menu',{attrs:{"offset-y":"","nudge-left":"2","nudge-bottom":"-2"},on:{"input":validate},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-space-between flex-grow-1 px-4 py-2"},on),[_c('div',{staticClass:"text-truncate d-flex flex-column flex-grow-1"},[_c('label',{staticClass:"dropdown__label text-h4 dark-grey--text text-capitalize font-weight-bold"},[_vm._v("\n              "+_vm._s(_vm.label)+"\n            ")]),_vm._v(" "),_c('div',{class:_vm.activeItemText === _vm.$t('global.selectItem')
                  ? 'primary-dark--text'
                  : _vm.activeColor},[(_vm.activeItemIcon)?_c('fa-icon',{staticClass:"justify-end mr-1",attrs:{"icon":_vm.activeItemIcon}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.activeItemText))])],1)]),_vm._v(" "),_c('fa-icon',_vm._b({staticClass:"text-dark--text justify-end ml-2",attrs:{"icon":"caret-down"}},'fa-icon',attrs,false))],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"menu"},_vm._l((_vm.otherItemsList),function(item){return _c('div',{key:item.id,staticClass:"menu__item primary-dark--text body-2 py-4 px-4",on:{"click":function($event){return _vm.chooseItem(item.id)}}},[(item.icon)?_c('fa-icon',{staticClass:"dark-grey--text",attrs:{"icon":item.icon}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"text-body-1 text-truncate"},[_vm._v(_vm._s(item.text))])],1)}),0)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }