<template>
  <section id="faq" class="faq">
    <v-container class="faq__container container">
      <h3 class="faq__title font-weight-black text-center">
        {{ $t('faq.ourFAQ') }}
      </h3>
      <ul>
        <li
          v-for="index in 10"
          :key="index"
          class="faq__answers dark-grey--text"
        >
          <span>{{ $t(`faq.rheinfire.${index}`) }}</span>
        </li>
      </ul>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'faq.vue',
};
</script>

<style lang="scss" scoped>
.faq {
  &__title {
    font-size: 2rem;
    line-height: 1.4 !important;
  }
  &__container {
    display: grid;
    gap: 3rem;
    @media #{$lg-and-up} {
      padding: 0 8.8rem 5rem !important;
    }
  }
  &__answers {
    font-size: 1.125rem;
    line-height: 1.4;
  }
  & ul {
    list-style: none;
    padding: 0;
  }
  & li {
    padding: 0 0 1.3rem 2rem;
  }
  & li:before {
    content: '🏈';
    display: inline-block;
    position: relative;
    top: 5px;
    margin-left: -2em;
    width: 2em;
  }
}
</style>
