<template>
  <div class="welcome container">
    <div class="welcome__content">
      <nuxt-img
        v-if="!custom"
        src="/icon.png"
        width="172"
        height="172"
        alt="t2l.ink logo"
        format="webp"
      />
      <nuxt-img
        v-if="customLogo"
        :src="customLogo"
        :alt="`${customCompanyName} logo`"
        width="185"
        max-height="185"
        class="mt-4 rounded-lg"
        format="webp"
      />
      <div>
        <h1
          v-show="custom"
          class="welcome__title welcome__title--custom text-center text-h1 input-bg--text mb-5"
        >
          Welcome to {{ customCompanyName }}
        </h1>
        <h1 v-show="!custom" class="welcome__title text-h1 input-bg--text mb-5">
          {{ $t('global.welcomeTo') }}
        </h1>
        <p
          v-show="custom"
          class="welcome__description text-center dark-grey--text mx-auto"
        >
          {{
            $t('global.welcomeCustomDescription', {
              company: customCompanyName,
            })
          }}
        </p>
        <p
          v-show="!custom"
          class="welcome__description text-body-2 text-center dark-grey--text mx-auto"
        >
          {{ $t('global.welcomeDescription') }}
        </p>
      </div>
      <PoweredBy v-if="custom" />
    </div>
    <div class="welcome__footer mb-11">
      <p class="welcome__subtitle text-body-2 text-center dark-grey--text">
        {{ $t('global.getStartedNow') }}
      </p>
      <Button
        height="48"
        width="100%"
        max-width="165"
        class="button--rounded button--secondary"
        @click="routerPushWithQuery('/login')"
      >
        {{ $t('login.login') }}
      </Button>
      <Button
        height="48"
        width="100%"
        max-width="165"
        class="button--rounded button--primary"
        @click="routerPushWithQuery('/register')"
      >
        {{ $t('login.register') }}
      </Button>
    </div>
  </div>
</template>

<script>
import PoweredBy from '~/components/PoweredBy.vue';
import Button from '~/components/shared/Button.vue';
import isAuth from '~/middleware/isAuth';
import customTheme from '~/mixins/customTheme';
import vhOnMobile from '~/mixins/vhOnMobile';
export default {
  name: 'welcome',
  components: {
    Button,
    PoweredBy,
  },
  mixins: [customTheme, vhOnMobile],
  layout: 'app',
  middleware: [isAuth],
};
</script>

<style lang="scss" scoped>
.welcome {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-rows: 1fr max-content;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 2rem;
  &__content {
    display: grid;
    grid-auto-rows: max-content;
    justify-items: center;
    gap: 4.125rem;
    grid-row: 1 / -1;
    align-self: center;
  }
  &__title {
    font-family: var(--font-family);
  }
  &__description {
    max-width: 240px;
  }
  &__subtitle {
    grid-column: 1 / -1;
    justify-self: center;
  }
  &__footer {
    display: grid;
    gap: 1.25rem 0.75rem;
    grid-template-columns: repeat(2, minmax(0, 165px));
    grid-auto-rows: max-content;
  }
}
</style>
