<template>
  <v-snackbar
    v-model="toast.show"
    class="toast"
    :color="toastColor"
    bottom
    timeout="6000"
    transition="slide-y-reverse-transition"
    @click.native="closeFlashMessage"
  >
    <fa-icon
      :icon="toastIcon"
      class="toast__icon mr-6"
      :class="`${toastIconColor}--text`"
    />
    <span class="toast__content text-body-2 dark-grey--text">
      {{ toast.message }}
    </span>
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex';

import { TOAST_TYPE } from '@/constants';

export default {
  name: 'FlashMessage',
  data() {
    return {
      TOAST_TYPE,
      toast: {
        show: false,
        message: '',
        type: TOAST_TYPE.ERROR,
      },
      showTimeOutId: null,
    };
  },
  computed: {
    ...mapState(['flashMessage']),
    toastColor() {
      return this.toast.type === TOAST_TYPE.ERROR ? 'light-red' : 'light-main';
    },
    toastIcon() {
      if (this.toast.type === TOAST_TYPE.ERROR) return 'exclamation-circle';
      if (this.toast.type === TOAST_TYPE.INFO) return 'info-circle';
      return 'check-circle';
    },
    toastIconColor() {
      return this.toast.type === TOAST_TYPE.ERROR ? 'error-red' : 'primary';
    },
  },
  watch: {
    flashMessage(value) {
      if (value.message) this.showFlashMessage(value);
    },
    toast: {
      deep: true,
      handler(value) {
        if (value.show) return;
        clearTimeout(this.showTimeOutId);
      },
    },
  },
  mounted() {
    this.$root.$on('show-flash-message', this.showFlashMessage);
  },
  beforeDestroy() {
    this.$root.$off('show-flash-message', this.showFlashMessage);
    if (this.showTimeOutId) clearTimeout(this.showTimeOutId);
  },
  methods: {
    showFlashMessage(event) {
      this.toast = {
        ...event,
        show: true,
      };
      this.showTimeOutId = setTimeout(() => {
        this.$store.commit('setFlashMessage', { type: '', message: '' });
      }, 6000);
    },
    closeFlashMessage() {
      this.toast = {
        show: false,
        message: '',
      };
      this.$nextTick(() => {
        this.toast.type = TOAST_TYPE.ERROR;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  &__icon {
    width: 1.375rem;
    height: 100%;
  }
  ::v-deep {
    .v-snack {
      &__wrapper {
        cursor: pointer;
        border-radius: 0.75rem !important;
        width: 640px;
        margin: 0 0 8rem;
        @media #{$under-xs} {
          margin-bottom: 10rem;
        }
      }
      &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        @media #{$under-xs} {
          padding: 0.75rem 1rem;
        }
      }
      &__action {
        display: none;
      }
    }
  }
}
</style>
