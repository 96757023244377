<template>
  <div
    class="switch"
    :class="checked ? 'primary-dark--text' : ''"
    :data-label="rightLabel"
  >
    <input
      class="switch__checkbox"
      type="checkbox"
      v-model="checked"
      @change="$emit('input', checked)"
    />
    <label class="switch__label text-body-1">
      <span
        class="switch__label--span"
        :class="checked ? 'text-gray--text' : 'primary-dark--text'"
      >
        {{ leftLabel }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SwitchWithText',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    leftLabel: {
      type: String,
      default: 'False',
    },
    rightLabel: {
      type: String,
      default: 'True',
    },
    discount: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checked: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.checked = newValue;
    },
  },
};
</script>

<style scoped lang="scss">
.switch {
  background: var(--v-light-base);
  border-radius: 30px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  line-height: 1.4;
  color: var(--v-text-gray-base);
  position: relative;
  padding-right: 50%;

  &::before {
    content: attr(data-label);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &__checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch__label::before {
      transform: translateX(96%);
      transition: transform 0.4s linear;
    }

    & + .switch__label {
      position: relative;
      padding: 0.75rem 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &::before {
        content: '';
        background: var(--v-white-base);
        height: 80%;
        width: 100%;
        position: absolute;
        left: 2%;
        top: 10%;
        border-radius: 30px;
        transform: translateX(0);
        transition: transform 0.4s;
      }

      .switch__label--span {
        position: relative;
      }
    }
  }
}
</style>
