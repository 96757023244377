<template>
  <footer id="footer" class="footer basic-black py-10">
    <div class="footer__container container">
      <div class="footer__content">
        <div class="footer__logo">
          <svg-icon
            class="d-block white--text"
            name="tap2link_logo_long"
            width="102"
            height="25"
          />
        </div>
        <nav class="footer__nav d-flex justify-center">
          <NavigationList
            :items="footerItems"
            colorClass="white--text"
            class="nav__list--light text-center"
            alignClass="justify-center"
          />
        </nav>
        <Button
          class="footer__btn btn--light"
          @click="$router.push('/welcome')"
        >
          {{ $t('global.loginOrRegister') }}
        </Button>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import Button from '~/components/atoms/Button';
import NavigationList from '~/components/atoms/NavigationList';
import navigationItems from '~/mixins/navigationItems';

export default {
  name: 'Footer',
  components: { NavigationList, Button },
  mixins: [navigationItems],
  computed: {
    ...mapGetters('blog', ['posts']),
  },
};
</script>

<style scoped lang="scss">
.footer {
  &__container {
    @media #{$max-container-width} {
      padding: 0 !important;
    }
  }
  &__content {
    display: grid;
    gap: 1.5rem;
    grid-template-areas: 'logo' 'nav' 'btn';
    @media #{$lg-and-up} {
      grid-template-areas: 'logo . nav btn';
      grid-template-columns: max-content minmax(0, 1fr) repeat(2, max-content);
      gap: 1.875rem;
      align-items: center;
    }
  }
  &__logo {
    grid-area: logo;
    justify-self: center;
  }
  &__nav {
    grid-area: nav;
  }
  &__btn {
    grid-area: btn;
  }
  ::v-deep {
    .nav__list {
      @media #{$lg-and-up} {
        display: grid;
        grid-auto-flow: column;
        gap: 1.875rem;
        align-items: center;
      }
    }
    .nav__item {
      @media #{$lg-and-up} {
        margin-bottom: 0;
      }
    }
  }
}
</style>
