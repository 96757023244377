<template>
  <div>
    <div v-if="showTips" class="info-screen container mx-auto">
      <div class="text-center">
        <fa-icon
          icon="check-circle"
          class="info-screen__icon--info primary--text mb-7"
        ></fa-icon>
        <p class="info-screen__title font-weight-black">
          {{ $t('global.closeTab') }}
        </p>
      </div>
    </div>
    <Spinner v-else :loading="loading" />
    <div v-if="notFound" class="info-error container mx-auto text-center">
      <fa-icon
        icon="exclamation-circle"
        class="info-screen__icon--info error-red--text mb-7"
      ></fa-icon>
      <p class="info-screen__title font-weight-black">
        {{ $t('error.cardWasNotFound') }}
      </p>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import isEmpty from 'lodash-es/isEmpty';
import { mapActions, mapGetters } from 'vuex';

import Spinner from '@/components/Spinner.vue';
import {
  COOKIE_TYPE,
  INVITATION_STATUS,
  PROFILE_STATUS,
  PROFILE_TYPE,
} from '~/constants';

export default {
  name: 'Card',
  components: {
    Spinner,
  },
  layout: 'empty',
  data() {
    return {
      showTips: false,
      loading: true,
      notFound: false,
    };
  },
  computed: {
    ...mapGetters('profile', ['profile']),
  },
  async mounted() {
    const card = await this.fetchCard(this.$route.params.id);
    if (isEmpty(card)) {
      this.loading = false;
      this.notFound = true;
      return;
    }
    const hasProfile = !!(await this.fetchProfile({
      profileUniqueValue: card.profile?.id ?? null,
      scope: false,
    }));
    if (hasProfile) {
      const { id, nickName, profileStatus, profileType } = this.profile;
      if (Cookie.get(COOKIE_TYPE.CLUB_EVENT_SCANNER)) {
        this.sendClubEventResponse(id);
        return;
      }
      await this.checkRoutes(id, nickName, profileStatus, profileType);
    } else {
      if (Cookie.get(COOKIE_TYPE.CARD_CONNECT_PRODUCT)) {
        Cookie.set(COOKIE_TYPE.NEW_PRODUCT_CARD_ID, this.$route.params.id);
        this.showTips = true;
        return;
      }
      if (Cookie.get(COOKIE_TYPE.CARD_CONNECT_SCANNER)) {
        this.sendCardConnectResponse();
        return;
      }
      if (Cookie.get('multiple-tabs') === 'true') {
        this.showTips = true;
      }
      this.newCardRedirection(card);
    }
  },
  methods: {
    ...mapActions('profile', [
      'fetchCard',
      'fetchProfile',
      'fetchProfileOrganizations',
    ]),
    sendCardConnectResponse() {
      Cookie.set(COOKIE_TYPE.CARD_CONNECT_RESPONSE, this.$route.params.id);
      Cookie.remove(COOKIE_TYPE.CARD_CONNECT_SCANNER);
      this.showTips = true;
    },
    newCardRedirection(card) {
      const { invitation, organization } = card;
      if (invitation?.isPaid) {
        Cookie.set(COOKIE_TYPE.PREPAID_CARD_ID, this.$route.params.id);
      } else {
        Cookie.set(COOKIE_TYPE.REDIRECT_CARD_ID, this.$route.params.id);
      }
      if (invitation?.id && invitation?.status === INVITATION_STATUS.WAITING) {
        window.location.href = `${process.env.baseUrl}/app/confirm/${invitation.id}`;
        return;
      }
      if (this.showTips) {
        return;
      }
      if (this.$apolloHelpers.getToken()) {
        window.location.href = `${process.env.baseUrl}/app/dashboard`;
        return;
      }
      if (organization?.profile?.nickName) {
        this.$router.push(`/welcome?company=${organization.profile.nickName}`);
      } else {
        this.$router.push('/welcome');
      }
    },
    sendClubEventResponse(id) {
      Cookie.set(COOKIE_TYPE.CLUB_EVENT_RESPONSE, id);
      this.showTips = true;
    },
    async checkRoutes(id, nickName, profileStatus, profileType) {
      const profileOrganization = (
        await this.fetchProfileOrganizations({
          profileIds: [id],
          singleProfile: true,
        })
      )?.organization.profile;
      const profileUniqueValue =
        profileStatus === PROFILE_STATUS.PUBLIC ? nickName : id;
      if (profileType === PROFILE_TYPE.PRODUCT) {
        const prefix =
          profileOrganization?.profileStatus === PROFILE_STATUS.PUBLIC
            ? `${profileOrganization?.nickName}`
            : `company/${profileOrganization?.id}`;
        return this.$router.push(`/${prefix}/product/${profileUniqueValue}`);
      }
      if (profileOrganization) {
        if (profileOrganization.profileStatus === PROFILE_STATUS.PUBLIC) {
          this.$router.push(
            `/${profileOrganization.nickName}/${profileUniqueValue}`,
          );
        } else {
          this.$router.push(
            `/club/${profileOrganization.id}/${profileUniqueValue}`,
          );
        }
      } else {
        if (profileStatus === PROFILE_STATUS.PUBLIC) {
          this.$router.push(`/${profileUniqueValue}`);
        } else {
          this.$router.push(`/profile/${profileUniqueValue}`);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info {
  &-screen {
    display: grid;
    grid-template-rows: 1fr max-content;
    place-items: center;
    min-height: 100vh;
    padding-bottom: 5rem !important;
    @media #{$lg-and-up} {
      padding-bottom: 0 !important;
    }
    &__icon {
      &--info {
        font-size: 2.5rem;
      }
      &--warning {
        font-size: 1.25rem;
      }
    }
    &__title {
      font-size: 1.56rem;
      line-height: 1;
      white-space: pre-line;
    }
    &__warning {
      font-size: 0.875rem;
      line-height: 1.55;
      border-radius: 12px;
    }
  }
  &-error {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: 5rem max-content;
    align-content: center;
    place-items: center;
  }
}
</style>
