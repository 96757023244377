<template>
  <nav class="nav d-flex align-center pt-5 pb-2" :class="navBg">
    <div class="container d-flex justify-space-between align-center">
      <slot />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppNavigation',
  computed: {
    navBg() {
      if (!this.$route.query?.company) return 'white';
      return 'nav--customBg';
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  width: 100vw;
  z-index: 10;
  &--customBg {
    backdrop-filter: blur(10px);
  }
  &__btn {
    border-radius: 10px;
    ::v-deep {
      .v-btn__content {
        font-size: 1rem;
        line-height: 1.4;
      }
    }
  }
  &__title {
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.55;
  }
  &__icon {
    height: 16px;
    width: 16px;
  }
}
</style>
