<template>
  <div class="team d-flex align-center white rounded-pill pr-2 mr-3">
    <Avatar
      :avatar-image="organizationLogo"
      :profile-full-name="organizationName"
      :profile-type="PROFILE_TYPE.CLUB"
      class-variant="avatar--badge"
    />
    <span class="team__name font-weight-bold primary-dark--text ml-1">
      {{ organizationName }}
    </span>
  </div>
</template>

<script>
import Avatar from '~/components/atoms/Avatar.vue';
import { PROFILE_TYPE } from '~/constants';

export default {
  name: 'Badge',
  components: {
    Avatar,
  },
  props: {
    organizationLogo: {
      type: String,
      default: '',
    },
    organizationName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      PROFILE_TYPE,
    };
  },
};
</script>

<style lang="scss" scoped>
.team {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  align-self: center;
  padding: 2px;
  width: max-content;
  height: 24px;
  &__name {
    font-size: 0.75rem;
  }
  &__image {
    border-radius: 50%;
  }
}
</style>
