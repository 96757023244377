<template>
  <section class="section" :class="`section--${variant}`">
    <h2 class="section__title font-weight-black">
      {{ section.title }}
    </h2>
    <div
      v-if="section.description"
      class="section__description dark-grey--text"
    >
      <span>{{ section.description }}</span>
    </div>
    <ol v-if="section.list.length" class="section__list dark-grey--text">
      <li v-for="(item, index) in section.list" :key="index">
        {{ item.content }}
      </li>
    </ol>
    <img
      v-if="section.picture.url"
      :src="section.picture.url"
      :alt="section.picture.alternativeText"
      class="section__image d-block rounded-lg"
    />
  </section>
</template>

<script>
export default {
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  computed: {
    variant() {
      return this.section.variant.replace('_', '-');
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  &__title {
    margin-bottom: 0.625rem;
    font-size: 1.625rem;
    line-height: 1.2;
    @media #{$sm-and-up} {
      font-size: 2rem;
    }
    @media #{$md-and-up} {
      margin-bottom: 2rem;
    }
  }
  &__image {
    width: 100%;
    margin-top: 2.5rem;
    @media #{$sm-and-up} {
      grid-column-start: 2;
      grid-row: 1 / -1;
      margin-top: 5rem;
    }
  }
  &__description {
    white-space: pre-line;
  }
  &__list {
    font-size: 1.5rem;
  }
  &__list,
  &__description {
    font-size: 1.25rem;
    line-height: 1.4;
    @media #{$sm-and-up} {
      font-size: 1.5rem;
    }
  }
  &--big-image &__image {
    width: 100vw;
    max-height: 800px;
    object-fit: cover;
    position: relative;
    border-radius: 0 !important;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  &--two-columns {
    display: grid;
    grid-template-columns: 1fr;
    @media #{$sm-and-up} {
      grid-template-columns: 1fr 40%;
      grid-template-rows: max-content 1fr;
      column-gap: 1.5rem;
    }
    @media #{$md-and-up} {
      grid-template-rows: 50% 50%;
    }
  }
  &--two-columns &__title {
    align-self: flex-end;
    margin-bottom: 0.625rem;
    @media #{$md-and-up} {
      margin-bottom: 1.125rem;
    }
  }
  &--two-columns &__image {
    margin-top: 2rem;
    @media #{$sm-and-up} {
      margin-top: unset;
    }
  }
}
</style>
