<template>
  <div class="advantage">
    <nuxt-img
      class="advantage__image rounded-lg mb-5 mb-sm-6"
      :src="card.src"
      :alt="card.alt"
      format="webp"
      sizes="sm:100vw md:30vw lg:336px"
      loading="lazy"
    />
    <h3 class="text-lp-h3 basic-black--text mb-2 mb-sm-3">{{ card.title }}</h3>
    <div class="text-lp-body-2 dark-grey--text">{{ card.description }}</div>
  </div>
</template>

<script>
export default {
  name: 'Advantage',
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.advantage {
  &__image {
    width: 100%;
    aspect-ratio: 305/289;
  }
}
</style>
