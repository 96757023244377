<template>
  <div class="abstract light rounded-lg">
    <div class="abstract__title d-flex align-center mb-8">
      <fa-icon icon="bookmark" class="abstract__icon primary--text mr-5" />
      <span class="font-weight-black">
        {{ $t('global.whatIsArticleAbout') }}
      </span>
    </div>
    <ul class="abstract__list dark-grey--text">
      <li v-for="item in abstract" :key="item">{{ item }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('blog', ['abstract']),
  },
};
</script>

<style lang="scss" scoped>
.abstract {
  padding: 3.125rem 1rem;
  @media #{$sm-and-up} {
    padding: 3.25rem;
  }
  &__title {
    font-size: 1.125rem;
    line-height: 1.4;
  }
  &__icon {
    height: 2rem;
  }
  &__list {
    font-size: 0.875rem;
    line-height: 1.4;
    max-width: 478px;
    display: grid;
    gap: 1rem;
    @media #{$sm-and-up} {
      font-size: 1.125rem;
      line-height: 1.4;
      margin-left: 2rem;
      gap: 2rem;
    }
  }
}
</style>
