<template>
  <div class="login">
    <LoginNavigation>
      <nuxt-link
        class="d-flex align-center"
        :to="{ path: '/', query: { ...$route.query } }"
      >
        <svg-icon
          class="primary--text"
          name="tap2link-logo"
          width="54"
          height="25"
        />
      </nuxt-link>
      <div class="d-flex align-center">
        <p class="text-body-2 mr-3">{{ $t('login.noAccount') }}</p>
        <Button
          height="44"
          class="button--rounded button--secondary text-button-2"
          @click="routerPushWithQuery('/register')"
        >
          {{ $t('login.registerNow') }}
        </Button>
      </div>
    </LoginNavigation>
    <main class="login__content d-flex flex-column container">
      <div v-show="custom" class="login__header">
        <div class="d-flex flex-column justify-center">
          <h1 class="text-h1 primary-dark--text">
            {{ $t('login.login') }}
          </h1>
          <p class="text-body-2 dark-grey--text mb-2">
            {{ $t('login.signUpAccount') }}
          </p>
        </div>
        <v-img
          :src="customLogo"
          class="ml-auto rounded-lg"
          width="68"
          max-height="68"
          :alt="`${customCompanyName}logo`"
        />
      </div>
      <h1 v-show="!custom" class="text-h1 primary-dark--text">
        {{ $t('login.login') }}
      </h1>
      <p v-show="!custom" class="text-body-2 dark-grey--text mb-2">
        {{ $t('login.signUpAccount') }}
      </p>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <v-form
          class="login__form d-flex flex-column"
          @submit.prevent="handleSubmit(handleLogin)"
        >
          <CompactInput
            v-model="loginOrEmail.value"
            :label="loginOrEmail.label"
            :placeholder="
              $t('login.insertPlaceholder', { inputLabel: loginOrEmail.label })
            "
            autocomplete="email"
            rules="required|email"
          />
          <CompactInput
            v-model="password.value"
            :label="password.label"
            :placeholder="
              $t('login.insertPlaceholder', { inputLabel: password.label })
            "
            autocomplete="current-password"
            type="password"
            rules="required"
          />
          <Button
            width="100%"
            height="64"
            :loading="loading"
            color="primary"
            class="login__btn button--primary"
            type="submit"
          >
            {{ $t('login.login') }}
          </Button>
        </v-form>
      </ValidationObserver>
      <nuxt-link
        class="text-body-1-link primary--text align-self-center"
        :to="{ path: '/password-recovery/request', query: { ...$route.query } }"
      >
        {{ $t('login.forgotPassword') }}
      </nuxt-link>
    </main>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';

import LoginNavigation from '~/components/shared/AppNavigation.vue';
import Button from '~/components/shared/Button.vue';
import CompactInput from '~/components/shared/Compact.vue';
import isAuth from '~/middleware/isAuth';
import customTheme from '~/mixins/customTheme';
import vhOnMobile from '~/mixins/vhOnMobile';

export default {
  name: 'LoginPage',
  components: {
    Button,
    CompactInput,
    LoginNavigation,
    ValidationObserver,
  },
  mixins: [customTheme, vhOnMobile],
  layout: 'app',
  middleware: [isAuth],
  data() {
    return {
      loginOrEmail: {
        value: '',
        label: this.$t('login.loginOrEmail'),
      },
      password: {
        value: '',
        label: this.$t('login.password'),
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['loading']),
  },
  methods: {
    ...mapActions('auth', ['signInUser']),
    handleLogin() {
      this.signInUser({
        username: this.loginOrEmail.value,
        password: this.password.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  &__content {
    margin-top: 8rem;
    gap: 1rem;
  }
  &__btn {
    border-radius: 16px !important;
    &::v-deep {
      .v-btn__content {
        line-height: 1.55;
      }
    }
  }
  &__form {
    gap: 1rem;
  }
}
</style>
