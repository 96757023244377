<template>
  <header class="card mb-4">
    <div
      :style="{
        background: backgroundStyle,
      }"
      class="card__container d-flex justify-center"
      :class="isProduct && 'card__container--product align-end'"
    >
      <div class="d-flex flex-column align-center">
        <Avatar
          v-if="!isProduct"
          :avatar-image="photoUrl"
          :profile-full-name="profile.name"
          :profile-type="profile.profileType"
          :organization-url="!!customThemeId"
          class-variant="avatar--profile"
        />
        <div class="text-center">
          <h1 class="card__position text-h1 light--text mt-7 mb-2">
            {{ profile.name }}
          </h1>
          <p
            v-if="isChristkindlmarkt"
            class="card__position text-gray--text pb-6"
          >
            {{ memberTitle }}
          </p>
          <p v-else class="card__position text-gray--text pb-6">
            <fa-icon
              v-if="userMembership.iconName"
              :icon="userMembership.iconName"
            />
            {{ memberTitle }}
          </p>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import isEmpty from 'lodash-es/isEmpty';
import { mapGetters } from 'vuex';

import Avatar from '~/components/atoms/Avatar.vue';
import { MEMBER_TITLE, PROFILE_TYPE } from '~/constants';
import { getStorageUrl } from '~/helpers';

export default {
  name: 'Header',
  components: { Avatar },
  computed: {
    ...mapGetters('profile', [
      'profile',
      'backgroundPhotoUrl',
      'photoUrl',
      'isPersonal',
      'isProduct',
      'isCompany',
      'currentProfileOrganization',
      'customThemeId',
    ]),
    isChristkindlmarkt() {
      return this.$route.params?.organization === 'Christkindlmarkt.club';
    },
    backgroundStyle() {
      if (this.isProduct)
        return `linear-gradient(180deg, rgba(1, 3, 11, 0) 62.5%, rgba(1, 3, 12, 0.81) 92.71%), url(${getStorageUrl(
          this.profile?.photoName,
        )}) center`;
      if (
        this.currentProfileOrganization?.organization?.settings?.personalizedBg
      ) {
        return `linear-gradient(180deg, rgba(1, 3, 11, 0) 62.5%, rgba(1, 3, 12, 0.81) 92.71%), url(${getStorageUrl(
          this.profile?.backgroundPhotoName,
        )}) center`;
      }
      if (this.backgroundPhotoUrl) {
        return `linear-gradient(180deg, rgba(1, 3, 11, 0) 62.5%, rgba(1, 3, 12, 0.81) 92.71%), url(${this.backgroundPhotoUrl}) center`;
      }
      return '';
    },
    userMembership() {
      return this.currentProfileOrganization?.membershipType ?? {};
    },
    memberTitle() {
      if (this.isChristkindlmarkt) {
        return this.profile?.position;
      }
      if (this.isProduct) {
        return this.profile.position;
      }
      const { profile, settings } =
        this.currentProfileOrganization?.organization || {};
      if (profile?.profileType === PROFILE_TYPE.COMPANY) {
        if (settings?.memberTitle === MEMBER_TITLE.WORK_POSITION) {
          return this.profile.position;
        }
        return this.userMembership.membershipName;
      }
      if (isEmpty(this.userMembership)) return this.profile.position;
      return this.userMembership?.membershipName;
    },
  },
  methods: {
    isEmpty,
  },
};
</script>

<style lang="scss" scoped>
.card {
  &__container {
    position: relative;
    margin: 0 auto;
    padding-top: 5.9375rem;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background: $profile-placeholder-gradient;
    aspect-ratio: 1/1;
    @media #{$small} {
      width: 640px;
      height: 400px;
      padding-top: 7.8125rem;
      margin-top: 3.75rem;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    &--product {
      padding-top: 15.31rem;
    }
  }
}
</style>
