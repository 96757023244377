<template>
  <section id="how-it-works" class="works">
    <div class="works__container container">
      <div class="container--internal">
        <h2 class="works__title text-lp-h2 basic-black--text text-center">
          {{ $t('howItWorks.howItWorks') }}
        </h2>
        <div class="works__content">
          <WorkItem
            v-for="item in workItems"
            :key="item.id"
            :alt="item.alt"
            :description="item.description"
            :src="item.src"
            :srcset="item.srcset"
            :title="item.title"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WorkItem from '~/components/atoms/WorkItem';
import howItWorks from '~/mixins/howItWorks';

export default {
  name: 'HowItWorks',
  components: { WorkItem },
  mixins: [howItWorks],
};
</script>

<style scoped lang="scss">
.works {
  background-color: var(--v-light-grey-base);
  @media #{$sm-and-up} {
    background-color: transparent;
  }
  &__container {
    padding-top: clamp(2.5rem, 8vw, 5rem) !important;
    padding-bottom: clamp(2.5rem, 8vw, 5rem) !important;
    @media #{$sm-and-up} {
      background-color: var(--v-light-grey-base);
    }
    @media #{$container-width} {
      border-radius: 8px;
    }
  }
  &__title {
    margin-bottom: clamp(2.5rem, 7vw, 5rem);
  }
  &__content {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: clamp(2.5rem, 6vw, 5rem);
  }
}
</style>
