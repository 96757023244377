<template>
  <div class="card-link light">
    <a
      v-if="externalLink"
      :href="createExternalLink(externalLink)"
      :target="target"
      class="card-link__link d-flex justify-space-between align-center text-decoration-none py-3 px-4"
    >
      <div class="card-link__content primary-dark--text">
        <slot />
      </div>
      <div class="d-flex">
        <fa-icon
          v-if="isPrivate"
          icon="lock"
          class="card-link__icon text-gray--text ml-2"
        />
        <fa-icon
          v-if="!isPrivate && isProfileOwner"
          icon="lock-open"
          class="card-link__icon text-gray--text ml-2"
        />
        <fa-icon
          v-if="!noIcon"
          icon="chevron-right"
          class="card-link__icon text-gray--text ml-2"
        />
      </div>
    </a>
    <nuxt-link
      v-if="internalLink"
      :to="internalLink"
      class="card-link__link d-flex justify-space-between align-center text-decoration-none py-3 px-4"
    >
      <div class="card-link__content primary-dark--text">
        <slot />
      </div>
      <fa-icon
        v-if="!noIcon"
        icon="chevron-right"
        class="card-link__icon text-gray--text ml-2"
      />
    </nuxt-link>
    <div
      v-if="!internalLink && !externalLink"
      class="card-link__link d-flex justify-space-between align-center text-decoration-none py-3 px-4"
    >
      <div class="card-link__content">
        <slot />
      </div>
      <div class="d-flex">
        <fa-icon
          v-if="isPrivate"
          icon="lock"
          class="card-link__icon text-gray--text ml-2"
        />
        <fa-icon
          v-if="!isPrivate && isProfileOwner"
          icon="lock-open"
          class="card-link__icon text-gray--text ml-2"
        />
        <fa-icon
          v-if="!noIcon"
          icon="chevron-right"
          class="card-link__icon text-gray--text ml-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { createExternalLink } from '~/helpers';

export default {
  name: 'CardLink',
  props: {
    noIcon: {
      type: Boolean,
      default: false,
    },
    externalLink: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
    internalLink: {
      type: String,
      default: '',
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('profile', ['isProfileOwner']),
  },
  methods: {
    createExternalLink,
  },
};
</script>

<style lang="scss" scoped>
.card-link {
  border-radius: 12px;
  gap: 0.5rem;
  height: 63px;
  &:hover {
    background-color: var(--v-light-2-base) !important;
    .card-link__icon {
      color: var(--v-dark-grey-base) !important;
    }
  }
  &:active {
    .card-link__icon {
      color: var(--v-primary-base) !important;
    }
  }
  @supports not ((scroll-behavior: smooth) or (gap: 20px)) {
    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  &__icon {
    max-height: 12px;
    max-width: 12px;
  }
  &__link {
    height: 100%;
  }
  &__content a {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__content,
  &__link ::v-deep &__content {
    display: grid;
    grid-template-columns: max-content 1fr;
    justify-items: center;
    align-items: center;
    gap: 0.5rem;
    > *:last-child {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &--custom {
    background-color: var(--v-custom-link-base) !important;
    .card-link__icon {
      color: var(--v-primary-base) !important;
    }
    &:hover {
      background-color: var(--v-custom-link-hover-base) !important;
      .card-link__icon {
        color: var(--v-primary-base) !important;
      }
    }
    &:active {
      background-color: var(--v-custom-link-active-base) !important;
    }
  }
  &--contact,
  &--settings {
    &:hover {
      background-color: var(--v-custom-link-base) !important;
      .card-link__icon {
        color: var(--v-text-gray-base) !important;
      }
    }
    &:active {
      background-color: var(--v-custom-link-hover-base) !important;
      .card-link__icon {
        color: var(--v-text-gray-base) !important;
      }
    }
  }
  &--owner {
    background-color: var(--v-confirmation-bg-base) !important;
    color: var(--v-green-confirmation-base) !important;
    &:hover {
      background-color: var(--v-confirmation-bg-hover-base) !important;
    }
    &:active {
      background-color: var(--v-confirmation-bg-active-base) !important;
    }
  }
  &--member {
    background-color: var(--v-light-main-base) !important;
    color: var(--v-primary-base) !important;
    &:hover {
      background-color: var(--v-light-hover-base) !important;
    }
    &:active {
      background-color: var(--v-primary-base) !important;
      color: var(--v-white-base) !important;
    }
  }
}
</style>
