<template>
  <section class="eula">
    <div class="eula__container container px-md-0">
      <h3 class="eula__title mb-6">
        {{ $t('eula.eulaTitle') }}
      </h3>
      <h2 class="eula__subtitle">
        {{ $t('eula.eulaSubtitle') }}
      </h2>
      <h2 class="text-body-2 mb-10">{{ $t('eula.lastUpdated') }}</h2>
      <div class="eula__content text-body-2">
        <p>{{ $t('eula.eulaNotice') }}</p>
        <p v-for="number in 4" :key="number">
          <span>{{ $t(`eula.eulaNotice${number}`) }}</span>
        </p>
        <h2 class="eula__subtitle">{{ $t('eula.tableOfContents') }}</h2>
        <ol>
          <li v-for="number in 16" :key="number">
            <span>{{ $t(`eula.${number}`) }}</span>
          </li>
        </ol>
        <h2 class="eula__subtitle">1. {{ $t('eula.1') }}</h2>
        <p>{{ $t('eula.1_1') }}</p>
        <p>{{ $t('eula.1_2') }}</p>
        <h2 class="eula__subtitle">2. {{ $t('eula.2') }}</h2>
        <p v-for="number in 8" :key="number">
          {{ $t(`eula.2_${number}`) }}
        </p>
        <h2 class="eula__subtitle">3. {{ $t('eula.3') }}</h2>
        <p v-for="number in 4" :key="number">
          {{ $t(`eula.3_${number}`) }}
        </p>
        <h2 class="eula__subtitle">4. {{ $t('eula.4') }}</h2>
        <p v-for="number in 2" :key="number">
          {{ $t(`eula.4_${number}`) }}
        </p>
        <h2 class="eula__subtitle">5. {{ $t('eula.5') }}</h2>
        <p>{{ $t('eula.5_1') }}</p>
        <ol>
          <li v-for="number in 13" :key="number">
            <span>{{ $t(`eula.5_1_${number}`) }}</span>
          </li>
        </ol>
        <p>{{ $t('eula.5_2') }}</p>
        <h2 class="eula__subtitle">6. {{ $t('eula.6') }}</h2>
        <p v-for="number in 4" :key="number">
          {{ $t(`eula.6_${number}`) }}
        </p>
        <h2 class="eula__subtitle">7. {{ $t('eula.7') }}</h2>
        <p>{{ $t('eula.7_1') }}</p>
        <h2 class="eula__subtitle">8. {{ $t('eula.8') }}</h2>
        <p v-for="number in 6" :key="number">
          {{ $t(`eula.8_${number}`) }}
        </p>
        <h2 class="eula__subtitle">9. {{ $t('eula.9') }}</h2>
        <ul>
          <li v-for="number in 3" :key="number">
            <span>{{ $t(`eula.9_1_${number}`) }}</span>
          </li>
        </ul>
        <template v-for="number in 3">
          <h2 :key="number" class="eula__subtitle">
            {{ `${number + 9}. ` }}
            {{ $t(`eula.${number + 9}`) }}
          </h2>
          <p :key="`${number + 9}_p`">{{ $t(`eula.${number + 9}_1`) }}</p>
        </template>
        <h2 class="eula__subtitle">13. {{ $t('eula.13') }}</h2>
        <p v-for="number in 2" :key="number">
          {{ $t(`eula.13_${number}`) }}
        </p>
        <template v-for="number in 2">
          <h2 :key="number" class="eula__subtitle">
            {{ `${number + 13}. ` }}
            {{ $t(`eula.${number + 13}`) }}
          </h2>
          <p :key="`${number + 13}_p`">{{ $t(`eula.${number + 13}_1`) }}</p>
        </template>
        <h2 class="eula__subtitle">16. {{ $t('eula.16') }}</h2>
        <p v-for="number in 2" :key="number">
          {{ $t(`eula.16_${number}`) }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'endUserLicence',
};
</script>

<style lang="scss" scoped>
.eula {
  overflow-y: scroll;
  max-height: 50vh;
  &__container {
    max-width: 790px;
  }
  &__content {
    display: grid;
    gap: 1.125rem;
  }
  &__subtitle,
  &__title {
    color: var(--v-primary-dark-base);
  }
  &__link {
    word-break: break-all;
  }
  &--no-scrollbar {
    overflow-y: unset;
    max-height: unset;
  }
}
p {
  margin-bottom: 0 !important;
}
.pre-line {
  white-space: pre-line;
}
</style>
