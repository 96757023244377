<template>
  <div class="form__container">
    <ValidationProvider
      v-slot="{ errors }"
      class="form__control"
      :skip-if-empty="false"
      :rules="rules"
      :name="name"
    >
      <div v-if="label" class="form__label text-body-2 text-dark--text mb-2">
        {{ `${label}${rules.includes('required') && '*'}` }}
      </div>
      <v-checkbox
        v-model="model"
        :color="color"
        :label="label"
        class="form__checkbox mt-0 pt-0"
        :disabled="disabled"
        :false-value="null"
        :true-value="true"
      >
        <template v-slot:label>
          <slot />
          <span class="form__label text-body-2 text-gray--text">
            {{ text }}
            <slot name="link" />
          </span>
        </template>
      </v-checkbox>
      <span
        v-if="errors.length"
        class="form-error text-body-2 d-inline-block error-red--text mt-3"
        :class="errorMessageClass"
      >
        {{ errors[0] }}
      </span>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessageClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value ? value : null);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form__container {
  max-width: max-content;
}
.form__checkbox {
  ::v-deep {
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input--selection-controls__input {
      align-self: flex-start;
    }
    .v-label {
      top: 1px;
    }
  }
}
</style>
