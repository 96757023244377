<template>
  <v-app dark class="app">
    <FlashMessage />
    <nuxt />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';

import checkAffiliateLinks from '~/middleware/checkAffiliateLinks';
import tabsBroadcast from '~/mixins/tabsBroadcast';

export default {
  components: {
    FlashMessage: () => import('~/components/shared/FlashMessage.vue'),
  },
  mixins: [tabsBroadcast],
  middleware: [checkAffiliateLinks],
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  async created() {
    if (this.$apolloHelpers.getToken()) {
      await this.fetchUserProfiles();
    }
  },
  methods: {
    ...mapActions('profile', ['fetchUserProfiles']),
  },
};
</script>
<style lang="scss" scoped>
.app {
  min-height: 100vh;
  padding-top: 0 !important;
  background-color: var(--v-background-base);
  ::v-deep {
    .v-application--wrap {
      overflow: hidden;
      min-height: unset;
    }

    .container {
      width: 100%;
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      max-width: 672px !important;
      @media #{$small} {
        max-width: 640px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}
</style>
