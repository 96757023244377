<template>
  <section id="your-data" class="data">
    <div class="data__container container">
      <div class="data__container--internal container--internal">
        <div class="data__content">
          <svg-icon
            name="carbon_data-check"
            width="140"
            height="140"
            class="data__icon mb-6 mb-sm-0"
          />
          <h3
            class="data__title text-lp-h3 basic-black--text text-center text-sm-left mb-3 mb-sm-0"
          >
            {{ $t('yourData.yourData') }}
          </h3>
          <p
            class="data__description text-lp-body-2 dark-grey--text text-center text-sm-left"
          >
            {{ $t('yourData.youHaveControl') }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'YourData',
};
</script>

<style scoped lang="scss">
.data {
  background-color: var(--v-light-grey-base);
  @media #{$sm-and-up} {
    background-color: transparent;
  }
  &__container {
    @media #{$md-and-up} {
      padding-top: clamp(2.5rem, 8vw, 5rem) !important;
      padding-bottom: clamp(2.5rem, 8vw, 5rem) !important;
      background-color: var(--v-light-grey-base);
    }
    &--internal {
      padding: clamp(2.5rem, 8vw, 5rem) clamp(2.5rem, 7vw, 5rem) !important;
      background-color: var(--v-light-grey-base);
      @media #{$md-and-up} {
        padding: 0 !important;
      }
    }
  }
  &__content {
    display: grid;
    justify-items: center;
    grid-template-areas: 'icon' 'title' 'description';
    @media #{$sm-and-up} {
      justify-items: start;
      grid-template-rows: max-content minmax(0, 1fr);
      grid-template-areas: 'icon title' 'icon description';
      gap: 1rem 4rem;
    }
  }
  &__icon {
    grid-area: icon;
  }
  &__title {
    grid-area: title;
  }
  &__description {
    grid-area: description;
  }
}
</style>
