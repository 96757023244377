<template>
  <nuxt-link
    class="button text-lp-body-2 px-4"
    :to="{ path: to, query: { ...this.$route.query } }"
  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  name: 'NuxtLinkButton',
  props: {
    to: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  display: grid;
  place-items: center;
  text-decoration: none !important;
  box-shadow: none;
  height: 3rem;
  min-width: min-content !important;
  text-transform: inherit;
  border-radius: 6px;
  &__icon--prepend {
    height: 12px;
  }
  &::before {
    display: none;
  }
  &:active {
    box-shadow: none;
  }
  &--square {
    padding: 0 !important;
  }
  &--delete {
    background-color: var(--v-error-red-light-base) !important;
    color: var(--v-error-red-base) !important;
    &:hover {
      background-color: var(--v-error-red-light-hover-base) !important;
    }
    &:active {
      background-color: var(--v-error-red-light-active-base) !important;
    }
  }
  &--primary {
    background-color: var(--v-primary-base) !important;
    color: var(--v-light-base) !important;
    &:hover {
      background-color: var(--v-primary-hover-base) !important;
    }
    &:active {
      background-color: var(--v-primary-active-base) !important;
    }
  }
  &--secondary {
    background-color: var(--v-light-main-base) !important;
    color: var(--v-primary-base) !important;
    &:hover {
      background-color: var(--v-light-hover-base) !important;
    }
    &:active {
      background-color: var(--v-primary-base) !important;
      color: var(--v-white-base) !important;
    }
  }
  &--light {
    background-color: var(--v-light-main-base) !important;
    color: var(--v-primary-base) !important;
    &::v-deep {
      .v-btn__content {
        font-weight: bold;
      }
    }
    &:hover {
      background-color: var(--v-light-base) !important;
    }
    &:active {
      background-color: var(--v-custom-link-base) !important;
    }
  }
  &--profile-primary {
    background-color: var(--v-light-base) !important;
    color: var(--v-primary-dark-base) !important;
    border-radius: 8px !important;
    &::v-deep {
      .v-btn__content {
        font-weight: bold;
      }
    }
    &:hover {
      border: 1px solid var(--v-button-hover-gray-base) !important;
    }
    &:active {
      border: 1px solid var(--v-primary-base) !important;
    }
  }
  &--profile-secondary {
    background-color: var(--v-white-base) !important;
    color: var(--v-dark-grey-base) !important;
    border-radius: 8px;
    border: 1px solid var(--v-button-gray-base) !important;
    &:hover {
      border: 1px solid var(--v-button-hover-gray-base) !important;
    }
    &:active {
      border: 1px solid var(--v-primary-base) !important;
    }
  }
  &--rounded {
    border-radius: 8px !important;
  }
  &--expanded {
    grid-column: 1 / span 2;
  }
  &--outlined {
    border: 1px solid var(--v-primary-dark-base) !important;
    color: var(--v-primary-dark-base) !important;
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--dark {
    font-weight: normal !important;
    &:active,
    &:hover {
      background-color: var(--v-light-base) !important;
      color: var(--v-primary-dark-base) !important;
    }
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--lp {
    background-color: var(--v-basic-black-base) !important;
    color: var(--v-white-base) !important;
    border: 2px solid var(--v-basic-black-base) !important;
    &:hover,
    &:active {
      background-color: var(--v-white-base) !important;
      color: var(--v-basic-black-base) !important;
      border: 2px solid var(--v-basic-black-base) !important;
    }
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--lp-light {
    background-color: var(--v-white-base) !important;
    color: var(--v-basic-blac-base) !important;
    border: 2px solid var(--v-basic-black-base) !important;
    &:hover,
    &:active {
      background-color: var(--v-basic-black-base) !important;
      color: var(--v-white-base) !important;
    }
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--cream {
    border: 1px solid var(--v-cream-base) !important;
    color: var(--v-cream-base) !important;
    &:hover,
    &:active {
      color: var(--v-basic-black-base) !important;
      background-color: var(--v-cream-base) !important;
    }
    &:hover {
      box-shadow: $small-btn-shadow;
    }
  }
  &--brown {
    border: none !important;
    border-radius: 11px !important;
    background-color: var(--v-cream-base) !important;
    color: var(--v-basic-black-base) !important;
    &:hover,
    &:active {
      background-color: #e8cf92 !important;
    }
    &:active {
      border: solid 2px black !important;
      box-sizing: border-box;
    }
  }
}
</style>
