<template>
  <v-menu
    class="switch rounded-xl"
    :class="colorClass"
    nudge-left="0"
    nudge-bottom="40"
  >
    <template v-slot:activator="{ on }">
      <div class="switch__selected" v-on="on" @click="isOpen = !isOpen">
        <nuxt-img
          :src="`/images/${activeLang || 'de'}.png`"
          alt="Language switcher"
          class="switch__img"
          loading="eager"
          format="webp"
        />
        <fa-icon
          :icon="isOpen ? 'chevron-up' : 'chevron-down'"
          :class="colorClass"
          width="15"
          height="15"
        />
      </div>
    </template>
    <div class="d-flex flex-column white pa-2">
      <div
        v-for="locale in locales"
        :key="locale.id"
        class="switch__item pa-2"
        @click="$i18n.setLocale(locale.code)"
      >
        {{ locale.name }}
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  props: {
    colorClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    locales() {
      return [
        {
          id: 1,
          code: 'en',
          name: this.$t('global.english'),
        },
        {
          id: 2,
          code: 'de',
          name: this.$t('global.german'),
        },
      ];
    },
    activeLang() {
      return this.locales.find(
        (locale) => locale.code === this.$i18n.getLocaleCookie(),
      )?.code;
    },
  },
};
</script>
<style lang="scss" scoped>
.switch {
  position: relative;
  border-radius: 6px;
  z-index: 999;
  &__selected {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 0.5rem;
    align-items: center;
  }
  &__img {
    width: 34px;
    height: 34px;
  }
  &__item {
    cursor: pointer;
    transition: $cubic-bezier;
    &:hover {
      background-color: var(--v-light-grey-base);
    }
  }
}
.v-menu__content {
  border-radius: 6px !important;
}
</style>
