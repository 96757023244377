<template>
  <client-only>
    <Dialog
      :value="!!identifier_int"
      :title="$t('global.connectNewCard')"
      icon="address-card"
      @closeDrawer="identifier_int = null"
    >
      <Fragment v-if="step === 1">
        <p class="new-card__title primary-dark--text font-weight-bold">
          {{ userFullName }}
        </p>
        <p class="text-gray--text">
          {{ $t('global.newPhysicalCardDetected') }}
        </p>
      </Fragment>
      <Fragment v-if="step === 2">
        <p class="new-card__title primary-dark--text font-weight-bold">
          {{ userFullName }}
        </p>
        <p class="text-gray--text">
          {{ $t('global.newPhysicalCardDetected') }}
        </p>
        <div class="new-card__content px-1">
          <PreviewCard
            v-for="profile in personalProfiles"
            :key="profile.id"
            :profile="profile"
            :profile-type="profile.profileType"
            :class="{ 'card--selected': profile.id === selectedProfileId }"
            @profileSelected="selectProfile"
          />
          <Button
            width="100%"
            height="64"
            class="button--primary button--rounded"
            @click="createCardWithConnection"
          >
            <fa-icon icon="plus-circle" class="mr-2" />
            {{ $t('global.createANewOne') }}
          </Button>
        </div>
      </Fragment>
      <template v-slot:buttons>
        <Button
          width="100%"
          height="48"
          class="button--secondary button--rounded"
          @click="identifier_int = null"
        >
          {{ $t('global.discard') }}
        </Button>
        <Fragment v-if="step === 1">
          <Button
            v-if="userHavePersonalProfiles"
            width="100%"
            height="48"
            class="button--primary button--rounded"
            @click="step = 2"
          >
            <fa-icon icon="link" class="mr-2" />
            {{ $t('global.connect') }}
          </Button>
          <Button
            v-else
            width="100%"
            height="48"
            class="button--primary button--rounded"
            @click="createCardWithConnection"
          >
            <fa-icon icon="plus-circle" class="mr-2" />
            {{ $t('global.createCard') }}
          </Button>
        </Fragment>
        <Fragment v-if="step === 2">
          <Button
            v-if="userHavePersonalProfiles"
            width="100%"
            height="48"
            class="button--primary button--rounded"
            :disabled="!selectedProfileId"
            @click="connectCardToProfile"
          >
            {{ $t('global.connectCard') }}
          </Button>
        </Fragment>
      </template>
    </Dialog>
  </client-only>
</template>

<script>
import Cookie from 'js-cookie';
import { Fragment } from 'vue-frag';
import { mapActions, mapGetters } from 'vuex';

import Dialog from '~/components/Dialog.vue';
import PreviewCard from '~/components/PreviewCard.vue';
import Button from '~/components/shared/Button.vue';
import { COOKIE_TYPE } from '~/constants';
export default {
  name: 'NewCardDialog',
  components: {
    Fragment,
    PreviewCard,
    Dialog,
    Button,
  },
  props: {
    redirectUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInformationOverlay: false,
      listenerId: null,
      identifier_int: null,
      selectedProfileId: null,
      step: 1,
    };
  },
  computed: {
    ...mapGetters('auth', ['userData']),
    ...mapGetters('profile', ['personalProfiles']),
    userFullName() {
      return `${this.userData?.firstName} ${this.userData?.lastName}`;
    },
    userHavePersonalProfiles() {
      return !!this.personalProfiles?.length;
    },
  },
  watch: {
    identifier_int(newValue) {
      if (newValue) {
        this.clearListener();
      } else {
        this.startListener();
      }
    },
  },
  created() {
    this.startListener();
  },
  destroyed() {
    this.clearListener();
  },
  methods: {
    ...mapActions('profile', ['handleCardAction']),
    connectCardToProfile() {
      if (this.selectedProfileId) {
        this.handleCardAction({
          profileId: this.selectedProfileId,
          identifier_int: this.identifier_int,
          title: this.userFullName,
          id: null,
        });
        this.identifier_int = null;
      }
    },
    selectProfile(id) {
      this.selectedProfileId = id;
    },
    startListener() {
      this.listenerId = setInterval(() => {
        const identifier_int = Cookie.get(COOKIE_TYPE.NEW_CARD_ID);
        if (identifier_int) {
          if (!this.$apolloHelpers.getToken()) {
            Cookie.set(COOKIE_TYPE.REDIRECT_CARD_ID, identifier_int);
            Cookie.remove(COOKIE_TYPE.NEW_CARD_ID);
            return;
          }
          this.identifier_int = identifier_int;
          this.$emit('input', true);
        }
      }, 750);
    },
    clearListener() {
      this.step = 1;
      if (this.listenerId) {
        Cookie.remove(COOKIE_TYPE.NEW_CARD_ID);
        clearInterval(this.listenerId);
        this.listenerId = null;
      }
    },
    createCardWithConnection() {
      Cookie.set(COOKIE_TYPE.REDIRECT_CARD_ID, this.identifier_int);
      window.location.href = `${process.env.baseUrl}/app/profile/create`;
    },
  },
};
</script>
<style lang="scss" scoped>
.new-card {
  &__content {
    display: grid;
    gap: 0.75rem;
    max-height: 200px;
    overflow-y: scroll;
    @media #{$sm-and-up} {
      max-height: 300px;
    }
  }
  &__title {
    font-size: 1.375rem;
  }
}
</style>
