<template>
  <main class="legal container">
    <TermsAndConditions class="terms--legal" />
  </main>
</template>

<script>
import TermsAndConditions from '~/components/terms';
export default {
  name: 'TermsOfUse',
  components: {
    TermsAndConditions,
  },
};
</script>

<style lang="scss" scoped>
.legal {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
</style>
