<template>
  <section
    class="about"
    v-if="
      profileDescriptionData.description ||
      profileDescriptionData.descriptionTitle
    "
  >
    <div class="about__content light pa-4">
      <p
        v-if="profileDescriptionData.descriptionTitle"
        class="about__title text-h2 primary-dark--text mb-3"
      >
        {{ profileDescriptionData.descriptionTitle }}
      </p>
      <p
        v-if="profileDescriptionData.description"
        v-resize="checkContentOverflow"
        class="about__description text-body-1 dark-grey--text"
        :class="{
          'about__description--open': showFullDescription,
        }"
      >
        <span id="description-content">{{
          profileDescriptionData.description
        }}</span>
      </p>
      <a
        v-if="showMoreButtonDisplay"
        class="about__expander d-inline-block mt-6"
        @click.prevent="toggleDescription"
      >
        <fa-icon
          :icon="showFullDescription ? 'chevron-up' : 'chevron-down'"
          class="primary--text mr-1"
        />
        {{
          showFullDescription ? $t('global.showLess') : $t('global.readMore')
        }}
      </a>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { ABOUT_SECTION, PROFILE_TYPE } from '~/constants';

export default {
  name: 'About',
  data() {
    return {
      showFullDescription: false,
      showMoreButtonDisplay: false,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'profile',
      'currentOrganizationProfile',
      'staffSettings',
    ]),
    profileDescriptionData() {
      if (
        this.profile.profileType !== PROFILE_TYPE.PRODUCT &&
        this.staffSettings?.aboutSection &&
        this.staffSettings?.aboutContent === ABOUT_SECTION.COMPANY_PROFILE
      ) {
        return {
          descriptionTitle: this.currentOrganizationProfile.descriptionTitle,
          description: this.currentOrganizationProfile.description,
        };
      }
      return {
        descriptionTitle: this.profile.descriptionTitle,
        description: this.profile.description,
      };
    },
  },
  methods: {
    checkContentOverflow() {
      const descWrapper = document.querySelector('.about__description');
      const descContent = document.querySelector('#description-content');
      this.showMoreButtonDisplay =
        descContent?.offsetHeight > descWrapper?.offsetHeight + 5;
      this.showFullDescription = false;
    },
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  &__content {
    border-radius: 12px;
  }
  &__description {
    max-height: 110px;
    overflow: hidden;
    white-space: pre-line;
    &--open {
      max-height: unset;
    }
  }
}
</style>
