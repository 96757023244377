<template>
  <div>
    <div class="ml-8">{{ url }}</div>
    <Button
      height="48"
      class="button--rounded button--primary px-10 ma-8"
      @click="getVcard"
    >
      Get vCard
    </Button>
  </div>
</template>

<script>
import Button from '~/components/shared/Button.vue';

export default {
  name: 'cvfTest',
  components: { Button },
  data() {
    return {
      url: 'https://storage.googleapis.com/tap2link-dev-storage/test.vcf',
    };
  },
  methods: {
    isChrome() {
      return navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    },
    isSafari() {
      return navigator.userAgent.toLowerCase().indexOf('safari') > -1;
    },
    async getVcard() {
      if (this.isChrome() || this.isSafari()) {
        const link = document.createElement('a');
        link.href = this.url;
        if (link.download !== undefined) {
          const fileName = this.url.substring(
            this.url.lastIndexOf('/') + 1,
            this.url.length,
          );
          link.download = fileName;
        }
        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });
        link.dispatchEvent(event);
        return true;
      }
      if (this.url.indexOf('?') === -1) {
        this.url += '?download';
        return;
      }

      window.open(this.url);
      return true;
    },
  },
};
</script>
