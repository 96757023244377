<template>
  <div />
</template>

<script>
import profileMixin from '~/mixins/profileMixin';

export default {
  name: 'CompanyMemberProfile',
  mixins: [profileMixin],
  layout: 'member',
};
</script>
