<template>
  <div
    class="confirm d-flex flex-column justify-center align-center text-center"
  >
    <fa-icon icon="check-circle" class="confirm__icon primary--text mb-7" />
    <h1 class="confirm__title black--text font-weight-black mb-3">
      {{ $t('global.pleaseWaitWhileRedirecting') }}
    </h1>
    <p class="confirm__description font-weight-medium">
      <span class="text-gray--text">
        {{ $t('global.ifYouAreNotRedirected') }}
      </span>
      <span
        class="confirm__link primary--text text-decoration-underline"
        @click="refreshPage"
      >
        {{ $t('global.refreshThisPage') }}
      </span>
    </p>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import { mapActions } from 'vuex';

import { HUBSPOT_INTEGRATION_SUCCESS } from '~/constants';

export default {
  name: 'HubspotConfirm',
  layout: 'empty',
  async mounted() {
    if (!this.$route.query.code || !this.$apolloHelpers.getToken()) {
      this.$router.push('/');
    }
    this.handleHubspotIntegrationProcess();
  },
  methods: {
    ...mapActions('profile', ['confirmHubspotConnection']),
    async handleHubspotIntegrationProcess() {
      const profileId = await this.confirmHubspotConnection(
        this.$route.query.code,
      );
      if (profileId) {
        Cookie.set(HUBSPOT_INTEGRATION_SUCCESS, true);
        window.location.href = `${process.env.baseUrl}/app/${profileId}/crm`;
      }
    },
    refreshPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  height: 100%;
  &__icon {
    width: 40px;
    height: 40px;
    @media #{$lg-and-up} {
      width: 80px;
      height: 80px;
    }
  }
  &__title {
    font-size: 1.5625rem;
    line-height: 1.2;
    @media #{$lg-and-up} {
      font-size: 2rem;
    }
  }
  &__description {
    @media #{$lg-and-up} {
      font-size: 1.25rem;
    }
  }
  &__link {
    cursor: pointer;
  }
}
</style>
