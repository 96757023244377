<template>
  <div>
    <div class="legal-information container">
      <h1 class="text-lp-h2">
        {{ $t('legalInformation.legalInformation') }}
      </h1>
      <div class="text-h3 primary-dark--text">
        {{ $t('legalInformation.subtitle') }}
      </div>
      <div class="legal-information__content dark-grey--text">
        <p>{{ company.name }}</p>
        <p>{{ company.street }}</p>
        <p>{{ company.postalCode + '' + company.city }}</p>
        <p class="pb-6">{{ company.country }}</p>
        <p>Tel: {{ company.phoneNumber }}</p>
        <p class="pb-6">Email: {{ company.email }}</p>
        <p>{{ $t('legalInformation.registered') }}</p>
        <p class="pb-6">
          {{ $t('legalInformation.registerNumber') }}:
          {{ company.registerNumber }}
        </p>
        <p>
          {{
            $t('legalInformation.registeredOffice') + company.seatOfTheCompany
          }}
        </p>
        <p class="pb-6">
          {{ $t('legalInformation.taxId') + ' ' + company.taxId }}
        </p>
        <p class="text-h3 primary-dark--text pb-6">
          {{ $t('legalInformation.authorizedDirector') }}:
        </p>
        <p class="pb-6">{{ company.director }}</p>
        <p class="text-h3 primary-dark--text pb-6">
          {{ $t('legalInformation.disputeResolution') }}
        </p>
        <p class="pb-6">{{ $t('legalInformation.disputeResolutionInfo') }}</p>
        <p class="text-h3 primary-dark--text pb-6">
          {{ $t('legalInformation.disclaimer') }}
        </p>
        <p>{{ $t('legalInformation.disclaimerText') }}</p>
      </div>
    </div>
    <Details />
  </div>
</template>

<script>
import { COMPANY as company } from '~/constants';

export default {
  name: 'legalInformation',
  components: {
    Details: () => import('~/components/Details'),
  },
  data() {
    return {
      company,
    };
  },
};
</script>

<style scoped lang="scss">
.legal-information {
  display: grid;
  gap: 2rem;
  margin-top: 100px;
  &__content {
    display: grid;
    gap: 0.7rem;
    & p {
      margin: 0 !important;
    }
  }
}
</style>
