<template>
  <div
    class="card white--text pa-6"
    :style="{
      background: backgroundPhoto,
    }"
    @click="selectCard"
  >
    <Avatar
      :avatar-image="getStorageUrl(profile.photoName)"
      :profile-full-name="imagePlaceholder"
      :profile-type="profile.profileType"
      :icon="membershipStatusIcon"
      class-variant="card__image avatar--card"
    />
    <h2 class="card__name">{{ cardName }}</h2>
    <template v-if="hasOrganization">
      <div class="text-gray--text">
        <fa-icon :icon="organizationIconName" />
        <span>{{ organizationMembershipName }}</span>
      </div>
      <Badge
        v-if="profileType === PROFILE_TYPE.PERSONAL"
        :organization-logo="organizationLogo"
        :organization-name="organizationName"
      />
    </template>
    <p v-else class="card__position">
      {{ profile.position }}
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Avatar from '~/components/atoms/Avatar.vue';
import Badge from '~/components/atoms/Badge.vue';
import { MEMBERSHIP_STATUS, PROFILE_TYPE } from '~/constants';
import { getBackgroundPhotoString, getStorageUrl } from '~/helpers';
import shareProfileMixin from '~/mixins/shareProfileMixin';
export default {
  components: {
    Badge,
    Avatar,
  },
  mixins: [shareProfileMixin],
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    profileType: {
      type: String,
      default: PROFILE_TYPE.PERSONAL,
    },
  },
  data() {
    return {
      PROFILE_TYPE,
      MEMBERSHIP_STATUS,
      cardGradient:
        'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 102.55%)',
    };
  },
  computed: {
    ...mapGetters('profile', ['getOrganization', 'getProfileOrganization']),
    backgroundPhoto() {
      const profileOrganization = this.getProfileOrganization(this.profile.id)
        ?.organization?.profile;
      if (profileOrganization) {
        const backgroundPhotoName =
          profileOrganization?.backgroundPhotoName ||
          profileOrganization?.photoName;
        if (backgroundPhotoName) {
          return getBackgroundPhotoString(backgroundPhotoName);
        }
      }
      const profileBackgroundPhoto =
        this.profile?.backgroundPhotoName || this.profile?.photoName;
      if (profileBackgroundPhoto) {
        return getBackgroundPhotoString(profileBackgroundPhoto);
      }
      return '';
    },
    imagePlaceholder() {
      return this.profile.name;
    },
    cardName() {
      return this.profile.name;
    },
    hasOrganization() {
      return !!this.getProfileOrganization(this.profile.id);
    },
    organizationIconName() {
      return (
        this.getProfileOrganization(this.profile.id)?.membershipType
          ?.iconName ?? ''
      );
    },
    organizationMembershipName() {
      return (
        this.getProfileOrganization(this.profile.id)?.membershipType
          ?.membershipName ?? ''
      );
    },
    organizationMembershipStatus() {
      return (
        this.getProfileOrganization(this.profile.id)?.membershipStatus ?? ''
      );
    },
    organizationName() {
      return (
        this.getProfileOrganization(this.profile.id)?.organization?.profile
          ?.name ?? ''
      );
    },
    organizationLogo() {
      return (
        this.getProfileOrganization(this.profile.id)?.organization?.profile
          ?.photoName &&
        getStorageUrl(
          this.getProfileOrganization(this.profile.id).organization.profile
            .photoName,
        )
      );
    },
    membershipStatusIcon() {
      const profile = this.getProfileOrganization(this.profile.id);
      if (profile) {
        return {
          [MEMBERSHIP_STATUS.INVITED]: 'question-circle',
          [MEMBERSHIP_STATUS.ACTIVE]: 'check-circle',
          [MEMBERSHIP_STATUS.EXPIRED]: 'exclamation-circle',
        }[profile?.membershipStatus];
      }
      return '';
    },
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    getStorageUrl,
    async selectCard() {
      this.$emit('profileSelected', this.profile.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  width: 100%;
  min-height: 196px;
  height: max-content;
  background-size: cover !important;
  border-radius: 12px;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.25rem 0.5rem;
  cursor: pointer;
  background: $profile-placeholder-gradient;
  position: relative;
  box-shadow: $small-dark-shadow;
  &--selected {
    outline: 3px solid var(--v-primary-base) !important;
  }
  &__image {
    grid-row: 1 / 3;
  }
  &__name {
    grid-column: 2 / 3;
    align-self: end;
  }
  &__position {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-column: 2 / 3;
  }
}
</style>
