<template>
  <section class="club">
    <div class="club__wrapper">
      <v-container class="d-flex justify-center">
        <div class="club__content">
          <h2 class="club__title font-weight-black mb-3">
            <span class="black--text"> {{ $t('rheinfire.the') }}&nbsp; </span>
            <span class="claret--text">
              {{ $t('rheinfire.rheinfire') }}&nbsp;
            </span>
            <span class="cream--text">
              {{ $t('rheinfire.heatClub') }}
            </span>
          </h2>
          <p class="club__explanation font-weight-medium dark-grey--text">
            {{ $t('rheinfire.rheinfireIsBackThorttle') }}
          </p>
        </div>
      </v-container>
    </div>
    <v-img
      class="club__background text-center"
      src="/images/rheinfire_club_bg_fallback.png"
      srcset="/images/rheinfire_club_bg_375w.webp 600w, /images/rheinfire_club_bg_835w.webp 900w, /images/rheinfire_club_bg_1435w.webp 1264w"
    >
      <img
        src="/images/rheinfire_club_phone_fallback.png"
        srcset="
          /images/rheinfire_club_phone_591w.webp  500w,
          /images/rheinfire_club_phone_956w.webp 1000w
        "
        alt=""
        class="club__image"
      />
    </v-img>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.club {
  &__wrapper {
    background: url('/images/rheinfire_club_fire.png');
    background-size: cover;
    background-repeat: none;
    background-position: top right;
  }
  &__title {
    font-size: 2rem;
    line-height: 1.4;
  }
  &__explanation {
    font-size: 1.125rem;
    line-height: 1.4;
    @media #{$sm-and-up} {
      font-size: 1.5rem;
    }
  }
  &__content {
    max-width: 49.4rem;
    padding: 5rem 0;
  }
  &__background {
    position: relative;
    overflow: visible;
    background-size: cover;
    background-position: center center;
    height: 17rem;
    @media #{$sm-and-up} {
      height: 33.6rem;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      background: radial-gradient(
        circle,
        $lighter-gray 0%,
        $light-gray 20%,
        $dark-gray 50%,
        $darker-gray 75%
      );
      z-index: -1;
    }
  }
  &__image {
    z-index: 4;
    height: 20rem;
    aspect-ratio: 1;
    margin-top: 3.75rem;
    margin-bottom: -5.6rem;
    @media #{$sm-and-up} {
      height: 37rem;
    }
  }
}
</style>
