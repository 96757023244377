<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <v-form class="contact" @submit.prevent="handleSubmit(send)">
        <span class="font-weight-medium text-gray--text">
          {{ $t('products.contactRequestInfo') }}
        </span>
        <CompactInput
          v-model="phone"
          :label="$t('products.phoneNr')"
          :placeholder="$t('products.insertYourPhoneNr')"
          rules="required"
          label-color="dark-grey"
        />
        <v-divider />
        <div class="contact__buttons">
          <Button
            class="button--primary font-weight-bold"
            height="48"
            width="100%"
            @click="$emit('closeDrawer')"
          >
            {{ $t('global.close') }}
          </Button>
          <Button
            class="button--secondary font-weight-bold"
            height="48"
            width="100%"
            type="submit"
          >
            {{ $t('contact.send') }}
          </Button>
        </div>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';

import { HUBSPOT_EVENT, REQUEST_STEPS } from '~/constants';

export default {
  name: 'ContactRequest',
  components: {
    ValidationObserver,
    Button: () => import('~/components/shared/Button.vue'),
    CompactInput: () => import('~/components/shared/Compact.vue'),
  },
  data() {
    return {
      phone: '',
    };
  },
  computed: {
    ...mapGetters('profile', ['profile', 'currentOrganizationProfile']),
  },
  methods: {
    ...mapActions('profile', ['sendHubspotEvent']),
    async send() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      const result = await this.sendHubspotEvent({
        eventName: HUBSPOT_EVENT.PRODUCT_REQUEST_CONTACT,
        organizationId: this.currentOrganizationProfile.id,
        payloadData: {
          phone: this.phone,
          productName: this.profile?.name,
        },
      });
      if (result) {
        this.$emit('changeStep', REQUEST_STEPS.SUCCESS);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact {
  display: grid;
  gap: 2rem;
  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
  }
}
</style>
